import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { TbEditCircle } from "react-icons/tb";
import { toast } from "react-toastify";
import { CategoryGetForDropDownSlice } from "../../../../redux/features/CategorySlice";
import { onImageHandler } from "../../../../common/Image upload on bucket/UploadImageOnBucket";
import { CreateKnowledgeInsightSlice } from "../../../../redux/features/Knowledge insight by Admin/KnowledgeInsightByAdminSlice";

const PublishedNewInsigth = () => {
  const [imageLoading, setImageLoading] = useState(false);
  const [iState, setUpdate] = useState([
    {
      headline: "",
      images: "",
    },
  ]);

  console.log("1:", iState);

  const navigate = useNavigate();

  const [option, setOption] = useState({
    category: [],
  });

  const [select, setSelect] = useState({
    selectedCategory: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(CategoryGetForDropDownSlice()).then((res) => {
        setOption((prev) => ({
          ...prev,
          category: res.payload?.data?.map((item, index) => ({
            value: item?.catName,
            label: item?.catName,
          })),
        }));
      });
    } catch (error) {
      toast.error("Something went worng while api calling!");
    }
  }, []);

  const handleChange = ({ ObjectKey, e }) => {
    console.log(ObjectKey);
    const { name, value } = e.target;
    const TempArr = [...iState];
    TempArr[ObjectKey] = { ...TempArr[ObjectKey], [name]: value };
    setUpdate(TempArr);
  };

  const AddMoreField = () => {
    setUpdate((prev) => [
      ...prev,
      {
        headline: "",
        images: "",
      },
    ]);
  };

  const hanldeImage = async ({ ObjectKey, e, setImageLoading, setUpdate }) => {
    const { name } = e.target;
    setImageLoading((prev) => ({ ...prev, [ObjectKey]: true }));
    const data = await onImageHandler(e);
    console.log(data[1].location);
    const TempArr = [...iState];
    TempArr[ObjectKey] = { ...TempArr[ObjectKey], [name]: data[1].location };
    setUpdate(TempArr);
    setImageLoading((prev) => ({ ...prev, [ObjectKey]: false }));
  };

  const handleSubmit = () => {
    // console.log(iState);

    const filteredArray = Object.entries(iState).map(([key, item]) => ({
      ...item,
      category: option?.SelectedCategory?.value,
    })); // Modify the structure

    if (
      filteredArray.some((value1) =>
        Object.entries(value1).some(([key2, value2]) => {
          if (String(value2)?.trim()?.length === 0) {
            toast.error(`Please enter this field ${key2}`);
            return true;
          }
        })
      )
    ) {
      return;
    }

    console.log(filteredArray);
    dispatch(CreateKnowledgeInsightSlice({ items: filteredArray })).then(
      (res) => {
        navigate(-1);
        console.log(res.payload);
      }
    );
  };

  return (
    <>
      <div className="TitleBox">
        <h4 className="Title">Content Management</h4>
        <Link className="TitleLink" onClick={() => navigate(-1)}>
          <i className="fa fa-arrow-left" /> Back
        </Link>
      </div>

      <div className="Small-Wrapper">
        {/* <div class="TitleBox"> 
              <h4 class="Title">Add News Article</h4> 
          </div> */}
        <div className="CommonForm">
          <h4>ADD QuixSense</h4>
          <div
            className="row"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>
              {/* <div className="col-sm-8">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Select Category</label>
                      <Select
                        options={option?.category}
                        value={option?.selectedCategory}
                        onChange={(e) =>
                          setOption((prev) => ({ ...prev, SelectedCategory: e }))
                        } 
                        isSearchable={true}
                        placeholder="Choose a category..."
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              {iState?.map((item, key) => {
                console.log(key, item);
                return (
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "1px solid gray",
                      marginBottom: "20px",
                    }}
                  >
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>News Headline</label>
                            <input
                              type="text"
                              className="form-control"
                              name="headline"
                              onChange={(e) =>
                                handleChange({ ObjectKey: key, e })
                              }
                              placeholder="Enter News Headline..."
                            />
                          </div>
                        </div>
                        {/* <div className="col-sm-12">
                          <div className="form-group">
                            <label>Sub Headline</label>
                            <input
                              type="text"
                              className="form-control"
                              name="subHeadline"
                              onChange={(e) =>
                                handleChange({ ObjectKey: key, e })
                              }
                              placeholder="Government Launches New Electric Vehicle (EV) Scheme to Accelerate Sustainable Mobility"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-sm-12">
                          <div className="form-group">
                            <label>Edit Content</label>
                            <textarea
                              className="form-control"
                              rows={5}
                              name="content"
                              onChange={(e) =>
                                handleChange({ ObjectKey: key, e })
                              }
                              placeholder="Please write content here..."
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Upload Images </label>
                        <div className="UploadBox">
                          <div className="Upload" style={{ padding: "0" }}>
                            {imageLoading[key] ? (
                              <Skeleton width={"100%"} height={"130px"} />
                            ) : item?.images?.length > 0 ? (
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={item?.images}
                                  style={{ height: "100%", width: "100%" }}
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <input
                                    type="file"
                                    name="images"
                                    onChange={(e) =>
                                      hanldeImage({
                                        ObjectKey: key,
                                        e,
                                        setImageLoading,
                                        setUpdate,
                                      })
                                    }
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      opacity: "0",
                                    }}
                                  />
                                  <TbEditCircle
                                    style={{
                                      fontSize: "20px",
                                      backgroundColor: "gray",
                                      color: "wheat",
                                      cursor: "pointer",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                <i
                                  className="fa fa-upload"
                                  style={{ marginTop: "30px" }}
                                />{" "}
                                <span>Upload </span>
                                <input
                                  type="file"
                                  name="images"
                                  onChange={(e) =>
                                    hanldeImage({
                                      ObjectKey: key,
                                      e,
                                      setImageLoading,
                                      setUpdate,
                                    })
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="Button ml-3"
              // href="content-management-add-new-more.html"
              onClick={AddMoreField}
            >
              Add More +
            </div>
          </div>
        </div>
      </div>
      <button class="Button mt-4" onClick={handleSubmit}>
        Create
      </button>
    </>
  );
};

export default PublishedNewInsigth;
