import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PrivacyAndPolicyGetSlice } from "../../../redux/features/Static Content Management slice/StaticContentManagement";
import { useDispatch } from "react-redux";

const PrivacyPolicy = () => {

  const [iState,setUpdate] = useState({id:'',data:''});
  const {state} = useLocation();
  const dispatch = useDispatch();

  useEffect(()=>{
   dispatch(PrivacyAndPolicyGetSlice()).then((res)=>{
    setUpdate((prev)=>({...prev,id:res?.payload?.data?.[0]?._id,data:res?.payload?.data?.[0]?.details}));

   })
  },[])

  console.log(iState)
  


  return (
    <>
      <div className="TitleBox">
        <h4 className="Title">Privacy and Policy</h4>
        <Link className="TitleLink" to={"/StaticContent/EditStaticContent"} state={{id:iState?.id,type:'staticContent',heading:'Privacy and Policy',content:iState?.data}}>
          Edit Privacy and Policy
        </Link>
      </div>

      <div className="StaticContentArea"> 
      <div dangerouslySetInnerHTML={{ __html: iState?.data }}/>
      </div>
    </>
  );
};

export default PrivacyPolicy;
