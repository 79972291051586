import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { GetDeleteArticleSlice } from "../../../../redux/features/Publish Article By Admin Slices/DeletedArticle";
import useParamsFormat from "../../../../common/Params Values format/ParamsFormat";
import TableSkeleton from "../../../../common/Skeleton";
import NotFoundData from "../../../../common/NotFoundData";
import CustomPagination from "../../../../common/Pagination/Pagination";
import useRemoveTag from "../../../../common/Remove Tag of ReactQuil/RemoveTag";

const DeleteArticles = () => {
  const location = useLocation().pathname.split("/").splice(1);

  const { pageNo } = useParams();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const [iState, setUpdate] = useState([]);
  const { createDataSetParams } = useParamsFormat();
  const [dataForHold, setDataForHold] = useState();
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: pageNo ?? 1,
  });

  const { loading, AllDeleteArticle } = useSelector((state) => state?.Delete);
  const { DeletedArticle, DisApprovedArticle, HideArticle } = useSelector(
    (state) => state?.Article
  );
  
  const {getPlainText} = useRemoveTag(); 

  useEffect(() => {
    dispatch(GetDeleteArticleSlice(createDataSetParams(getSearch))).then(
      (res) => {
        setPagination(res?.payload?.data?.pagination);
      }
    );
  }, [DeletedArticle, DisApprovedArticle, HideArticle]);

  useEffect(() => {
    setUpdate(AllDeleteArticle); 
  }, [AllDeleteArticle]);

  const hanldeFetchData = (PageNumber) => {
    setGetSearch((prev) => ({ ...prev, page: PageNumber }));
    dispatch(
      GetDeleteArticleSlice(
        createDataSetParams({ ...getSearch, page: PageNumber })
      )
    ).then((res) => {
      setPagination(res?.payload?.data?.pagination);
    });
  };

  return (
    <>
      <>
        <div className="Small-Wrapper">
          <div className="FilterArea">
            <div className="FilterLeft">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  name="search"
                  onChange={(e) =>
                    setGetSearch((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  className="form-control"
                  placeholder="Search here"
                />
              </div>
              <div className="form-group">
                <label>From Date</label>
                <input
                  type="date"
                  name="fromDate"
                  onChange={(e) =>
                    setGetSearch((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>To Date</label>
                <input
                  type="date"
                  name="toDate"
                  onChange={(e) =>
                    setGetSearch((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>&nbsp;</label>
                <button className="Button">Apply</button>
                <button className="Button Cancel">
                  <i className="fa fa-refresh" />
                </button>
              </div>
            </div>
            <div className="FilterRight">
              <div className="form-group">
                <label>Timeframe</label>
                <select className="form-control">
                  <option>Select Timeframe</option>
                  <option>Today</option>
                  <option>This Week</option>
                  <option>This Month</option>
                  <option>This Year</option>
                </select>
              </div>
            </div>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Article ID</th>
                  <th>Article Description</th>
                  <th>Posted On</th>
                  <th>Summary</th>
                  {/* <th>Article Status</th> */}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableSkeleton />
                ) : Array.isArray(iState) && iState.length > 0 ? (
                  iState?.map((item, index) => {
                    return (
                      <tr>
                        <td>{(pagination.currentPage - 1) * 10 + index + 1}</td>
                        <td>{item?.articleId?.toUpperCase()}</td>
                        <td>
                        {getPlainText(item?.content)?.slice(0, 20)}...
                          <Link
                            to={`/ContentManagement/PublishArticleByAdmin/AllArticleDetails/${item?._id}`}
                            state={item}
                            className="blue"
                          >
                            view more
                          </Link>
                          <br />
                        </td>
                        <td>{item?.createdAt.slice(0, 10)}</td>
                        <td>
                        {getPlainText(item?.summary)?.slice(0, 20)}...
                          <Link
                            to={`/ContentManagement/PublishArticleByAdmin/AllArticleDetails/${item?._id}`}
                            state={item}
                            className="blue"
                          >
                            view more
                          </Link>
                          <br />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NotFoundData />
                )}
              </tbody>
            </table>
          </div>
        </div>
        {iState.length > 0 && (
          <CustomPagination
            activePage={pagination?.currentPage}
            itemsCountPerPage={pagination?.pageSize}
            totalItemsCount={pagination?.totalPages}
            pageRangeDisplayed={5}
            hanldeFetchData={hanldeFetchData}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        )}
      </>
    </>
  );
};

export default DeleteArticles;
