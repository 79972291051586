import React, { useState } from "react";
import Pagination from "react-js-pagination";

const CustomPagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  hanldeFetchData,
  itemClass,
  linkClass,
}) => {
  const [currentPage, setCurrentPage] = useState(activePage);

  const itemsPerPage = itemsCountPerPage;

  // console.log( activePage,
  //   itemsCountPerPage,
  //   totalItemsCount,
  //   pageRangeDisplayed,
  //   hanldeFetchData,)
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    hanldeFetchData(pageNumber)
  };

  return (
    <div style={{display:'flex',flexDirection:'row-reverse',marginTop:'10px'}}>
   

      {/* Pagination Component */}
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage} 
        totalItemsCount={totalItemsCount * 10}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
        innerClass="pagination" 
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  );
};

export default CustomPagination;
