import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const MyAccountSlice = createAsyncThunk("detailsAdd",async ({id,data}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`detailsAdd/${id}`,data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ChangePasswordSlice = createAsyncThunk("adminpanelpassword",async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`adminpanelpassword`,data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);



const MyAccountAdminSlice = createSlice({
  name: 'MyAccount Admin Auth',
  initialState: {
    AdminDetails: [],
    PasswordChanged: [],
    loading: false,
    error: null,
    validationErrors: [],
 
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      toast.error(state.error);
    }

    builder
      .addCase(MyAccountSlice.pending,handlePendingState)
      .addCase(MyAccountSlice.fulfilled, (state, action) => {
        console.log(action.payload.data?.data)  
        state.AdminDetails = action.payload.data; 
        state.loading = false;
        toast.success("Admin details updated SuccessFully!");
      })
      .addCase(MyAccountSlice.rejected,handleRejectState)
     
      .addCase(ChangePasswordSlice.pending,handlePendingState)
      .addCase(ChangePasswordSlice.fulfilled, (state, action) => {
        state.PasswordChanged = action.payload.data; 
        state.loading = false;
        toast.success("LoggedIn Successfully!");
      })
      .addCase(ChangePasswordSlice.rejected,handleRejectState)
  },
});


export default MyAccountAdminSlice.reducer;
