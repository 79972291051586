import React from "react";
import { Link, Outlet } from "react-router-dom";

const KnowledgeInsightByAPIOutlet = () => {
  return (
    <>
      <div className="CommonTabs">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className="nav-link active"
              data-toggle="tab"
              to={'AllPublishedArticleKnowledgeInsigthByApi'} 
            >
              All Published Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" data-toggle="tab" to={'NewRequest'}>
              New Request
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" data-toggle="tab" to={'HiddenArticleByKnowledgeInsigthByAPI'}>
              Hidden Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link "
              data-toggle="tab"
              to={'DisApprovedArticleByKnowledgeInsightByAPI'}
            >
              Disapproved Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" data-toggle="tab" to={'DeletedArticleKnowledgeInsigthByAPI'}>
              Deleted Articles 
            </Link>
          </li>
        </ul>
      </div>
      <div>
      <Outlet/> 
      </div>
    </>
  );
};

export default KnowledgeInsightByAPIOutlet;
