import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Select from "react-select";
import { CategoryGetForDropDownSlice } from "../../../redux/features/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { InsertArticleByAPISlice } from "../../../redux/features/Publish Article By API Slices/ArticleByAPISlice";
import useParamsFormat from "../../../common/Params Values format/ParamsFormat";

const PublishArticleByAPIOutlet = () => {
  const location = useLocation().pathname?.split("/").splice(1);

  const [option, setOption] = useState({ options: [] });
  const { CategoryForDropDown, loading } = useSelector(
    (state) => state.Category
  );
  const { createDataSetParams } = useParamsFormat();

  const dispatch = useDispatch();
  const [selectedRegion, setSelectedRegion] = useState({
    category: null,
  }); 

  useEffect(()=>{
    if(selectedRegion?.category){
      const params = {category:selectedRegion?.category?.value}
      dispatch(InsertArticleByAPISlice(createDataSetParams(params)))
    }
  },[selectedRegion?.category])
  

  useEffect(() => {
    dispatch(CategoryGetForDropDownSlice());
  }, []);

  useEffect(() => {
    const option = CategoryForDropDown?.map((item, index) => ({
      value: item?.catName,
      label: item?.catName,
    }));
    setOption((prev) => ({ ...prev, options: option })); 
  }, [CategoryForDropDown]);

  return (
    <>
      {!location?.includes('AllArticleDetails') &&
      <div className="CommonTabs">
        <ul className="nav nav-tabs">
        <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("AllPendingArticlesByAPI") && "active"
              }`}
              data-toggle="tab"
              to={"AllPendingArticlesByAPI"}
            >
              All Pending Articles
            </Link>
          </li>
          <li className="nav-item mb-2">
            <Link
              className={`nav-link ${
                location?.includes("AllPublishedArticleByAPI") && "active"  
              }`}
              data-toggle="tab"
              to={"AllPublishedArticleByAPI"}
            >
              All Published Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("AllHiddenArticle") && "active"
              }`}
              data-toggle="tab"
              to={"AllHiddenArticle"}
            >
              Hidden Articles
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("NewRequest") && "active"
              }`}
              data-toggle="tab"
              to={"NewRequest"}
            >
              New Request
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("DisapprovedArticle") && "active"
              }`}
              data-toggle="tab"
              to={"DisapprovedArticle"}
            >
              Disapproved Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("DeletedArticle") && "active"
              }`}
              data-toggle="tab"
              to={"DeletedArticle"}
            >
              Deleted Articles
            </Link>
          </li>
          <li>
            <Select
              options={option?.options}
              value={selectedRegion?.category}
              onChange={(e) =>
                setSelectedRegion((prev) => ({ ...prev, category: e }))
              }
              isSearchable={true}
              placeholder="Import Article"
            />
          </li>
        </ul>
      </div>
      }
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default PublishArticleByAPIOutlet;
