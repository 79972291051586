import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onImageHandler } from "../../../common/Image upload on bucket/UploadImageOnBucket";
import { TbEditCircle } from "react-icons/tb";
import Skeleton from "react-loading-skeleton"; // You can install react-loading-skeleton package
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { AddCategorySlice } from "../../../redux/features/CategorySlice";
import { toast } from "react-toastify";

const AddNewsCategory = ({showModal,setShowModal}) => {
  const NewsCategoryRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [iState, setUpdate] = useState({
    catName: "",
    catImage: "",
  });

  const [imageLoading, setImageLoading] = useState(false);

  const { loading } = useSelector((state) => state.Category);

  const handleSubmit = () => {
    if (
      Object.entries(iState)
        .map(([key, value]) => value?.length)
        .includes(0)
    ) {
      toast.error("Please fill all fields!");
      return;
    }

    dispatch(AddCategorySlice(iState)).then((res) => {
      NewsCategoryRef.current.click();
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdate((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpload = async (e) => {
    setImageLoading(true);
    const data = await onImageHandler(e);
    setUpdate((prev) => ({ ...prev, catImage: data[1].location }));
    setImageLoading(false);
  };

  return (
    <div className="ModalBox">
      <div
        id="UploadModal"
        className="modal fade"
        role="dialog"
        data-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="Category">
                <a                 
                  className="CloseModal"
                  data-dismiss="modal"
                  ref={NewsCategoryRef}
                  onClick={() =>{
                    setShowModal((prev)=>({...prev,addCat:!prev['addCat']}))
                  }}
                >
                  ×
                </a>
                <h3>Add News Category</h3>
                <div className="form-group">
                  <label>Category Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="catName"
                    value={iState.catName}
                    onChange={handleChange}
                    placeholder="Category Title"
                  />
                </div>
                <div className="form-group">
                  {iState?.imageUrl?.length === 0 && (
                    <label>Upload banner Image </label>
                  )}
                  <div className="UploadBox">
                    <div className="Upload" style={{ padding: "0" }}>
                      {imageLoading ? (
                        <Skeleton width={"100%"} height={"130px"} />
                      ) : iState?.catImage?.length > 0 ? (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={iState?.catImage}
                            style={{ height: "100%", width: "100%" }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="file"
                              onChange={handleUpload}
                              style={{
                                height: "100%",
                                width: "100%",
                                opacity: "0",
                              }}
                            />
                            <TbEditCircle
                              style={{
                                fontSize: "20px",
                                backgroundColor: "gray",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <i className="fa fa-upload" style={{marginTop:'30px'}} /> <span>Upload </span>
                          <input type="file" onChange={handleUpload} /> 
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="Button"
                  disabled={imageLoading || loading}
                  onClick={handleSubmit}
                >
                  {imageLoading || loading
                    ? "Please wait..."
                    : "Add News Category"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewsCategory;
