import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import EditKnowledgeInsight from "./EditKnowledgeInsightArticle";

const ViewKnowledgeInsight = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
    const [show, setShow] = useState(false);

  const [iState, setUpdate] = useState([]);
  const [StateForUpdate,setStateForUpdate] = useState([]);

  useEffect(() => {
    setUpdate(state?.items);
  }, [state]);

  console.log(iState);

  return (
    <>
      <div>
        <div>
          <div>
            <div>
              <div className="TitleBox">
                <h4 className="Title">Content Management</h4>
                <a className="TitleLink" onClick={() => navigate(-1)}>
                  <i className="fa fa-arrow-left" /> Back
                </a>
              </div>
              <div className="Small-Wrapper">
                <div className="CommonForm">
                  <h4>ADD Knowledge Insights</h4>
                  <div
                    className="row"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      {/* <div className="col-sm-8">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Select Category</label>
                           
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {iState?.map((item, index) => {
                        return (
                            <>
                             <div onClick={()=>{
                              setShow((prev)=>!prev)
                              setStateForUpdate(item)
                              }} style={{float:'right',cursor:'pointer',border:'2px solid black',height:'35px',width:'35px',backgroundColor:'silver',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'100%'}}>
                            <FaRegEdit style={{color:'green',fontSize:'20px'}} />
                            </div>
                          <div
                            style={{
                              display: "flex",
                              borderBottom: "1px solid gray",
                              marginBottom: 20,
                            }}
                          >
                           
                            <div className="col-sm-8">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label>News Headline</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="headline"
                                      disabled={true}
                                      value={item?.headline}
                                      placeholder="Government Launches New Electric Vehicle (EV) Scheme to Accelerate Sustainable Mobility"
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-sm-12">
                                  <div className="form-group">
                                    <label>Sub Headline</label>
                                    <input
                                      type="text"
                                      disabled={true}
                                      value={item?.subheadline}
                                      className="form-control"
                                      name="subHeadline"
                                      placeholder="Government Launches New Electric Vehicle (EV) Scheme to Accelerate Sustainable Mobility"
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="col-sm-12">
                                  <div className="form-group">
                                    <label>Edit Content</label>
                                    <textarea
                                      className="form-control"
                                      rows={5}
                                      name="content"
                                      value={item?.content}
                                      disabled={true}
                                      placeholder="Please write content here..."
                                      defaultValue={""}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label>Upload Images </label>
                                <div className="UploadBox">
                                  <div
                                    className="Upload"
                                    style={{ padding: 0 }}
                                  >
                                    <div
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        src={item?.images}
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                        }}
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "5px",
                                          right: "10px",
                                          cursor: "pointer",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditKnowledgeInsight StateForUpdate={StateForUpdate} show={show} setShow={setShow}/>
    </>
  );
};

export default ViewKnowledgeInsight;
