import React from 'react'

const NewsPreferences = () => {
  return (
    <>
     <div className="row">
  <div className="col-sm-7">
    <div className="Small-Wrapper">
      <div className="News-References">
        <h3>
          No input was provided for the following topics.
          <br />
          Please mark your selection.
        </h3>
        <div className="TableList">
          <table>
            <tbody>
              <tr>
                <td className="icon">🎯</td>
                <td>Politics</td>
                <td>
                  <button
                    className="thumb up"
                    onclick="markSelection('Politics', 'up')"
                  >
                    👍
                  </button>
                </td>
                <td>
                  <button
                    className="thumb down"
                    onclick="markSelection('Politics', 'down')"
                  >
                    👎
                  </button>
                </td>
              </tr>
              <tr>
                <td className="icon">📚</td>
                <td>Education</td>
                <td>
                  <button
                    className="thumb up"
                    onclick="markSelection('Education', 'up')"
                  >
                    👍
                  </button>
                </td>
                <td>
                  <button
                    className="thumb down"
                    onclick="markSelection('Education', 'down')"
                  >
                    👎
                  </button>
                </td>
              </tr>
              <tr>
                <td className="icon">🔮</td>
                <td>Miscellaneous</td>
                <td>
                  <button
                    className="thumb up"
                    onclick="markSelection('Miscellaneous', 'up')"
                  >
                    👍
                  </button>
                </td>
                <td>
                  <button
                    className="thumb down"
                    onclick="markSelection('Miscellaneous', 'down')"
                  >
                    👎
                  </button>
                </td>
              </tr>
              <tr>
                <td className="icon">💼</td>
                <td>Business</td>
                <td>
                  <button
                    className="thumb up"
                    onclick="markSelection('Business', 'up')"
                  >
                    👍
                  </button>
                </td>
                <td>
                  <button
                    className="thumb down"
                    onclick="markSelection('Business', 'down')"
                  >
                    👎
                  </button>
                </td>
              </tr>
              <tr>
                <td className="icon">🎯</td>
                <td>Politics</td>
                <td>
                  <button
                    className="thumb up"
                    onclick="markSelection('Politics', 'up')"
                  >
                    👍
                  </button>
                </td>
                <td>
                  <button
                    className="thumb down"
                    onclick="markSelection('Politics', 'down')"
                  >
                    👎
                  </button>
                </td>
              </tr>
              <tr>
                <td className="icon">⚽</td>
                <td>Sports</td>
                <td>
                  <button
                    className="thumb up"
                    onclick="markSelection('Sports', 'up')"
                  >
                    👍
                  </button>
                </td>
                <td>
                  <button
                    className="thumb down"
                    onclick="markSelection('Sports', 'down')"
                  >
                    👎
                  </button>
                </td>
              </tr>
              <tr>
                <td className="icon">🎵</td>
                <td>Entertainment</td>
                <td>
                  <button
                    className="thumb up"
                    onclick="markSelection('Entertainment', 'up')"
                  >
                    👍
                  </button>
                </td>
                <td>
                  <button
                    className="thumb down"
                    onclick="markSelection('Entertainment', 'down')"
                  >
                    👎
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
 
    </>
  )
}

export default NewsPreferences
