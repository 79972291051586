
const useAuthentication = () => {
  
    const isValid = ({iState,setError}) =>{
        //if email and password both are blank
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
        const validate = Object.entries(iState).map((([key,item])=>String(item)?.length)).every((item)=>item === 0);
    
        if(validate){
          setError((prev)=>({...prev,email:'Please provide an email',password:'Please provide a password'}))
          return !validate;  
        }
    
        //if any of them is blank, then throw an error
        const result = Object.entries(iState).every(([key, item]) => {
          if (item?.toString()?.trim().length === 0) {
            setError((prev)=>({[key]:`Please provide ${key}`}));
            return false;
          }
          return true;
        });
     
        if(!result)
         return result;
    
        if(!emailRegex.test(iState.email)){
          setError((prev)=>({email:'Please written in email format!'}));
          return;
        }

        setError({email:'',password:''})
        return true;
      }

    const handleChange = ({event,setUpdate}) =>{
        const {name,value} = event.target;
    
        setUpdate((prev)=>({...prev,[name]:value}));
      }
    

    return {isValid,handleChange};
}

export default useAuthentication
