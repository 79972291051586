import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInstance.js';

export const GetUserManagementSlice = createAsyncThunk("admin/customerslisting",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`customerslisting?${params}`);
      console.log("response:",response);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const UpdateUserStatusSlice = createAsyncThunk("admin/customerupdatestatus",async ({id,data}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`customerupdatestatus/${id}`,data);
      console.log("response:",response);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const UserManagementSlice = createSlice({
  name: 'UserManagement',
  initialState: {
    UserManagement: [],
    UpdateUser:[],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      // toast.error(state.error);
    }

    builder
      .addCase(GetUserManagementSlice.pending,handlePendingState)
      .addCase(GetUserManagementSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.UserManagement = action.payload.data; 
        state.loading = false;
        // toast.success(action?.payload?.message); 
      })
      .addCase(GetUserManagementSlice.rejected,handleRejectState)

      .addCase(UpdateUserStatusSlice.pending,handlePendingState)
      .addCase(UpdateUserStatusSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.UpdateUser = action.payload.data; 
        state.loading = false;
        // toast.success(action?.payload?.message); 
      })
      .addCase(UpdateUserStatusSlice.rejected,handleRejectState)
  },
});


export default UserManagementSlice.reducer;
