import React from 'react'
import { Link, Outlet } from 'react-router-dom'

const MyAccountOutlet = () => {
  return (
    <>
    <div className="TitleBox">
  <h4 className="Title">My Account</h4>
</div>

    <div className="CommonTabs">  
  <ul className="nav nav-tabs">
    <li className="nav-item">
      <Link className="nav-link active" data-toggle="tab" to={'MyAccount'}>
        My Account
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-toggle="tab" to={'ChangePassword'}>
        Password Settings
      </Link>
    </li>
  </ul>
</div>

     <Outlet/>   
    </>
  )
}

export default MyAccountOutlet
