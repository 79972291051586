import React from 'react'

const ContactAnalystics = () => {
  return (
    <>
     <div className="Small-Wrapper">
  <div className="FilterArea">
    <div className="FilterLeft">
      <div className="form-group">
        <label>From Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>To Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>&nbsp;</label>
        <button className="Button">Apply</button>
        <button className="Button Cancel">
          <i className="fa fa-refresh" />
        </button>
      </div>
    </div>
    <div className="FilterRight">
      <div className="form-group">
        <label>Timeframe</label>
        <select className="form-control">
          <option>Select Timeframe</option>
          <option>Today</option>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select>
      </div>
    </div>
  </div>
  <div className="Small-Wrapper">
    <div className="row">
      <div className="col-6">
        <div className="TitleBox">
          <h4 className="Title">Most Attempted Topics</h4>
        </div>
        <figure className="graph-2">
          <img src="images/graph-1.png" />
        </figure>
      </div>
      <div className="col-6">
        <div className="TitleBox">
          <h4 className="Title">Top 20 Performers</h4>
        </div>
        <figure className="graph-2">
          <img src="images/graph-1.png" />
        </figure>
      </div>
      <div className="col-6">
        <div className="TitleBox">
          <h4 className="Title">State Wise User Performance</h4>
        </div>
        <figure className="graph-2">
          <img src="images/graph-1.png" />
        </figure>
      </div>
      <div className="col-6">
        <div className="TitleBox">
          <h4 className="Title">District/City Wise User Performance</h4>
        </div>
        <figure className="graph-2">
          <img src="images/graph-1.png" />
        </figure>
      </div>
    </div>
  </div>
</div>
 
    </>
  )
}

export default ContactAnalystics
