import React from 'react'
import { Link, Outlet } from 'react-router-dom'

const BannerManagementOutlet = () => {
  return (
    <>
    <div className="TitleBox">
  <h4 className="Title">Banner Management</h4>
</div>

    <div className="CommonTabs">
  <ul className="nav nav-tabs">
    <li className="nav-item">
      <Link className="nav-link active" data-toggle="tab" to={'BannerUpload'}>
        Banner Uploaded by Admin
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-toggle="tab" to={'GoogleAdsBanner'}>
        Google Ad Banner
      </Link>
    </li>
  </ul>
</div>

    <div>
      <Outlet/>
    </div>
    </>
  )
}

export default BannerManagementOutlet
