import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const GetDisapproveArticleSlice = createAsyncThunk("disapprove-Article",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listofArticles/disapprove?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const disapproveArticleSlice = createSlice({
  name: 'disapprove Article',
  initialState: {
    AlldisapproveArticle: [],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      // toast.error(state.error);
    }

    builder
      .addCase(GetDisapproveArticleSlice.pending,handlePendingState)
      .addCase(GetDisapproveArticleSlice.fulfilled, (state, action) => {
        state.AlldisapproveArticle = action.payload.data?.articles; 
        state.loading = false;
      })
      .addCase(GetDisapproveArticleSlice.rejected,handleRejectState);
  },
});


export default disapproveArticleSlice.reducer;
