import React from "react";
import { Link, Outlet } from "react-router-dom";

const ContentAnalysticsOutlet = () => {
  return (
    <>
      <div className="TitleBox">
        <h4 className="Title">Content Analytics</h4>
      </div>

      <div className="CommonTabs">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className="nav-link active"
              data-toggle="tab"
              to={'QuizAnalystics'}
            >
              Quiz Analytics
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" data-toggle="tab" to={'ContactAnalystics'}>
              Content Analytics
            </Link>
          </li>
        </ul>
      </div>
      <div >
        <Outlet/>
      </div>
    </>
  );
};

export default ContentAnalysticsOutlet;
