import React from 'react'

const UserQuizLeaderBoad = () => {
  return (
    <>
     <div class="TitleBox">
                <h4 class="Title">User Quiz LeaderBoard</h4>
            </div> 
    </>
  )
}

export default UserQuizLeaderBoad
