import axios from "axios";
import { getToken } from "../utils/GetToken";
import { toast } from "react-toastify";
const axiosInstance = axios.create({
  baseURL: "http://15.206.16.230:4011/api/v1/admin/",
});

axiosInstance.interceptors.request.use((config)=>{
    const token = JSON.parse(getToken("Quixtoken"));
    if(token){
        config.headers.Authorization=`Bearer ${token}`
    }
    return config
},
(error)=>{
    return Promise.reject(error)
}
)

axiosInstance.interceptors.response.use(
    (response) => {
      // If status is 200–299 (successful), return response with custom status
      console.log("inter:",response);
      console.log("inter:",{ ...response, customStatus: response.status });
      response.data = { ...response.data, customStatus: response.status };
      return response;
    },
    (error) => {
      // Check if the error response exists
      if (error.response) {
        const { status } = error.response;
  
        // Handle different HTTP status codes
        switch (status) {
          case 400:
            toast.error("Bad Request: Invalid Input!");
            break;
          case 401:
            toast.error("Invalid Credential");
            break;
          case 403:
            toast.error("Forbidden: You don't have permission!");
            break;
          case 404:
            toast.error("Not Found: Requested resource not found!");
            break;
          case 500:
            toast.error("Server Error: Please try again later!");
            break;
          case 503:
            toast.error("Service Unavailable: Server is currently down!");
            break;
          default:
            toast.error(`Error: Something went wrong! (${status})`);
        }
      } else {
        // If no response received (Network error)
        toast.error("Network Error: Please check your internet connection!");
      }
  
      return Promise.reject({...error, customStatus: error.response.status });
    }
  );
  

export default axiosInstance;