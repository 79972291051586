import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const AboutusGetSlice = createAsyncThunk("About-us-GET",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('aboutusget');
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const PrivacyAndPolicyGetSlice = createAsyncThunk("PrivacyPolicy-GET",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('privacydataget');
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const TermAndConditionGetSlice = createAsyncThunk("TermAndsCondition-GET",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('termget');
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ContactUsGetSlice = createAsyncThunk("ContactUs-GET",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('contactget');
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

//Update Static Content

export const AboutusUpdateSlice = createAsyncThunk("About-us-Update",async ({id,update}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`aboutusupdate/${id}`,{details:update});
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const PrivacyAndPolicyUpdateSlice = createAsyncThunk("PrivacyPolicy-Update",async ({id,update}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`privacyupdatedata/${id}`,{details:update});
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const TermAndConditionUpdateSlice = createAsyncThunk("TermAndsCondition-Update",async ({id,update}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`termupdate/${id}`,{details:update});
      console.log(response)
        return response.data;
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ContactUsUpdateSlice = createAsyncThunk("ContactUs-Update",async ({id,update}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`contactupdate/${id}`,{details:update});
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const StaticContentSlice = createSlice({
  name: 'Static Content',
  initialState: {
    AboutUs: [],
    PrivacyAndPolicy: [],
    TermAndCondition: [],
    ContactUs: [],
  
    AboutUsUpdate: [],
    PrivacyAndPolicyUpdate: [],
    TermAndConditionUpdate: [],
    ContactUsUpdate: [],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      // toast.error(state.error);
    }

    builder
      .addCase(AboutusGetSlice.pending,handlePendingState)
      .addCase(AboutusGetSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.AboutUs = action.payload.data; 
        state.loading = false;
      })
      .addCase(AboutusGetSlice.rejected,handleRejectState)

      .addCase(PrivacyAndPolicyGetSlice.pending,handlePendingState)
      .addCase(PrivacyAndPolicyGetSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.AboutUs = action.payload.data; 
        state.loading = false;
      })
      .addCase(PrivacyAndPolicyGetSlice.rejected,handleRejectState)
    
      .addCase(TermAndConditionGetSlice.pending,handlePendingState)
      .addCase(TermAndConditionGetSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.TermAndCondition = action.payload.data; 
        state.loading = false;
      })
      .addCase(TermAndConditionGetSlice.rejected,handleRejectState)
   
      .addCase(ContactUsGetSlice.pending,handlePendingState)
      .addCase(ContactUsGetSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.ContactUs = action.payload.data; 
        state.loading = false;
      })
      .addCase(ContactUsGetSlice.rejected,handleRejectState)
  
      .addCase(AboutusUpdateSlice.pending,handlePendingState)
      .addCase(AboutusUpdateSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.AboutUsUpdate = action.payload.data; 
        state.loading = false;
        toast.success('About Us updated Successfully!')
      })
      .addCase(AboutusUpdateSlice.rejected,handleRejectState)
  
      .addCase(PrivacyAndPolicyUpdateSlice.pending,handlePendingState)
      .addCase(PrivacyAndPolicyUpdateSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.PrivacyAndPolicyUpdate = action.payload.data; 
        state.loading = false;
        toast.success('Privacy and Policy updated Successfully!')
      })
      .addCase(PrivacyAndPolicyUpdateSlice.rejected,handleRejectState)
  
      .addCase(TermAndConditionUpdateSlice.pending,handlePendingState)
      .addCase(TermAndConditionUpdateSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.PrivacyAndPolicyUpdate = action.payload.data; 
        state.loading = false;
        toast.success('Term and Condition updated Successfully!')
      })
      .addCase(TermAndConditionUpdateSlice.rejected,handleRejectState)
  
      .addCase(ContactUsUpdateSlice.pending,handlePendingState)
      .addCase(ContactUsUpdateSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.ContactUsUpdate = action.payload.data; 
        state.loading = false;
      })
      .addCase(ContactUsUpdateSlice.rejected,handleRejectState)
  },
});


export default StaticContentSlice.reducer;
