import React, { useEffect, useState } from "react";
import AddBannerModal from "./AddBannerModal";
import TableSkeleton from "../../../common/Skeleton";
import NotFoundData from "../../../common/NotFoundData";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteBannerManagementSlice,
  GetBannerManagementSlice,
  UpdateBannerManagementSlice,
} from "../../../redux/features/Banner Management/BannerManagement";
import CustomPagination from "../../../common/Pagination/Pagination";
import useParamsFormat from "../../../common/Params Values format/ParamsFormat";
import EditBanner from "./EditBanner";
import { MdModeEditOutline } from "react-icons/md";

const BannerUpload = () => {
  const [modalState, setModalState] = useState({
    AddBanner: false,
    delete: false,
    EditBanner: false,
  });
  const [dataForHold, setDataForHold] = useState({
    deleteId: "",
    EditBanner: "",
  });
  const dispatch = useDispatch();
  const { loading, DeletedBanner, UpdatedBanner,addBanner } = useSelector(
    (state) => state.Banner
  );
  const { createDataSetParams } = useParamsFormat();
  const [iState, setUpdate] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: 1,
  });

  useEffect(() => {
    dispatch(
      GetBannerManagementSlice(createDataSetParams({ ...getSearch }))
    ).then((res) => {
      console.log(res);
      setUpdate(res?.payload?.data);
      setPagination(res?.payload?.pagination);
    });
  }, [DeletedBanner, UpdatedBanner,addBanner]);

  const hanldeFetchData = (PageNumber) => {
    setGetSearch((prev) => ({ ...prev, page: PageNumber }));
    dispatch(
      GetBannerManagementSlice(
        createDataSetParams({ ...getSearch, page: PageNumber })
      )
    ).then((res) => {
      setPagination(res?.payload?.data?.pagination);
    });
  };

  const hanldeDelete = (id) => {
    dispatch(DeleteBannerManagementSlice(id));
  };

  const handleActiveStatus = ({ event, item }) => {
    console.log(event.target.checked);
    const data = {
      bannerName: item?.bannerName,
      bannerImage: item?.bannerImage,
      isActive: event.target.checked,
    };
    dispatch(UpdateBannerManagementSlice({ id: item?._id, data }));
  };

  return (
    <>
      <div className="TitleBox">
        <h4 className="Title" />
        <a
          className="TitleLink"
          onClick={() =>
            setModalState((prev) => ({
              ...prev,
              AddBanner: !prev["AddBanner"],
            }))
          }
          data-toggle="modal"
          data-target="#UploadModal"
        >
          Add New
        </a>
      </div>

      <div className="Small-Wrapper">
        <div className="TableList">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Banner ID</th>
                <th>Banner Name</th>
                <th>Banner Image</th>
                <th>Uploaded On</th>
                <th>Last Updated On</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableSkeleton />
              ) : Array.isArray(iState) && iState?.length > 0 ? (
                iState?.map((item, index) => {
                  return (
                    <tr>
                      <td>{(pagination?.currentPage - 1) * 10 + index + 1}</td>
                      <td>bnrid1</td>
                      <td>{item?.bannerName}</td>
                      <td>
                        <img
                          src={item?.bannerImage}
                          style={{ height: "50px", width: "50px" }}
                        />
                      </td>
                      <td>{item?.createdAt?.slice(0, 10)}</td>
                      <td>{item?.updatedAt?.slice(0, 10)}</td>
                      <td>
                        {
                          <label className="Switch">
                            <input
                              type="checkbox"
                              checked={item?.isActive}
                              onChange={(event) =>
                                handleActiveStatus({ event, item })
                              }
                            />
                            <span className="slider" />
                          </label>
                        }
                      </td>
                      <td>
                        <div className="Actions">
                          <a className="Blue" >
                            <i className="fa fa-download" aria-hidden="true" />
                          </a>
                          <a
                            onClick={() => {
                              setModalState((prev) => ({
                                ...prev,
                                EditBanner: !prev["EditBanner"],
                              }));
                              setDataForHold((prev) => ({
                                ...prev,
                                EditBanner: item,
                              }));
                            }}
                          >
                            <MdModeEditOutline />
                          </a>
                          <a
                            className="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                            onClick={() => {
                              setDataForHold((prev) => ({
                                ...prev,
                                deleteId: item?._id,
                              }));
                              setModalState((prev) => ({
                                ...prev,
                                delete: !prev["delete"],
                              }));
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NotFoundData />
              )}
            </tbody>
          </table>
        </div>
        {iState.length > 0 && (
          <CustomPagination
            activePage={pagination?.currentPage}
            itemsCountPerPage={pagination?.perPage}
            totalItemsCount={pagination?.totalPages}
            pageRangeDisplayed={5}
            hanldeFetchData={hanldeFetchData}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        )}
        {modalState?.delete && (
          <div className="ModalBox">
            <div
              id="DeleteModal"
              className="modal"
              role="dialog"
              aria-modal="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="Decline">
                      <a
                        href="javascript:void(0);"
                        className="CloseModal"
                        data-dismiss="modal"
                        onClick={() =>
                          setModalState((prev) => ({
                            ...prev,
                            delete: !prev["delete"],
                          }))
                        }
                      >
                        ×
                      </a>
                      <h3>Delete</h3>
                      <p>Are you sure you want to Delete? </p>
                      <h4>
                        <a href="javascript:void(0);" data-dismiss="modal">
                          no
                        </a>
                        <a
                          onClick={() => hanldeDelete(dataForHold?.deleteId)}
                          href="javascript:void(0);"
                          data-dismiss="modal"
                        >
                          Yes
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {modalState?.AddBanner && (
        <AddBannerModal modalState={modalState} setModalState={setModalState} />
      )}
      {modalState?.EditBanner && (
        <EditBanner
          item={dataForHold?.EditBanner}
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </>
  );
};

export default BannerUpload;
