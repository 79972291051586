import React, { useEffect, useState } from "react";
import useParamsFormat from "../../../../common/Params Values format/ParamsFormat";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
// import { ArticleDeleteSlice } from '../../../../redux/features/Publish Article By API Slices/';
// import { GetDisapproveArticleSlice } from '../../../../redux/features/disapproveArticle';
import TableSkeleton from "../../../../common/Skeleton";
import NotFoundData from "../../../../common/NotFoundData";
import CustomPagination from "../../../../common/Pagination/Pagination";
import { GetDisapproveArticleByAPISlice } from "../../../../redux/features/Publish Article By API Slices/disapproveArticle";
import { MdEdit } from "react-icons/md";
import { StatusUpdatePublishArticleByAPISlice } from "../../../../redux/features/Publish Article By API Slices/ArticleByAPISlice";
import useRemoveTag from "../../../../common/Remove Tag of ReactQuil/RemoveTag";

const DisapprovedArticle = () => {
  const location = useLocation().pathname.split("/").splice(1);

  const { pageNo } = useParams();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const [iState, setUpdate] = useState([]);
  const { createDataSetParams } = useParamsFormat();
  const [dataForHold, setDataForHold] = useState();
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: pageNo ?? 1,
  });

  const { StatusUpdateArticle } = useSelector((state) => state?.ArticleByAPI);

  const { loading, AlldisapproveArticle } = useSelector(
    (state) => state?.disapprove
  );

  const { getPlainText } = useRemoveTag();

  useEffect(() => {
    // console.log("called")
    dispatch(
      GetDisapproveArticleByAPISlice(createDataSetParams(getSearch))
    ).then((res) => {
      setPagination(res?.payload?.data?.pagination);
    });
  }, [StatusUpdateArticle]);

  useEffect(() => {
    setUpdate(AlldisapproveArticle);
  }, [AlldisapproveArticle]);

  const hanldeFetchData = (PageNumber) => {
    setGetSearch((prev) => ({ ...prev, page: PageNumber }));
    dispatch(
      GetDisapproveArticleByAPISlice(
        createDataSetParams({ ...getSearch, page: PageNumber })
      )
    ).then((res) => {
      setPagination(res?.payload?.data?.pagination);
    });
  };

  const handleDeleteArticle = (id) => {
    const params = { id: id, status: "deleted", isActive: true }; 
    dispatch(StatusUpdatePublishArticleByAPISlice(createDataSetParams(params)));
  };

  const handleDisapprove = (id) => {
    const params = { id: id, status: "pending", isActive: false };
    dispatch(StatusUpdatePublishArticleByAPISlice(createDataSetParams(params)));
  };

  return (
    <>
      <div className="Small-Wrapper">
        <div className="FilterArea">
          <div className="FilterLeft">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
              />
            </div>
            <div className="form-group">
              <label>From Date</label>
              <input type="date" className="form-control" />
            </div>
            <div className="form-group">
              <label>To Date</label>
              <input type="date" className="form-control" />
            </div>
            <div className="form-group">
              <label>&nbsp;</label>
              <button className="Button">Apply</button>
              <button className="Button Cancel">
                <i className="fa fa-refresh" />
              </button>
            </div>
          </div>
          <div className="FilterRight">
            <div className="form-group">
              <label>Timeframe</label>
              <select className="form-control">
                <option>Select Timeframe</option>
                <option>Today</option>
                <option>This Week</option>
                <option>This Month</option>
                <option>This Year</option>
              </select>
            </div>
          </div>
        </div>
        <div className="TableList">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Article ID</th>
                <th>Article Description</th>
                <th>Posted On</th>
                <th>Summary</th>
                <th>Article Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableSkeleton />
              ) : Array.isArray(iState) && iState.length > 0 ? (
                iState?.map((item, index) => {
                  return (
                    <tr>
                      <td>{(pagination.currentPage - 1) * 10 + index + 1}</td>
                      <td>{item?.articleId?.toUpperCase()}</td>
                      <td>
                      {getPlainText(item?.content)?.slice(0, 20)}...
                        <Link
                          to={`/ContentManagement/PublishArticleByAdmin/AllArticleDetails/${item?._id}`}
                          state={item}
                          className="blue"
                        >
                          view more
                        </Link>
                        <br />
                      </td>
                      <td>{item?.createdAt}</td>
                      <td>
                      {getPlainText(item?.summary)?.slice(0, 20)}...
                        <Link 
                          to={`/ContentManagement/PublishArticleByAdmin/AllArticleDetails/${item?._id}`}
                          state={item}
                          className="blue"
                        >
                          view more
                        </Link>
                        <br />
                      </td>
                      <td>
                        <span className={item?.isActive ? "Green" : "Red"}>
                          {item?.isActive ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <div className="Actions">
                          <label className="Switch">
                            <input
                              type="checkbox"
                              checked={true}
                              onChange={() => handleDisapprove(item?._id)}
                            />
                            <span className="slider" />
                          </label>
                          <Link
                            to={"/ContentManagement/EditPublishNewsArticle"}
                            state={item}
                          >
                            <MdEdit color="green" />
                          </Link>
                          <a
                            className="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                            onClick={() => setDataForHold(item?._id)}
                          >
                            <i className="fa fa-trash" aria-hidden="true" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NotFoundData />
              )}
            </tbody>
          </table>
        </div>
        {iState.length > 0 && (
          <CustomPagination
            activePage={pagination?.currentPage}
            itemsCountPerPage={pagination?.pageSize}
            totalItemsCount={pagination?.totalPages}
            pageRangeDisplayed={5}
            hanldeFetchData={hanldeFetchData}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        )}
      </div>

      <div className="ModalBox">
        <div id="DeleteModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to Delete? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-dismiss="modal"
                      onClick={() => handleDeleteArticle(dataForHold)}
                    >
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisapprovedArticle;
