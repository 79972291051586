import React from 'react'
import { Outlet } from 'react-router-dom'

const LoginCommonOutlet = () => {
  return (
    <>
     <Outlet/> 
    </>
  )
}

export default LoginCommonOutlet
