import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const KnowledgeInsightSlice = createAsyncThunk("listingInsight/published",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingInsight/published?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const HiddenKnowledgeInsightSlice = createAsyncThunk("listingInsight/hidden",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingInsight/hidden?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const DisapprovedKnowledgeInsightSlice = createAsyncThunk("listingInsight/disapprove",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingInsight/disapprove?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const DeletedKnowledgeInsightSlice = createAsyncThunk("listingInsight/deleted",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingInsight/deleted?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const CreateKnowledgeInsightSlice = createAsyncThunk("Create-Knowledge",async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.post(`insightCreate`,data);
      if (response.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const StatusUpdateKnowledgeInsightSlice = createAsyncThunk("pending-Status-Update-Knowledge",async ({id,data}, { rejectWithValue }) => {
  try {
    console.log(data)
    const response = await axiosInstance.patch(`insightStatus/${id}`,data); 
    if (response.status === 200) {
      return response.data; 
    } else {
      return rejectWithValue(response.data);
    }
  } catch (error) {
    console.error("API error:", error);
    return rejectWithValue(error.response?.data || { message: "An error occurred" });
  }
}
);

const KnowledgeInsigthSlice = createSlice({
  name: 'Knowledge Insight',
  initialState: {
    AllKnowledgeInsight: [],
    HiddenKnowledgeInsight: [],
    DisapproveKnowledgeInsight: [],
    CreatedKnowledgeInsight: [],
    DeletedKnowledgeInsight: [],
    StatusUpdateKnowledgeInsight: [],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      toast.error(state.error);
    }

    builder
      .addCase(KnowledgeInsightSlice.pending,handlePendingState)
      .addCase(KnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.AllKnowledgeInsight = action?.payload?.data?.insights; 
        state.loading = false;
      })
      .addCase(KnowledgeInsightSlice.rejected,handleRejectState)

      .addCase(CreateKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(CreateKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.CreatedKnowledgeInsight = action.payload.data; 
        state.loading = false;
        toast.success("Article Created Successfully!"); 
      })
      .addCase(CreateKnowledgeInsightSlice.rejected,handleRejectState)

      .addCase(HiddenKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(HiddenKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.HiddenKnowledgeInsight = action?.payload?.data?.insights; 
        state.loading = false;
      })
      .addCase(HiddenKnowledgeInsightSlice.rejected,handleRejectState)

      .addCase(DisapprovedKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(DisapprovedKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.DisapproveKnowledgeInsight = action?.payload?.data?.insights; 
        state.loading = false;
      })
      .addCase(DisapprovedKnowledgeInsightSlice.rejected,handleRejectState)

      .addCase(DeletedKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(DeletedKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.DeletedKnowledgeInsight = action?.payload?.data?.insights; 
        state.loading = false;
      })
      .addCase(DeletedKnowledgeInsightSlice.rejected,handleRejectState)

      .addCase(StatusUpdateKnowledgeInsightSlice.pending,(state,action) => {
        state.error = null; 
        state.validationErrors = []; 
      })
      .addCase(StatusUpdateKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action?.payload?.data)
        state.StatusUpdateKnowledgeInsight = action?.payload?.data; 
        state.loading = false;
      })
      .addCase(StatusUpdateKnowledgeInsightSlice.rejected,handleRejectState)
  },
});


export default KnowledgeInsigthSlice.reducer;
