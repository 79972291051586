import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { EmailForVerify, ForgotPasswordSlice, VerifyOTPSlice } from "../../../redux/features/AuthSlice";
import { toast } from "react-toastify";

const OTPVerify = () => {
  const navigate = useNavigate();
  const [otpState, setOtpState] = useState("");
  const [otpArr, setOtpArr] = useState([0, 1, 2, 3, 4, 5]);
  const [selectItem, setSelectItem] = useState(0);
  const OTPRef = useRef([]);
  const [OTP, setOTP] = useState([]);
  const dispatch = useDispatch();
  const [counting,setCounting] = useState(30);
  const { EmailHoldForOPT } = useSelector((state) => state.Auth);

  const handleChange = ({ event, item }) => {
    const { name, value } = event.target;

    if (value > 0 && item + 1 < otpArr.length) {
      setSelectItem(item + 1);
      OTPRef?.current?.[item + 1].focus();
    } else if (value?.length === 0 && item - 1 >= 0) {
      setSelectItem(item - 1);
      OTPRef?.current?.[item - 1].focus();
    }
    const temp = [...OTP];
    if(value === ''){
      temp.splice(item,1)
    }else{
      temp[item] = value
    }
    setOTP(temp);
  };

  const hanldeSubmit = () => {
    const temp = {
      email: EmailHoldForOPT,
      otp: Number(OTP?.join("")),
    };
    if(OTP?.length=== 6) 
    dispatch(VerifyOTPSlice(temp)).then((res) => {
      console.log("res:",res);
      if(res?.payload?.customStatus === 200){
        navigate("/login/ResetPassword");
      }
    })
  };

  useEffect(() => {
    if (OTPRef.current[0]) {
      OTPRef?.current?.[0].focus();
    }
  }, []);

  const handleResendPassword = ()=>{
    console.log(EmailHoldForOPT);
     dispatch(ForgotPasswordSlice({email:EmailHoldForOPT})).then((res)=>{
          if(res.payload?.otp){
            dispatch(EmailForVerify(EmailHoldForOPT))
            navigate("/login/OTPVerify");
            toast.info(res.payload?.otp);
          }
        })
  }

  useEffect(() => {
    if (counting <= 0) return; // Stop when counting reaches 0

    const interval = setInterval(() => {
      setCounting((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval
  }, [counting]);

  return (
    <>
      <div className="LoginArea">
        <div className="LoginBox">
          <div className="LoginLeft">
            <figure>
              <img src={require("../../../assets/images/Logo.png")} />
            </figure>
            {/* <h2>Huk N <span>Buk</span></h2> */}
            <h3>
              <span>OTP Verification</span>
            </h3>
            <h4>
              Please enter 6 digit OTP Verification code recieved <br /> on your
              email address
            </h4>
            <form>
              <div className="form-group">
                <div className="OTPBox">
                  {otpArr?.map((item) => { 
                    return (
                      <input
                        type="text"
                        key={item}
                        value={OTP[item]} 
                        ref={(el) => (OTPRef.current[item] = el)}
                        onChange={(event) => {
                          const regex = /^[^A-Za-z@#$%^&*()_+={}[\]:;"'<>,.?/!\\~-]*$/;
                          if(regex.test(event.target.value)){
                            handleChange({ event, item });
                          }
                        }}
                        style={{
                          border: `${
                            item === selectItem
                              ? "1px solid blue"
                              : "1px solid black"
                          }`,
                          boxShadow: `${
                            item === selectItem
                              ? "rgba(0, 0, 0, 0.45) 1.95px 1.95px 2.6px"
                              : "none"
                          }`,
                        }}
                        className="form-control"
                        maxLength={1}
                      />
                    );
                  })}
                </div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                <p>
                  00:{counting}
                </p>
                <p className="text-right" onClick={handleResendPassword}>
                  <a>Resend</a>
                </p>
                </div>
              </div>
              {/* <button class="Login">Submit</button>  */}
              <Link className="Login" onClick={hanldeSubmit}>
                VERIFY OTP
              </Link>
              <Link className="Login Back aa" onClick={() => navigate(-2)}>
                Back to Login
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTPVerify;
