import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./features/AuthSlice";
import  UserManagementSlice  from "./features/UserManagementSlice";
import CategorySlice from './features/CategorySlice';
import ArticleSlice from './features/Publish Article By Admin Slices/ArticleSlice';
import HiddenArticleSlice from './features/Publish Article By Admin Slices/HiddenArticle';
import disapproveArticleSlice from './features/Publish Article By Admin Slices/disapproveArticle';
import DeleteArticleSlice from './features/Publish Article By Admin Slices/DeletedArticle';
import KnowledgeInsigthSlice from './features/Knowledge insight by Admin/KnowledgeInsightByAdminSlice'
import BannerSlice from './features/Banner Management/BannerManagement'
import ArticleByAPISlice from './features/Publish Article By API Slices/ArticleByAPISlice'
import StaticContentSlice from './features/Static Content Management slice/StaticContentManagement'
import PendingArticleByAPISlice from './features/Publish Article By API Slices/PendingByAPISlice'
import PendingArticleByAdminSlice from './features/Publish Article By Admin Slices/PendingArticle'
import PendingKnowledgeInsigthSlice from './features/Knowledge insight by Admin/PendingKnowledgeInsigth'
import MyAccountAdminSlice from './features/My Account/MyAccount';
import SubCategorySlice from './features/Sub Category Slice/SubCategorySlice'

const store = configureStore({
  reducer: {
    Auth:AuthSlice,
    UserManagement:UserManagementSlice,
    Category:CategorySlice,
    SubCategory:SubCategorySlice,
    Article:ArticleSlice,
    Hidden:HiddenArticleSlice,
    disapprove:disapproveArticleSlice,
    Delete:DeleteArticleSlice,
    MyAccountAdmin:MyAccountAdminSlice,
    KnowledgeInsigth:KnowledgeInsigthSlice,
    Banner:BannerSlice,
    PendingArticle:PendingArticleByAdminSlice,
    ArticleByAPI:ArticleByAPISlice,
    StaticContent:StaticContentSlice,
    PendingArticleByAPI:PendingArticleByAPISlice,
    PendingKnowledgeInsigth:PendingKnowledgeInsigthSlice
    },
  });
  
  export default store;