import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const PendingKnowledgeInsightSlice = createAsyncThunk("pending-listingInsight/published",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingInsight/pending?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);


export const CreatePendingKnowledgeInsightSlice = createAsyncThunk("pending-Create-Knowledge",async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.post(`insightCreate`,data);
      if (response.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data);  
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const StatusUpdatePendingKnowledgeInsightSlice = createAsyncThunk("pending-Status-Update-Knowledge",async ({id,data}, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.patch(`insightStatus/${id}`,data); 
      if (response.status === 200) {
        return response.data; 
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const UpdateKnowledgeInsightSlice = createAsyncThunk("Update-Knowledge",async ({id,data}, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.patch(`updateInsight/${id}`,data); 
      if (response.status === 200) {
        return response.data; 
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const PendingKnowledgeInsigthSlice = createSlice({
  name: 'Pending-Knowledge-Insight',
  initialState: {
    AllPendingKnowledgeInsight: [],
    CreatedPendingKnowledgeInsight: [],
    StatusUpdatePendingKnowledgeInsight: [],
    UpdateKnowledgeInsight: [],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      toast.error(state.error);
    }

    builder
      .addCase(PendingKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(PendingKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.AllPendingKnowledgeInsight = action?.payload?.data?.insights; 
        state.loading = false;
      })
      .addCase(PendingKnowledgeInsightSlice.rejected,handleRejectState)

      .addCase(CreatePendingKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(CreatePendingKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.CreatedPendingKnowledgeInsight = action.payload.data; 
        state.loading = false;
        toast.success("Article Created Successfully!"); 
      })
      .addCase(CreatePendingKnowledgeInsightSlice.rejected,handleRejectState)

      .addCase(StatusUpdatePendingKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(StatusUpdatePendingKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.StatusUpdatePendingKnowledgeInsight = action?.payload?.data?.insights; 
        state.loading = false;
      })
      .addCase(StatusUpdatePendingKnowledgeInsightSlice.rejected,handleRejectState) 

      .addCase(UpdateKnowledgeInsightSlice.pending,handlePendingState)
      .addCase(UpdateKnowledgeInsightSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.UpdateKnowledgeInsight = action?.payload?.data; 
        state.loading = false;
      })
      .addCase(UpdateKnowledgeInsightSlice.rejected,handleRejectState) 
  },
});


export default PendingKnowledgeInsigthSlice.reducer;
