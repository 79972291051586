import React, { useEffect, useState } from 'react'
import EditContact from './Edit Contact us/EditContact'
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ContactUsGetSlice } from '../../../redux/features/Static Content Management slice/StaticContentManagement';

const ContactUs = () => {
  const [iState,setUpdate] = useState([]);
  const {state} = useLocation();
  const dispatch = useDispatch();

  useEffect(()=>{
   dispatch(ContactUsGetSlice()).then((res)=>{
    setUpdate((prev)=>({...prev,id:res?.payload?.data?.[0]?._id,data:res?.payload?.data?.[0]?.details}));
   })
  },[])

  console.log(iState)



  return (
    <>
     <div className="TitleBox">
        <h4 className="Title">Contact Us</h4>
        <Link className="TitleLink" to={"/StaticContent/EditStaticContent"} state={{id:iState?.id,type:'Contact Us',heading:'Contact Us',content:iState?.data}}>
          Edit Contact Us
        </Link>
      </div>

      <div className="StaticContentArea"> 
      <div dangerouslySetInnerHTML={{ __html: iState?.data }}/>
      </div>
    </>
  )
}

export default ContactUs
