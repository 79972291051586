import React from 'react'

const OfflineSave = () => {
  return (
    <>
     <div className="row">
  <div className="col-sm-9">
    <div className="Small-Wrapper">
      <div className="News-References">
        <div className="TableList">
          <table>
            <tbody>
              <tr>
                <td>
                  Government Launches New Electric Vehicle (EV) Scheme to
                  Accelerate Sustainable Mobility
                </td>
                <td>
                  <img className="image-view" src="images/user.png" />
                </td>
              </tr>
              <tr>
                <td>
                  Government Launches New Electric Vehicle (EV) Scheme to
                  Accelerate Sustainable Mobility
                </td>
                <td>
                  <img className="image-view" src="images/user.png" />
                </td>
              </tr>
              <tr>
                <td>
                  Government Launches New Electric Vehicle (EV) Scheme to
                  Accelerate Sustainable Mobility
                </td>
                <td>
                  <img className="image-view" src="images/user.png" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
 
    </>
  )
}

export default OfflineSave
