import React from 'react'

const NotFoundData = () => {
  return (
    <>
     <tr>
      <td colSpan={10}>There is not data found</td>
    </tr> 
    </>
  )
}

export default NotFoundData
