import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const ArticleCreateSlice = createAsyncThunk("Article-Create-admin",async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('newArticle',data);
      // if (response.status === 200) {
        return response.data;
      // } else {
      //   return rejectWithValue(response.data);a
      // }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ArticleUpdateSlice = createAsyncThunk("Article-Update",async ({id,data}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`updatearticle/${id}`,data);
      // if (response.status === 200) {
        return response.data;
      // } else {
      //   return rejectWithValue(response.data);a
      // }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ArticleGetSlice = createAsyncThunk("Article-listofArticles",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listofArticles/published?${param}`);
        return response.data;

    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error?.response?.data || { message: "An error occurred" });
    }
  }
);

export const ArticleDeleteSlice = createAsyncThunk("Article-softdeleteArticle-delete",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`hidearticle?${param}`);
        return response.data;
    } catch (error) {
      console.error("API error:", error); 
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ArticleHideSlice = createAsyncThunk("Article-Hide-admin",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`hidearticle?${param}`);
        return response.data;
    } catch (error) {
      console.error("API error:", error); 
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ArticleDisapproveSlice = createAsyncThunk("Article-DisApproved",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`disapprovearticle/${param}`);
        return response.data;
    } catch (error) {
      console.error("API error:", error); 
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const ArticleSlice = createSlice({
  name: 'Article-Admin',
  initialState: {
    ArticleCreated: [],
    AllArticle: [],
    DeletedArticle:[],
    HideArticle:[],
    DisApprovedArticle:[],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      console.log(state.error);
      // toast.error(state.error);
    }

    builder
      .addCase(ArticleCreateSlice.pending,handlePendingState)
      .addCase(ArticleCreateSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.ArticleCreated = action.payload.data; 
        state.loading = false;
        toast.success("Article created successfully!");
      })
      .addCase(ArticleCreateSlice.rejected,handleRejectState)

      .addCase(ArticleGetSlice.pending,handlePendingState)
      .addCase(ArticleGetSlice.fulfilled, (state, action) => {
        state.AllArticle = action.payload.data.articles; 
        state.loading = false;
        // toast.success("Article Created Successfully!");
      })
      .addCase(ArticleGetSlice.rejected,handleRejectState)

      .addCase(ArticleDeleteSlice.pending,handlePendingState)
      .addCase(ArticleDeleteSlice.fulfilled, (state, action) => {
        state.DeletedArticle = action.payload.data; 
        state.loading = false;
        toast.success("Article deleted successfully!"); 
      })
      .addCase(ArticleDeleteSlice.rejected,handleRejectState)

      .addCase(ArticleHideSlice.pending,handlePendingState)
      .addCase(ArticleHideSlice.fulfilled, (state, action) => {
        state.HideArticle = action.payload.data; 
        state.loading = false;
        toast.success("Article status change successfully!");
      })
      .addCase(ArticleHideSlice.rejected,handleRejectState)

      .addCase(ArticleDisapproveSlice.pending,handlePendingState)
      .addCase(ArticleDisapproveSlice.fulfilled, (state, action) => {
        state.DisApprovedArticle = action.payload.data; 
        state.loading = false;
        toast.success("Article disapproved successfully!");
      })
      .addCase(ArticleDisapproveSlice.rejected,handleRejectState)
  },
});


export default ArticleSlice.reducer;
