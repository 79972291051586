import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

const Dashboard = () => {
    const location = useLocation().pathname;

    console.log("location:",location);
  return (
    <>
     <div>
  <div>
    <div className="TitleBox">
      <h4 className="Title">Dashboard</h4>
    </div>
    <div className="CommonTabs">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link ${["/UserDashboard",'/'].includes(location) && 'active'}`}
            data-toggle="tab"
            to={'UserDashboard'}
          >
            User Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link  ${['/QuizDashboard'].includes(location) && 'active'}`} data-toggle="tab" to="QuizDashboard">
            Quiz Dashboard
          </Link>
        </li>
      </ul>
    </div>
    <div className="tab-content">
      <Outlet/>
    </div>
  </div>
</div> 
    </>
  )
}

export default Dashboard
