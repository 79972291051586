import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom'
import NotFoundData from '../../../../common/NotFoundData';
import TableSkeleton from '../../../../common/Skeleton';
import useParamsFormat from '../../../../common/Params Values format/ParamsFormat';
import CustomPagination from '../../../../common/Pagination/Pagination';
import { MdEdit } from 'react-icons/md';
import { GetDeleteArticleByAPISlice } from '../../../../redux/features/Publish Article By API Slices/DeletedArticle';
import { StatusUpdatePublishArticleByAPISlice } from '../../../../redux/features/Publish Article By API Slices/ArticleByAPISlice';
import useRemoveTag from '../../../../common/Remove Tag of ReactQuil/RemoveTag';

const DeletedArticle = () => {
  const location = useLocation().pathname.split("/").splice(1);
  const { pageNo } = useParams();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const [iState, setUpdate] = useState([]);
  const { createDataSetParams } = useParamsFormat();
  const [dataForHold, setDataForHold] = useState();
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: pageNo ?? 1,
  });

  const {getPlainText} = useRemoveTag();

  const { StatusUpdateArticle } = useSelector(
    (state) => state?.ArticleByAPI
  );
 
  const { loading,AllDeleteArticle } = useSelector(
    (state) => state?.Delete
  );

  useEffect(() => {
    dispatch(GetDeleteArticleByAPISlice(createDataSetParams(getSearch))).then(
      (res) => {
        setPagination(res?.payload?.data?.pagination);
      }
    );
  }, [StatusUpdateArticle]);

  useEffect(() => {
    setUpdate(AllDeleteArticle);
  }, [AllDeleteArticle]);

  const hanldeFetchData = (PageNumber) => {
    setGetSearch((prev) => ({ ...prev, page: PageNumber }));
    dispatch(
      GetDeleteArticleByAPISlice(
        createDataSetParams({ ...getSearch, page: PageNumber })
      )
    ).then((res) => {
      setPagination(res?.payload?.data?.pagination);
    });
  };

  const handleDeleteArticle = (id) => {
    const params = { id: id, status: "deleted", isActive: true };
    dispatch(StatusUpdatePublishArticleByAPISlice(createDataSetParams(params)));
  };

  const handleHideArticle = (id) => {
    const params = { id: id, status: "hidden", isActive: false };
    dispatch(StatusUpdatePublishArticleByAPISlice(createDataSetParams(params)));
  };

  const handleDisapprove = (id) => {
    const params = { id: id, status: "disapprove", isActive: false };
    dispatch(StatusUpdatePublishArticleByAPISlice(createDataSetParams(params)));
  };

  return (
    <>
      <>
        <div className="Small-Wrapper">
          <div className="FilterArea">
            <div className="FilterLeft">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  name="search"
                  onChange={(e) =>
                    setGetSearch((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  className="form-control"
                  placeholder="Search here"
                />
              </div>
              <div className="form-group">
                <label>From Date</label>
                <input
                  type="date"
                  name="fromDate"
                  onChange={(e) =>
                    setGetSearch((prev) => ({
                      ...prev,
                      [e?.target?.name]: e?.target?.value,
                    }))
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>To Date</label>
                <input
                  type="date"
                  name="toDate"
                  onChange={(e) =>
                    setGetSearch((prev) => ({
                      ...prev,
                      [e?.target?.name]: e?.target?.value,
                    }))
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>&nbsp;</label>
                <button className="Button">Apply</button>
                <button className="Button Cancel">
                  <i className="fa fa-refresh" />
                </button>
              </div>
            </div>
            <div className="FilterRight">
              <div className="form-group">
                <label>Timeframe</label>
                <select className="form-control">
                  <option>Select Timeframe</option>
                  <option>Today</option>
                  <option>This Week</option>
                  <option>This Month</option>
                  <option>This Year</option>
                </select>
              </div>
            </div>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Article ID</th>
                  <th>Article Description</th>
                  <th>Posted On</th>
                  <th>Summary</th>
                  <th>Article Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableSkeleton />
                ) : Array.isArray(iState) && iState?.length > 0 ? (
                  iState?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {(pagination?.currentPage - 1) * 10 + index + 1}
                        </td>
                        <td>{item?.articleId?.toUpperCase()}</td>
                        <td>
                        <div>
                       {getPlainText(item?.content)?.slice(0, 20)}...
                    </div>
                          <Link
                            to={`/ContentManagement/PublishArticleByAPI/AllArticleDetails/${item?._id}`}
                            state={item}
                            className="blue"
                          >
                            view more
                          </Link>
                        </td>
                        <td>{item?.createdAt?.slice(0, 10)}</td>
                        <td>
                        <div>
                       {getPlainText(item?.summary)?.slice(0, 20)}...
                    </div>
                          <Link
                            to={`/ContentManagement/PublishArticleByAPI/AllArticleDetails/${item?._id}`}
                            state={item}
                            className="blue"
                          >
                            view more
                          </Link>
                        </td>
                        <td>
                          <span className={item?.isActive ? "Green" : "Red"}>
                            {item?.isActive ? "Active" : "InActive"}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NotFoundData />
                )}
              </tbody>
            </table>
          </div>
        </div>
        {iState?.length > 0 && (
          <CustomPagination
            activePage={pagination?.currentPage}
            itemsCountPerPage={pagination?.perPage}
            totalItemsCount={pagination?.totalPages}
            pageRangeDisplayed={5}
            hanldeFetchData={hanldeFetchData}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        )}
      </>
      <div className="ModalBox">
        <div id="DeleteModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to Delete? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-dismiss="modal"
                      onClick={() => handleDeleteArticle(dataForHold)}
                    >
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletedArticle;
