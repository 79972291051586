import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInstance.js';

export const CategoryGetSlice = createAsyncThunk("Category-get",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listCategory?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const CategoryGetForDropDownSlice = createAsyncThunk("Category-get-ForDropDown",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`categoryListing`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const AddCategorySlice = createAsyncThunk("Category-Add",async (Data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('categoryAdd',Data);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const DeleteCategorySlice = createAsyncThunk("Category-delete",async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`categoryDelete/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const UpdateCategorySlice = createAsyncThunk("Category-Update",async ({id,iState}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`categoryUpdate/${id}`,iState);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const CategorySlice = createSlice({
  name: 'Category',
  initialState: {
    AllCategory: [],
    CategoryForDropDown: [],
    NewCategoryAdded: [],
    deletedCategory: [],
    UpdatedCategory: [],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      // toast.error(state.error);
    }

    builder
      .addCase(CategoryGetSlice.pending,handlePendingState)
      .addCase(CategoryGetSlice.fulfilled, (state, action) => { 
        state.AllCategory = action.payload.data; 
        state.loading = false;
        // toast.success("LoggedIn Successfully!");
      })
      .addCase(CategoryGetSlice.rejected,handleRejectState)

      .addCase(CategoryGetForDropDownSlice.pending,handlePendingState)
      .addCase(CategoryGetForDropDownSlice.fulfilled, (state, action) => { 
        state.CategoryForDropDown = action.payload.data; 
        state.loading = false;
        // toast.success("LoggedIn Successfully!");
      })
      .addCase(CategoryGetForDropDownSlice.rejected,handleRejectState)

      .addCase(AddCategorySlice.pending,handlePendingState)
      .addCase(AddCategorySlice.fulfilled, (state, action) => { 
        state.NewCategoryAdded = action.payload.data; 
        state.loading = false;
        toast.success("Category Created Successfully!");
      })
      .addCase(AddCategorySlice.rejected,handleRejectState)

      .addCase(DeleteCategorySlice.pending,handlePendingState)
      .addCase(DeleteCategorySlice.fulfilled, (state, action) => { 
        // console.log(action.payload.data)
        state.deletedCategory = action.payload.data; 
        state.loading = false;
        toast.success("Category deleted Successfully!");
      })
      .addCase(DeleteCategorySlice.rejected,handleRejectState)

      .addCase(UpdateCategorySlice.pending,handlePendingState)
      .addCase(UpdateCategorySlice.fulfilled, (state, action) => { 
        state.UpdatedCategory = action.payload.data; 
        state.loading = false;
        toast.success("Category deleted Successfully!");
      })
      .addCase(UpdateCategorySlice.rejected,handleRejectState)
  },
});


export default CategorySlice.reducer;
