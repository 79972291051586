import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { EmailForVerify, ForgotPasswordSlice } from "../../../redux/features/AuthSlice";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSendOTP = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    setError(""); // Clear errors if validation passes

    dispatch(ForgotPasswordSlice({email:email})).then((res)=>{
      if(res.payload?.otp){
        dispatch(EmailForVerify(email))
        navigate("/login/OTPVerify");
        toast.info(res.payload?.otp)
      }
    })
  };

  return (
    <div className="LoginArea">
      <div className="LoginBox">
        <div className="LoginLeft">
          <figure>
            <img src={require('../../../assets/images/Logo.png')} alt="Logo" />
          </figure>
          <h3>
            <span>Forgot Password</span>
          </h3>
          <h4>
            No Problem! Just provide your e-mail address and we’ll <br /> send you a
            reset OTP.
          </h4>
          <form onSubmit={handleSendOTP}>
            <div className="form-group">
              <label>Enter your email ID</label>
              <input
                type="text"
                placeholder="Enter your email ID"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="Icon">
                <i className="fa fa-envelope" />
              </span>
              {error && <p style={{color:'red'}}>{error}</p>}
            </div>
            <button type="submit" className="Login">
              SEND OTP
            </button>
            <Link className="Login Back aa" onClick={() => navigate(-1)}>
              Back to Login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
