import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUserManagementSlice, UpdateUserStatusSlice } from '../../redux/features/UserManagementSlice';
import TableSkeleton from '../../common/Skeleton';
import useParamsFormat from '../../common/Params Values format/ParamsFormat';
import CustomPagination from '../../common/Pagination/Pagination';
import { Link } from 'react-router-dom';

const UserManagement = () => {
  const dispatch = useDispatch();
  const [iState,setUpdate] = useState([]);
  const [pagination, setPagination] = useState({});
  const [holdData,setHoldData] = useState({});
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: 1,
  });
  const {createDataSetParams} = useParamsFormat();

  const {loading,UpdateUser} = useSelector((state)=>state.UserManagement);

  useEffect(()=>{ 
    dispatch(GetUserManagementSlice(createDataSetParams(getSearch))).then((res)=>{
      setUpdate(res?.payload?.data?.users)
      setPagination(res?.payload?.data?.pagination) 
    })
  },[UpdateUser]);

   const hanldeFetchData = (PageNumber) =>{
         setGetSearch((prev)=>({...prev,page:PageNumber}))
         dispatch(GetUserManagementSlice(createDataSetParams({...getSearch,page:PageNumber}))).then((res)=>{
          setPagination(res?.payload?.data?.pagination)
        })
    }
  
    const handleChangeUserStatus = (item)=>{
      let data ={
        "status":item?.status === 'active' ? "inactive" : "active",
    }

    dispatch(UpdateUserStatusSlice({id:item?._id,data}));
    
    }
   
    const handleDeleteUserStatus = ()=>{
      let data ={
        "status":"inactive",
        "isaccountDeleted":true
    }

    dispatch(UpdateUserStatusSlice({id:holdData['delete']?._id,data}));
    
    }

  return (
     <>
  <>
    <div className="TitleBox" >
      <h4 className="Title">User Management</h4>
    </div>
    <div className="Small-Wrapper">
      <div className="FilterArea">
      <div className="FilterLeft">
                <div className="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    name='search'
                    onChange={(e)=>setGetSearch((prev)=>({...prev,[e.target.name]:e.target.value}))}
                    className="form-control"
                    placeholder="Search here"
                  />
                </div>
                <div className="form-group">
                  <label>From Date</label>
                  <input type="date"
                  name='fromDate'
                  onChange={(e)=>setGetSearch((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  className="form-control" />
                </div>
                <div className="form-group">
                  <label>To Date</label>
                  <input type="date"
                  name='toDate'
                  onChange={(e)=>setGetSearch((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  className="form-control" />
                </div>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <button className="Button">Apply</button>
                  <button className="Button Cancel">
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
        <div className="FilterRight">
          <div className="form-group">
            <label>Timeframe</label>
            <select className="form-control">
              <option>Select Timeframe</option>
              <option>Today</option>
              <option>This Week</option>
              <option>This Month</option>
              <option>This Year</option>
            </select>
          </div>
        </div>
      </div>
      <div className="TableList">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>User ID</th>
              <th>User Name</th>
              <th>Mobile Number</th>
              <th>User State</th>
              <th>City/District</th>
              <th>Preferred Language</th>
              <th>Registration On</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {loading ? <TableSkeleton/>:(Array.isArray(iState) && iState?.length ? (
            iState?.map((item,index)=>{
            return(  
            <tr>
              <td>{(pagination?.currentPage - 1 )*10 + index + 1}</td>
              <td>{item?.custId}</td>
              <td>{item?.name}</td>
              <td>{item?.phoneNumber}</td>
              <td>{item?.meraGaon?.slice(0,20)}...</td>
              <td>{item?.city}</td> 
              <td>Hindi</td>
              <td>{item?.createdAt?.slice(0,10)}</td>
              <td>
                <span className={`${item.status==='active' ?'Green':'Red'}`}>{item?.status==='active'?'Active':'InActive'} </span>
              </td>
              <td>
                <div className="Actions">
                  <label className="Switch">
                    <input type="checkbox" onChange={()=>handleChangeUserStatus(item)} checked={item?.status==='active'}/>
                    <span className="slider" />
                  </label>
                  <Link className="Green" to={`UserManagementDetails/PersonalDetails/${item?._id}`} state={item}>
                    <i className="fa fa-eye" /> 
                  </Link>
                  <a
                    className="Red"
                    data-toggle="modal"
                    data-target="#DeleteModal"
                    onClick={()=>setHoldData((prev)=>({...prev,delete:item}))}

                  >
                    <i className="fa fa-trash" />
                  </a>
                </div>
              </td>
            </tr>)
            })
          ) : (
            <tr>
              <td colSpan={10}>There is no data found!</td>
            </tr>
            ))
          } 
          </tbody>
        </table>
      </div>
    </div>
    <div className='ModalBox'> 
      <div id="DeleteModal" className="modal fade" role="dialog"> 
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-body">
        <div className="Decline">
          <a
            href="javascript:void(0);"
            className="CloseModal"
            data-dismiss="modal"
          >
            × 
          </a>
          <h3>Delete</h3>
          <p>Are you sure you want to Delete? </p>
          <h4>
            <a href="javascript:void(0);" data-dismiss="modal"> 
              no
            </a>
            <a href="javascript:void(0);" data-dismiss="modal" onClick={()=>handleDeleteUserStatus()}> 
              Yes
            </a>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
  </>
  {iState.length > 0 &&
              <CustomPagination
        activePage={pagination?.currentPage}
        itemsCountPerPage={pagination?.perPage}
        totalItemsCount={pagination?.totalPages}
        pageRangeDisplayed={5}
        hanldeFetchData={hanldeFetchData}
        itemClass={"page-item"}
        linkClass={"page-link"}
      />
      }
</>
   )
}

export default UserManagement
