import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import {
  CategoryGetSlice,
  DeleteCategorySlice,
} from "../../../redux/features/CategorySlice";
import TableSkeleton from "../../../common/Skeleton";
import NotFoundData from "../../../common/NotFoundData";
import useParamsFormat from "../../../common/Params Values format/ParamsFormat";
import CustomPagination from "../../../common/Pagination/Pagination";
import { CiEdit } from "react-icons/ci";
import AddNewsCategory from './AddNewsCategory';
import UpdateCategory from "./UpdateCategory";

const CategoryList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal,setShowModal] = useState({
    addCat:false,
    editCat:false
  })
  const { pageNo } = useParams();
  const {
    AllCategory,
    NewCategoryAdded,
    UpdatedCategory,
    deletedCategory,
    loading,
  } = useSelector((state) => state.Category);
  const [DataForHold, setDataForHold] = useState({});
  const [iState, setUpdate] = useState([]);
  const [serialNum, setSerialNum] = useState([]);
  const [pagination, setPagination] = useState({});
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: pageNo ?? 1,
  });
  const { createDataSetParams } = useParamsFormat();

  useEffect(() => {
    setUpdate(AllCategory);
    setSerialNum([...Array(AllCategory?.length + 1).keys()].splice(1));
  }, [AllCategory]);

  useEffect(() => {
    dispatch(CategoryGetSlice(createDataSetParams(getSearch)))?.then((res) => {
      if (res.payload.pagination) {
        console.log(res.payload?.pagination);
        setPagination(res.payload?.pagination);
      }
    });
  }, [NewCategoryAdded, deletedCategory, UpdatedCategory]);

  const hanldeFetchData = (pageNumber) => {
    setGetSearch((prev) => ({ ...prev, page: pageNumber }));
    console.log(pageNumber);

    dispatch(
      CategoryGetSlice(createDataSetParams({ ...getSearch, page: pageNumber }))
    )?.then((res)=>{
      setPagination(res.payload?.pagination);
    })
  };

  const handleDelete = (id) => {
    console.log(id);
    dispatch(DeleteCategorySlice(id)).then(() => {});
  };

  return (
    <>
      <div className="TitleBox">
        <h4 className="Title">Content Management</h4>
        <div>

        <Link
          class="TitleLink"
          onClick={()=>navigate(-1)}
          >
          Back
        </Link>
        <Link
          class="TitleLink"
          data-toggle="modal"
          data-target="#UploadModal"
          onClick={()=>setShowModal((prev)=>({...prev,addCat:!prev['addCat']}))}
          >
          Add New
        </Link>
          </div>
      </div>
      <div className="Small-Wrapper">
        <div className="TableList">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Category ID</th>
                <th>Category Name</th>
                <th>Category Image</th>
                <th>Uploaded On</th>
                <th>Last Updated On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableSkeleton />
              ) : Array.isArray(iState) && iState.length > 0 ? (
                iState?.map((item, index) => {
                  return (
                    <tr key={index}>
                    <td>{(pagination?.currentPage - 1 )*10 + index + 1}</td>
                    <td>{item?.catId}</td>
                      <td>{item?.catName}</td>
                      <td>
                        <img
                          src={item?.catImage}
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "100%",
                          }}
                        />
                      </td>
                      <td>{item?.updatedAt?.slice(0, 10)}</td>
                      <td>{item?.updatedAt?.slice(0, 10)}</td>
                      <td>
                        <div className="Actions">
                          <a className="Blue">
                            <i className="fa fa-download" aria-hidden="true" />
                          </a>
                          <a
                            className="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                          >
                            <i
                              className="fa fa-trash"
                              onClick={() => setDataForHold({ id: item?._id })}
                              aria-hidden="true"
                            />
                          </a>
                          <Link
                            className="Red"
                            data-toggle="modal"
                            data-target="#UpdateModal"
                            onClick={()=>{
                              setShowModal((prev)=>({...prev,editCat:!prev['editCat']}))
                              setDataForHold(item);
                            }}
                          >
                            <CiEdit
                              color="blue"
                              fontSize={"16px"}
                              aria-hidden="true"
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NotFoundData />
              )}
            </tbody>
          </table>
        </div>
        <Outlet />
      </div>
      <CustomPagination
        activePage={pagination?.currentPage}
        itemsCountPerPage={pagination?.pageSize}
        totalItemsCount={pagination?.totalPages}
        pageRangeDisplayed={5}
        hanldeFetchData={hanldeFetchData}
        itemClass={"page-item"}
        linkClass={"page-link"}
      />

      <div className="ModalBox">
        <div id="DeleteModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to Delete? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a
                      href="javascript:void(0);"
                      onClick={() => handleDelete(DataForHold?.id)}
                      data-dismiss="modal"
                    >
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal?.addCat &&
      <AddNewsCategory showModal={showModal.addCat} setShowModal={setShowModal}/>
      }
      {showModal?.editCat &&
      <UpdateCategory state={DataForHold} showModal={showModal.editCat} setShowModal={setShowModal}/>
      }
    </>
  );
};

export default CategoryList;
