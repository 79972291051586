import { City } from "country-state-city";
import { onImageHandler } from "../common/Image upload on bucket/UploadImageOnBucket";

const usePublishArticle = () =>{

    const handleUpload = async ({e,setImageLoading,setUpdate}) => {
        setImageLoading(true);
        const data = await onImageHandler(e);
        setUpdate((prev) => ({ ...prev, [e.target.name]:data[1].location }));
        setImageLoading(false);
      };

      const handleChangeRegion = ({e,setSelectedRegion,setCityArr})=>{
        setSelectedRegion((prev)=>({...prev,state:e,city:null}))
        const StatesCity = City.getCitiesOfState('IN',e.value); // 'IN' is the ISO code for India
        setCityArr(StatesCity.map((item)=>({value:item?.name,label:item?.name})));
      }
    
    return({handleUpload,handleChangeRegion})
}

export default usePublishArticle