import React from 'react'

const NotificationManagement = () => {
  return (
    <>
    <div className="TitleBox">
  <h4 className="Title">Notification Management</h4>
</div>

      <div className="tab-pane active" id="UserDashboard">
  <div className="TitleBox">
    <h4 className="Title" />
    <a className="TitleLink" data-toggle="modal" data-target="#UploadModal">
      Add New Notification
    </a>
  </div>
  <div className="Small-Wrapper">
    <div className="FilterArea">
      <div className="FilterLeft">
        <div className="form-group">
          <label>Search</label>
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
          />
        </div>
        <div className="form-group">
          <label>From Date</label>
          <input type="date" className="form-control" />
        </div>
        <div className="form-group">
          <label>To Date</label>
          <input type="date" className="form-control" />
        </div>
        <div className="form-group">
          <label>&nbsp;</label>
          <button className="Button">Apply</button>
          <button className="Button Cancel">
            <i className="fa fa-refresh" />
          </button>
        </div>
      </div>
      <div className="FilterRight">
        <div className="form-group">
          <label>Timeframe</label>
          <select className="form-control">
            <option>Select Timeframe</option>
            <option>Today</option>
            <option>This Week</option>
            <option>This Month</option>
            <option>This Year</option>
          </select>
        </div>
      </div>
    </div>
    <div className="TableList">
      <table>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Title of Notification</th>
            <th>Content</th>
            <th>Sent On</th>
            <th>User Group</th>
            <th>User ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Archana</td>
            <td>
              <img src="images/car.png" />
              &nbsp;&nbsp; lorem
            </td>
            <td>22-09-23</td>
            <td>All</td>
            <td>All</td>
            <td>
              <div className="Actions">
                <a className="Blue" href="">
                  <i className="fa fa-eye" aria-hidden="true" />
                </a>
                <a className="Green" href="">
                  <i className="fa fa-pencil" aria-hidden="true" />
                </a>
                <a className="Red" href="">
                  <span className="Red">Resend </span>
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Archana</td>
            <td>
              <img src="images/car.png" />
              &nbsp;&nbsp; lorem
            </td>
            <td>22-09-23</td>
            <td>User</td>
            <td>D-23494</td>
            <td>
              <div className="Actions">
                <a className="Blue" href="">
                  <i className="fa fa-eye" aria-hidden="true" />
                </a>
                <a className="Green" href="">
                  <i className="fa fa-pencil" aria-hidden="true" />
                </a>
                <a className="Red" href="">
                  <span className="Red">Resend </span>
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Archana</td>
            <td>
              <img src="images/car.png" />
              &nbsp;&nbsp; lorem
            </td>
            <td>22-09-23</td>
            <td>User</td>
            <td>U-23494</td>
            <td>
              <div className="Actions">
                <a className="Blue" href="">
                  <i className="fa fa-eye" aria-hidden="true" />
                </a>
                <a className="Green" href="">
                  <i className="fa fa-pencil" aria-hidden="true" />
                </a>
                <a className="Red" href="">
                  <span className="Red">Resend </span>
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Archana</td>
            <td>
              <img src="images/car.png" />
              &nbsp;&nbsp; lorem
            </td>
            <td>22-09-23</td>
            <td>All</td>
            <td>All</td>
            <td>
              <div className="Actions">
                <a className="Blue" href="">
                  <i className="fa fa-eye" aria-hidden="true" />
                </a>
                <a className="Green" href="">
                  <i className="fa fa-pencil" aria-hidden="true" />
                </a>
                <a className="Red" href="">
                  <span className="Red">Resend </span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

    </>
  )
}

export default NotificationManagement
