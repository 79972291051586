import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

const PublishArticleByAdminOutlet = () => {
   
  const location = useLocation().pathname?.split('/').splice(1)

  return (
    <>
  {!location?.includes('AllArticleDetails') && !location?.includes('SubCategoryList') && !location?.includes('CategoryList') && !location?.includes('PublishNewsArticle') && 
 <div className="CommonTabs">
        <ul className="nav nav-tabs">
          <li className="nav-item mb-2 ">
            <Link
              className={`nav-link ${location?.includes('AllPublishedArticles') && 'active'}`}
              data-toggle="tab"
              to="AllPublishedArticles/1"
            >
              All Published Articles
            </Link>
          </li>
          <li className="nav-item mb-2 ">
            <Link
              className={`nav-link ${location?.includes('AllPendingArticles') && 'active'}`}
              data-toggle="tab"
              to="AllPendingArticles/1"
            >
              All Pending Article
            </Link>
          </li>
          <li className={`nav-item  mb-2`}>
            <Link className={`nav-link ${location?.includes('HiddenArticles') && 'active'}`} data-toggle="tab" to="HiddenArticles/1">
              Hidden Articles
            </Link>
          </li>
          <li className={`nav-item mb-2`}>
            <Link
              className={`nav-link ${location?.includes('DisapprovedArticles') && 'active'}`}
              data-toggle="tab"
              to="DisapprovedArticles/1"
            >
              Disapproved Articles
            </Link>
          </li>
          <li className={`nav-item mb-2`}>
            <Link className={`nav-link  ${location?.includes('DeleteArticles') && 'active'}`} data-toggle="tab" to="DeleteArticles/1">
              Deleted Articles
            </Link>
          </li>
        </ul>
      </div>}
      {!location?.includes('AllArticleDetails') && !location?.includes('SubCategoryList') && !location?.includes('CategoryList') && !location?.includes('PublishNewsArticle') && 
      <div className="TitleBox">
            <h4 className="Title" />
           {!location.includes('PublishArticleByAPI') &&
            <span>
              {" "}
              <Link
                className="TitleLink"
                to={'/ContentManagement/PublishArticleByAdmin/SubCategoryList'}
              >
                Sub Category
              </Link>
              <Link
                className="TitleLink"
                to={'/ContentManagement/PublishArticleByAdmin/CategoryList/1'}
              >
                Add News Category
              </Link>
              <Link
                className="TitleLink" 
                to={'/ContentManagement/PublishArticleByAdmin/PublishNewsArticle'}
              >
                Publish New Article
              </Link>
            </span>
            }
          </div>
          }
      <div className="tab-content">
      <Outlet/>
      </div>  
     </>
  )
}

export default PublishArticleByAdminOutlet
