import React from 'react'

const UserDashboard = () => {
  return (
    <>
      <div className="AccordionBox mt-4">
  <div id="accordion">
    <div className="card">
      <div
        className="card-header collapsed"
        data-toggle="collapse"
        href="#collapse1"
        aria-expanded="false"
      >
        <h4>User Statistics</h4>
      </div>
      <div id="collapse1" className="collapse" data-parent="#accordion">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-11 ml-auto">
              <div className="CardBox">
                <figure>
                  <img src="images/chart 3.png" />
                </figure>
                <div className="legend">
                  <div className="legend-item">
                    <div className="legend-dot chrome" />
                    Total Registered users
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot firefox" />
                    Total Active user
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot opera" />
                    Blocked user
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot microsoft-edge" />
                    Total Bookmark
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot others" />
                    Total Shares
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card">
      <div
        className="card-header"
        data-toggle="collapse"
        href="#collapse2"
        aria-expanded="false"
      >
        <h4>News Statistics By Admin</h4>
      </div>
      <div id="collapse2" className="collapse" data-parent="#accordion">
        <div className="card-body p-4">
          <div className="row">
            <div className="col-lg-11 ml-auto">
              <div className="CardBox">
                <figure>
                  <img src="images/chart 3.png" />
                </figure>
                <div className="legend">
                  <div className="legend-item">
                    <div className="legend-dot chrome" />
                    Total Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot firefox" />
                    Total Active Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot opera" />
                    Total Disapprove Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot microsoft-edge" />
                    Total Hidden Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot others" />
                    Total Deleted Posted
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card">
      <div
        className="card-header"
        data-toggle="collapse"
        href="#collapse3"
        aria-expanded="false"
      >
        <h4>News Statistics by API</h4>
      </div>
      <div id="collapse3" className="collapse" data-parent="#accordion">
        <div className="card-body p-4">
          <div className="row">
            <div className="col-lg-11 ml-auto">
              <div className="CardBox">
                <figure>
                  <img src="images/chart 4.png" />
                </figure>
                <div className="legend">
                  <div className="legend-item">
                    <div className="legend-dot chrome" />
                    Total Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot firefox" />
                    Total Active Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot opera" />
                    Total Disapprove Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot microsoft-edge" />
                    Total Hidden Post
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot others" />
                    Total Deleted Posted
                  </div>
                  <div className="legend-item">
                    <div className="legend-dot internet-explorer" />
                    New Post Request
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card">
      <div
        className="card-header"
        data-toggle="collapse"
        href="#collapse4"
        aria-expanded="false"
      >
        <h4>Trending topics</h4>
      </div>
      <div id="collapse4" className="collapse" data-parent="#accordion">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-3">
              <div className="Dashboard Active">
                {/* <figure><img src="images/Order.png"></figure> */}
                <figcaption>
                  <h4>Polutics</h4>
                  {/* <h2>25</h2> */}
                </figcaption>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="Dashboard">
                {/* <figure><img src="images/Order.png"></figure> */}
                <figcaption>
                  <h4>Bollywood</h4>
                  {/* <h2>25</h2> */}
                </figcaption>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="Dashboard">
                {/* <figure><img src="images/Order.png"></figure> */}
                <figcaption>
                  <h4>Business</h4>
                  {/* <h2>25</h2> */}
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card">
      <div
        className="card-header"
        data-toggle="collapse"
        href="#collapse5"
        aria-expanded="false"
      >
        <h4>Popular News</h4>
      </div>
      <div id="collapse5" className="collapse" data-parent="#accordion">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6">
              <div className="Dashboard Active">
                {/* <figure><img src="images/Order.png"></figure> */}
                <figcaption>
                  <h4>
                    Government Launches New Electric Vehicle (EV) Scheme to
                    Accelerate Sustainable Mobility
                  </h4>
                </figcaption>
                <figure>
                  <img src="images/Order.png" />
                </figure>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="Dashboard">
                <figcaption>
                  <h4>
                    Government Launches New Electric Vehicle (EV) Scheme to
                    Accelerate Sustainable Mobility
                  </h4>
                </figcaption>
                <figure>
                  <img src="images/Order.png" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default UserDashboard
