import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const KnowledgeInsightsByAdminOutlet = () => {
  const location = useLocation().pathname?.split("/").splice(1);

  return (
    <>
{!location?.includes('ViewKnowledgeInsight') &&!location?.includes('PublishedNewInsigth') &&
      <>
      <div className="CommonTabs">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("PendingArticleKnowledgeInsigth") && "active"
              }`}
              data-toggle="tab"
              to={"PendingArticleKnowledgeInsigth"}
            >
              All Pending Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("AllPublishedArticleKnowledgeInsigth") &&
                "active"
              }`}
              data-toggle="tab"
              to={"AllPublishedArticleKnowledgeInsigth"}
            >
              All Published Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${ location?.includes("HiddenArticleByAdminKnowledgeInsigth") && "active" }`}
              data-toggle="tab"
              to={"HiddenArticleByAdminKnowledgeInsigth"}
            >
              Hidden Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${ location?.includes("DisapprovedArticleByAdminKnowledgeInsigth") && "active" }`}
              data-toggle="tab"
              to={"DisapprovedArticleByAdminKnowledgeInsigth"}
            >
              Disapproved Articles
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${ location?.includes("AllPublishedArticleByAPI") && "active" }`}
              data-toggle="tab"
              to={"DeletedArticleByAdminKnowledgeInsigth"}
            >
              Deleted Articles
            </Link>
          </li>
        </ul>
      </div>

      <div className="TitleBox">
        <h4 className="Title" />
        <span>
          {" "}
          <Link
            to={"/ContentManagement/KnowledgeInsightsByAdmin/PublishedNewInsigth"}
            className="TitleLink"
            >
            Publish New Insight
          </Link>
        </span>
      </div>
 </>
          }
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default KnowledgeInsightsByAdminOutlet;
