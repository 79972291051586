import React from 'react'

const DeletedQuiz = () => {
  return (
    <>
     <div className="Small-Wrapper">
  <div className="FilterArea">
    <div className="FilterLeft">
      <div className="form-group">
        <label>Search</label>
        <input type="text" className="form-control" placeholder="Search here" />
      </div>
      <div className="form-group">
        <label>From Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>To Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>&nbsp;</label>
        <button className="Button">Apply</button>
        <button className="Button Cancel">
          <i className="fa fa-refresh" />
        </button>
      </div>
    </div>
    <div className="FilterRight">
      <div className="form-group">
        <label>Timeframe</label>
        <select className="form-control">
          <option>Select Timeframe</option>
          <option>Today</option>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select>
      </div>
    </div>
  </div>
  <div className="TableList">
    <table>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Quiz ID</th>
          <th>Quiz Category</th>
          <th>Posted On</th>
          <th>Paper Name</th>
          <th>Duration</th>
          <th>Article Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>12345</td>
          <td>Current Affairs</td>
          <td>04-Nov-2024</td>
          <td>Biotecnology</td>
          <td>50 min</td>
          <td>
            <span className="Green">Active</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>12345</td>
          <td>Current Affairs</td>
          <td>04-Nov-2024</td>
          <td>Biotecnology</td>
          <td>50 min</td>
          <td>
            <span className="Red">Disapproved</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>12345</td>
          <td>Current Affairs</td>
          <td>04-Nov-2024</td>
          <td>Biotecnology</td>
          <td>50 min</td>
          <td>
            <span className="Orange">Hidden</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>12345</td>
          <td>Current Affairs</td>
          <td>04-Nov-2024</td>
          <td>Biotecnology</td>
          <td>50 min</td>
          <td>
            <span className="Red">Deleted</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
 
<div className="ModalBox">
        <div id="LogoutModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Logout</h3>
                  <p>Are you sure you want to log out? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="login.html">Yes</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="DeleteModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to Delete? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="ActiveModal"
          className="modal fade ExtraSmallModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Active</h3>
                  <p>Are you sure you want to active?</p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="InactiveModal"
          className="modal fade ExtraSmallModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Inactive</h3>
                  <p>Are you sure you want to inactive ?</p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="AddQuizCategoryModal"
          className="modal fade"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Add QUIZ Category</h3>
                  <div className="form-group">
                    <label>Category Title</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Auto fill "
                    />
                  </div>
                  <div className="form-group">
                    <label>Upload Image </label>
                    <div className="UploadBox">
                      <div className="Upload">
                        <i className="fa fa-upload" /> <span>Upload </span>
                        <input type="file" />
                      </div>
                    </div>
                  </div>
                  <button className="Button">Add Quiz Category</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeletedQuiz
