import React from 'react'

const useRemoveTag = () => {
    const getPlainText = (html) => {
        if (!html) return "";
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
      };
      
  return {getPlainText}
}

export default useRemoveTag
