import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';


export const ArticleGetByAPISlice = createAsyncThunk("Article-listingapiarticles",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingapiarticles/published?${param}`);
        return response.data;

    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error?.response?.data || { message: "An error occurred" });
    }
  }
);


export const StatusUpdatePublishArticleByAPISlice = createAsyncThunk("Published-Status-Update",async (param, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`updateapiarticle/?${param}`);
      return response.data;
  } catch (error) {
    console.error("API error:", error);
    return rejectWithValue(error.response?.data || { message: "An error occurred" });
  }
}
);

export const InsertArticleByAPISlice = createAsyncThunk("insertdataviaApi-Status-Update",async (param, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`insertdataviaApi/?${param}`);
      return response.data;
  } catch (error) {
    console.error("API error:", error);
    return rejectWithValue(error.response?.data || { message: "An error occurred" });
  }
}
);



const ArticleByAPISlice = createSlice({
  name: 'Article-API',
  initialState: {
    AllArticleByAPI: [],
    StatusUpdateArticle: [],
    InsertArticle:[],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      console.log(state.error);
      // toast.error(state.error);
    }

    builder
      .addCase(ArticleGetByAPISlice.pending,handlePendingState)
      .addCase(ArticleGetByAPISlice.fulfilled, (state, action) => { 
        state.AllArticleByAPI = action.payload.data.articles; 
        state.loading = false;
        // toast.success("Article Created Successfully!");
      })
      .addCase(ArticleGetByAPISlice.rejected,handleRejectState)

      .addCase(StatusUpdatePublishArticleByAPISlice.pending,handlePendingState)
      .addCase(StatusUpdatePublishArticleByAPISlice.fulfilled, (state, action) => { 
        state.StatusUpdateArticle = action.payload?.data; 
        state.loading = false;
        // toast.success("Article Created Successfully!");
      })
      .addCase(StatusUpdatePublishArticleByAPISlice.rejected,handleRejectState)

      .addCase(InsertArticleByAPISlice.pending,handlePendingState)
      .addCase(InsertArticleByAPISlice.fulfilled, (state, action) => { 
        state.InsertArticle = action.payload?.data; 
        state.loading = false;
        toast.success("Article Inserted Successfully!");
      })
      .addCase(InsertArticleByAPISlice.rejected,handleRejectState)

      },
});


export default ArticleByAPISlice.reducer;
