import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

const ContentManagement = () => {
  const location = useLocation().pathname.split('/').splice(1)
  // console.log("location:",location);
  const AllowedRouteToHeading = [!location?.includes('CategoryList') && !location?.includes('PublishNewsArticle') && !location?.includes('PublishedNewInsigth')&&!location?.includes('EditPublishNewsArticle')&&!location?.includes('ViewKnowledgeInsight')&&!location?.includes('SubCategoryList')]
  return (
    <>
      <>
  <div>
    <div>
      {AllowedRouteToHeading.includes(true)  &&
     <div className="TitleBox">
     <h4 className="Title">Content Management</h4>
   </div> 
      }
      <div>
      <Outlet/>
      </div>
    </div>
  </div>
  {/* <div className="ModalBox">
    <div id="LogoutModal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="Decline">
              <Link
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </Link>
              <h3>Logout</h3>
              <p>Are you sure you want to log out? </p>
              <h4>
                <Link href="javascript:void(0);" data-dismiss="modal">
                  no
                </Link>
                <Link href="login.html">Yes</Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="DeleteModal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="Decline">
              <Link
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </Link>
              <h3>Delete</h3>
              <p>Are you sure you want to Delete? </p>
              <h4>
                <Link href="javascript:void(0);" data-dismiss="modal">
                  no
                </Link>
                <Link href="javascript:void(0);" data-dismiss="modal">
                  Yes
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="ActiveModal" className="modal fade ExtraSmallModal" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="Decline">
              <Link
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </Link>
              <h3>Active</h3>
              <p>Are you sure you want to active?</p>
              <h4>
                <Link href="javascript:void(0);" data-dismiss="modal">
                  no
                </Link>
                <Link href="javascript:void(0);" data-dismiss="modal">
                  Yes
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="InactiveModal"
      className="modal fade ExtraSmallModal"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="Decline">
              <Link
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </Link>
              <h3>Inactive</h3>
              <p>Are you sure you want to inactive ?</p>
              <h4>
                <Link href="javascript:void(0);" data-dismiss="modal">
                  no
                </Link>
                <Link href="javascript:void(0);" data-dismiss="modal">
                  Yes
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="AddQuizCategoryModal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="Category">
              <Link
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </Link>
              <h3>Add QUIZ Category</h3>
              <div className="form-group">
                <label>Category Title</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Auto fill "
                />
              </div>
              <div className="form-group">
                <label>Upload Image </label>
                <div className="UploadBox">
                  <div className="Upload">
                    <i className="fa fa-upload" /> <span>Upload </span>
                    <input type="file" />
                  </div>
                </div>
              </div>
              <button className="Button">Add Quiz Category</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</>

    </>
  )
}

export default ContentManagement
