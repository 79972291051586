import React from 'react'
import { useNavigate } from 'react-router-dom'

const AddQUIZ = () => {
    const navigate = useNavigate();

  return (
    <>
     <>
  <div className="TitleBox">
    <h4 className="Title">Quiz Management</h4>
    <span>
      <a
        className="TitleLink"
        onClick={()=>navigate(-1)}
      >
        <i className="fa fa-arrow-left" />
        Back
      </a>
    </span>
  </div>
  <div className="Small-Wrapper">
    <div className="CommonForm">
      <h4>Add Quiz</h4>
      <div className="row">
        <div className="col-4">
          {" "}
          <div className="form-group">
            <label>Select Quiz Category</label>
            <select className="form-control">
              <option>Current Affairs</option>
              <option>Current Affairs</option>
              <option>Current Affairs</option>
            </select>
          </div>
        </div>
        <div className="col-4">
          {" "}
          <div className="form-group">
            <label>Level</label>
            <select className="form-control">
              <option>Medium</option>
              <option>Highest</option>
              <option>Low</option>
            </select>
          </div>
        </div>
        <div className="col-4">
          {" "}
          <div className="form-group">
            <label>Choose Number of Questions</label>
            <select className="form-control">
              <option>10</option>
              <option>20</option>
              <option>30</option>
            </select>
          </div>
        </div>
        <div className="col-4">
          {" "}
          <div className="form-group">
            <label>Choose options for Answers</label>
            <select className="form-control">
              <option>4</option>
              <option>3</option>
              <option>2</option>
              <option>1</option>
            </select>
          </div>
        </div>
        <div className="col-4">
          {" "}
          <div className="form-group">
            <label>Duration</label>
            <select className="form-control">
              <option>50 min</option>
              <option>30 min</option>
              <option>20 min</option>
              <option>10 min</option>
            </select>
          </div>
        </div>
        <div className="col-4">
          {" "}
          <div className="form-group">
            <label>Question Paper Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Biotechnology"
            />
          </div>
        </div>
      </div>
      <div className="Small-Wrapper">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>Question 1</label>
              <input
                type="text"
                className="form-control"
                placeholder="The bacterium Arobacterium tumeficians is used to obtain which of the following ?"
              />
            </div>
          </div>
          <div className="col-4">
            {" "}
            <div className="form-group">
              <label>Option 1</label>
              <input
                type="text"
                className="form-control"
                placeholder="Bt-toxin"
              />
            </div>
          </div>
          <div className="col-4">
            {" "}
            <div className="form-group">
              <label>Option 2</label>
              <input
                type="text"
                className="form-control"
                placeholder="Ti-plasmid"
              />
            </div>
          </div>
          <div className="col-4">
            {" "}
            <div className="form-group">
              <label>Option 3</label>
              <input
                type="text"
                className="form-control"
                placeholder="Both of the Above"
              />
            </div>
          </div>
          <div className="col-4">
            {" "}
            <div className="form-group">
              <label>Option 4</label>
              <input
                type="text"
                className="form-control"
                placeholder="None of the Above"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>Choose options for Answers</label>
              <select className="form-control">
                <option>Option 1</option>
                <option>Option 2</option>
                <option>Option 3</option>
                <option>Option 4</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>Hint</label>
              <input
                type="text"
                className="form-control"
                placeholder="The bacterium Arobacterium tumeficians is used to obtain which of the following ?"
              />
            </div>
          </div>
        </div>
        <button className="Button">Add More +</button>
      </div>
      <button className="Button mt-4">PUBLISH</button>
    </div>
  </div>
</>
 
    </>
  )
}

export default AddQUIZ
