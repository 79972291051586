import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GetMyProfileSlice } from '../../redux/features/AuthSlice';

const SideNavbar = () => {
  const location = useLocation()?.pathname?.split('/').splice(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [iState,setUpdate] = useState({})

  const {MyProfile} = useSelector((state)=>state.Auth);
  const {AdminDetails} = useSelector((state)=>state.MyAccountAdmin);


  const handleLogout = ()=>{
    localStorage.removeItem('Quixtoken');
    navigate('/login');
  }


  useEffect(()=>{
    setUpdate(MyProfile);
  },[MyProfile])

  
  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('Quixtoken'))){
      const decodedToken = jwtDecode(JSON.parse(localStorage.getItem('Quixtoken'))) ||false;
      dispatch(GetMyProfileSlice(decodedToken?.id));
    }
  },[AdminDetails])

  console.log(location);

  return (
    <>
      <div className="SidenavArea">
  <div className="SidenavHead">
    <figure>
      <img src={iState?.profileImage} />
    </figure>
    <h4>
      {iState?.name} <span>Administrator</span> 
    </h4>
  </div>
  <div className="SidenavBody">
    <ul>
      <li className={['/'].includes(location) && 'active'}>
        <Link to={'/'}>
          <span className="Icon">
            <i className="fa fa-tachometer" />
          </span>
          <span className="Text">Dashboard</span>
        </Link>
      </li>
      <li className={`${location.includes('UserManagement') && 'active'}`}>
        <Link to={'UserManagement'}>
          <span className="Icon">
            <i className="fa fa-user" />
          </span>
          <span className="Text">User Management</span>
        </Link>
      </li>
      <li className={`dropdown ${location.includes('ContentManagement') && 'active'}`}>
        <a
          href="javascript:void(0)"
          id="navbardrop"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="Icon">
            <i className="fa fa-font" />
          </span>
          <span className="Text">Content Management</span>
        </a>
        <ol className="dropdown-menu">
          <li >
            <Link to={'/ContentManagement/PublishArticleByAdmin/AllPublishedArticles/1'} className={`${location.includes('PublishArticleByAdmin')&&'text-dark'}`}>
              Published Article By Admin
            </Link>
          </li>
          <li>
            <Link to={'/ContentManagement/PublishArticleByAPI/AllPendingArticlesByAPI'} className={`${location.includes('PublishArticleByAPI')&&'text-dark'}`}>
              Published Article By API
            </Link>
          </li>
          <li>
            <Link to={'/ContentManagement/KnowledgeInsightsByAdmin/PendingArticleKnowledgeInsigth'} className={`${location.includes('KnowledgeInsightsByAdmin')&&'text-dark'}`}>
              QuixSense
            </Link> 
          </li>
          {/* <li>
            <Link to={'/ContentManagement/KnowledgeInsightsByAPI'}>
              Knowledge Insights By API
            </Link>
          </li> */}
        </ol>
      </li>
      <li className="dropdown">
        <a
          href="javascript:void(0)"
          id="navbardrop"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="Icon">
            <i className="fa fa-puzzle-piece" />
          </span>
          <span className="Text">Quiz Management</span> 
        </a>
        <ol className="dropdown-menu">
          <li>
            <Link to={'/QuizManagement/QuizUploadByAdmin/AllPublishedQuiz'}>
              QUIZ Uploaded By Admin
            </Link>
          </li>
          <li>
            <a href="quiz-management-quiz-uploaded-by-api.html">
              QUIZ Uploaded By API
            </a>
          </li>
        </ol>
      </li>
      <li>
        <Link to={'ContentAnalystics'}>
          <span className="Icon">
            <i className="fa fa-file-text" />
          </span>
          <span className="Text">Content Analytics</span>
        </Link>
      </li>
      <li>
        <Link to={'BannerManagement'}>
          <span className="Icon">
            <i className="fa fa-file-image-o" />
          </span>
          <span className="Text">Banner Management</span>
        </Link>
      </li>
      <li>
        <Link to={'NotificationManagementNode/NotificationManagement'}>
          <span className="Icon">
            <i className="fa fa-bell-o" />
          </span>
          <span className="Text">Notification Management</span>
        </Link>
      </li>
      <li className={`dropdown ${location.includes('StaticContent') && 'active'}`}>
        <a
          href="javascript:void(0)"
          id="navbardrop"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="Icon">
            <i className="fa fa-file" />
          </span>
          <span className="Text">Static Content</span>
        </a>
        <ol className="dropdown-menu">
          <li>
            <Link to={'StaticContent/AboutUS'}  className={`${location.includes('AboutUS')&&'text-dark'}`}>About Us </Link>
          </li>
          <li>
            <Link to={'StaticContent/PrivacyPolicy'} className={`${location.includes('PrivacyPolicy')&&'text-dark'}`}>Privacy Policy</Link>
          </li>
          <li>
            <Link to={'StaticContent/TermAndCondition'} className={`${location.includes('TermAndCondition')&&'text-dark'}`}>
              Terms &amp; Conditions
            </Link>
          </li>
          <li>
            <Link to={'StaticContent/ContactUs'} className={`${location.includes('ContactUs')&&'text-dark'}`}>Contact Us</Link>
          </li>
        </ol>
      </li>
      <li>
        <Link to={'MyAccountNode'}>
          <span className="Icon">
            <i className="fa fa-user" aria-hidden="true" />
          </span>
          <span className="Text">My Account</span>
        </Link>
      </li>
      <li>
        <Link data-toggle="modal" data-target="#LogoutModal" onClick={handleLogout}>
          <span className="Icon">
            <i className="fa fa-sign-out" />{" "}
          </span>
          <span className="Text">Logout</span>
        </Link>
      </li>
    </ul>
  </div>
</div>

    </>
  )
}

export default SideNavbar
