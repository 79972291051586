import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AboutusGetSlice, AboutusUpdateSlice } from "../../../redux/features/Static Content Management slice/StaticContentManagement";

const AboutUS = () => {
    const [iState,setUpdate] = useState({id:'',data:''});
    const dispatch = useDispatch();
  
    useEffect(()=>{
     dispatch(AboutusGetSlice()).then((res)=>{
      setUpdate((prev)=>({...prev,id:res?.payload?.data?.[0]?._id,data:res?.payload?.data?.[0]?.details}));
     })
    },[])

  return (
    <>
      <>
        <div className="TitleBox">
          <h4 className="Title">About Us</h4>
        <Link className="TitleLink" to={"/StaticContent/EditStaticContent"} state={{id:iState?.id,type:'staticContent',heading:'About Us',content:iState?.data}}>
            Edit About us
          </Link> 
        </div>

        <div className="StaticContentArea">
        <div dangerouslySetInnerHTML={{ __html: iState?.data }}/>
        </div>
      </>
    </>
  );
};

export default AboutUS;
