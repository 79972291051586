import React, { useRef, useState } from "react";
import { TbEditCircle } from "react-icons/tb";
import { onImageHandler } from "../../../common/Image upload on bucket/UploadImageOnBucket";
import Skeleton from "react-loading-skeleton";
import { CreateBannerManagementSlice } from "../../../redux/features/Banner Management/BannerManagement";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const AddBannerModal = ({ modalState, setModalState }) => {
  const BannerClose = useRef();
  const dispatch = useDispatch();
  const [imageLoading, setImageLoading] = useState(false);
    const [iState, setUpdate] = useState({
      bannerName: "",
      bannerImage: "",
      isActive:''
    })

    const handleChange=(e)=>{
     const {name,value} = e.target;
    setUpdate((prev)=>({...prev,bannerName:value}))  
    }

  const handleUpload = async (e) => {
    setImageLoading(true);
    const data = await onImageHandler(e);
    setUpdate((prev) => ({ ...prev, bannerImage: data[1].location }));
    setImageLoading(false);
  };

  const handleSubmit=()=>{
    try {
        dispatch(CreateBannerManagementSlice(iState))?.then((res)=>{
         BannerClose?.current?.click();
        }) 
    } catch (error) {
        toast.error('something went worng while uploading banner!');
    }
  }


  return (
    <div className="ModalBox">
      <div
        id="UploadModal"
        className="modal fade show"
        role="dialog"
        aria-modal="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="Category">
                <a
                  ref={BannerClose}
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                  onClick={() =>
                    setModalState((prev) => ({
                      ...prev,
                      AddBanner: !prev["AddBanner"],
                    }))
                  }
                >
                  ×
                </a>
                <h3>Upload Banner</h3>
                <div className="form-group">
                  <label>Banner Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Banner Name"
                    name="bannerName"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <div className="UploadBox">
                    <div className="Upload" style={{ padding: "0" }}>
                      {imageLoading ? (
                        <Skeleton width={"100%"} height={"130px"} />
                      ) : iState?.bannerImage?.length > 0 ? (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={iState?.bannerImage}
                            style={{ height: "100%", width: "100%" }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="file"
                              onChange={handleUpload}
                              style={{
                                height: "100%",
                                width: "100%",
                                opacity: "0",
                              }}
                            />
                            <TbEditCircle
                              style={{
                                fontSize: "20px",
                                backgroundColor: "gray",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <i className="fa fa-upload" style={{marginTop:'30px'}}/> <span>Upload </span>
                          <input type="file" onChange={handleUpload} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <button className="Button" onClick={handleSubmit}>Upload News banner</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBannerModal;
