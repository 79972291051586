import React from "react";
import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/Login/Forgot password/ForgotPassword";
import LoginCommonOutlet from "../pages/Login/Login Common Outlet/LoginCommonOutlet";
import OTPVerify from "../pages/Login/OTP Verify/OTPVerify";
import ResetPassword from "../pages/Login/Reset Password/ResetPassword";
import Congras from "../pages/Login/Successfull reset Password/Congras";
import Dashboard from "../pages/Dashboard/Dashboard";
import UserDashboard from "../pages/Dashboard/UserDashboard/UserDashboard";
import QuizDashboard from "../pages/Dashboard/QuizDashboard/QuizDashboard";
import UserManagement from "../pages/UserManagement/UserManagement";
import ContentManagement from "../pages/Content Management/ContentManagement";
import AllPublishedArticles from "../pages/Content Management/Publish Article By Admin/All Published Articles/AllPublishedArticles";
import HiddenArticles from "../pages/Content Management/Publish Article By Admin/Hidden Articles/HiddenArticles";
import DisapprovedArticles from "../pages/Content Management/Publish Article By Admin/Disapproved Articles/DisapprovedArticles";
import DeleteArticles from "../pages/Content Management/Publish Article By Admin/Delete Articles/DeleteArticles";
import { Route, Routes } from "react-router-dom";
import PublishArticleByAdminOutlet from "../pages/Content Management/Publish Article By Admin/PublishArticleByAdminOutlet";
import CategoryList from "../pages/Content Management/Add News Category/CategoryList";
import PublishNewsArticle from "../pages/Content Management/Publish News Article/PublishNewsArticle";
import PublishArticleByAPIOutlet from "../pages/Content Management/Publish Article By API/PublishArticleByAPIOutlet";
import NewRequest from "../pages/Content Management/Publish Article By API/New Request/NewRequest";
import DisapprovedArticle from "../pages/Content Management/Publish Article By API/Disapproved Article/DisapprovedArticle";
import DeletedArticle from "../pages/Content Management/Publish Article By API/Deleted Article/DeletedArticle";
import KnowledgeInsightsByAdminOutlet from "../pages/Content Management/Knowlwdge insights by Admin/KnowledgeInsightsByAdminOutlet";
import ContentAnalysticsOutlet from "../pages/Content Analystics/ContentAnalysticsOutlet";
import QuizAnalystics from "../pages/Content Analystics/Quiz Analystics/QuizAnalystics";
import ContactAnalystics from "../pages/Content Analystics/Contact Analystics/ContactAnalystics";
import BannerManagementOutlet from "../pages/Banner Management/BannerManagementOutlet";
import BannerUpload from "../pages/Banner Management/Banner Uploaded by Admin/BannerUpload";
import GoogleAdsBanner from "../pages/Banner Management/Google Ads Banner/GoogleAdsBanner";
import NotificationManagementOutlet from "../pages/Notification Management/NotificationManagementOutlet";
import NotificationManagement from "../pages/Notification Management/Notification Management Module/NotificationManagement";
import StaticContentOutlet from "../pages/Static Content/StaticContentOutlet";
import AboutUS from "../pages/Static Content/About us/AboutUS";
import ContactUs from "../pages/Static Content/Contact us/ContactUs";
import TermAndCondition from "../pages/Static Content/Term And Condition/TermAndCondition";
import PrivacyPolicy from "../pages/Static Content/Privacy Policy/PrivacyPolicy";
import MyAccountOutlet from "../pages/My Account/MyAccountOutlet";
import MyAccount from "../pages/My Account/My Account Module/MyAccount";
import ChangePassword from "../pages/My Account/Password setting/ChangePassword";
import PersonalDetails from "../pages/UserManagement/User Management details page/Personal details/PersonalDetails";
import NewsPreferences from "../pages/UserManagement/User Management details page/News Preferences/NewsPreferences";
import UserEnagement from "../pages/UserManagement/User Management details page/User Engagement/UserEnagement";
import Bookmarks from "../pages/UserManagement/User Management details page/Bookmarks/Bookmarks";
import UserManagementDetailsOutlet from "../pages/UserManagement/User Management details page/UserManagementDetailsOutlet";
import OfflineSave from "../pages/UserManagement/User Management details page/offline save/OfflineSave";
import UserQuizLeaderBoad from "../pages/UserManagement/User Management details page/User Quiz LeaderBoard/UserQuizLeaderBoad";
import UserManagementOutlet from "../pages/UserManagement/UserManagementOutlet";
import AllArticleDetails from "../pages/Content Management/Publish Article By Admin/All Published Articles/All Article Details Page/AllArticleDetails";
import AllPublishedArticleKnowledgeInsigth from "../pages/Content Management/Knowlwdge insights by Admin/All Published Article by admin Knowledge insight/AllPublishedArticleKnowledgeInsigth";
import HiddenArticleByAdminKnowledgeInsigth from "../pages/Content Management/Knowlwdge insights by Admin/Hidden Article by Admin Knowledge insigth/HiddenArticleByAdminKnowledgeInsigth";
import DisapprovedArticleByAdminKnowledgeInsigth from "../pages/Content Management/Knowlwdge insights by Admin/DisApproved Article By Admin Knowledge Insigth/DisapprovedArticleByAdminKnowledgeInsigth";
import DeletedArticleByAdminKnowledgeInsigth from "../pages/Content Management/Knowlwdge insights by Admin/Deleted Article by Admin Knowledge Insight/DeletedArticleByAdminKnowledgeInsigth";
import PublishedNewInsigth from "../pages/Content Management/Knowlwdge insights by Admin/Publish New Insight/PublishedNewInsigth";
import KnowledgeInsightByAPIOutlet from "../pages/Content Management/Knowledge insight by API/KnowledgeInsightByAPIOutlet";
import AllPublishedArticleKnowledgeInsigthByAPI from "../pages/Content Management/Knowledge insight by API/All Published Article By API/AllPublishedArticleKnowledgeInsigthByAPI";
import HiddenArticleByKnowledgeInsigthByAPI from "../pages/Content Management/Knowledge insight by API/Hidden Article/HiddenArticleByKnowledgeInsigthByAPI";
import DisApprovedArticleByKnowledgeInsightByAPI from "../pages/Content Management/Knowledge insight by API/Disapproved Insigth Article/DisApprovedArticleByKnowledgeInsightByAPI";
import DeletedArticleKnowledgeInsigthByAPI from "../pages/Content Management/Knowledge insight by API/Deleted Article/DeletedArticleKnowledgeInsigthByAPI";
import EditPublishNewsArticle from "../pages/Content Management/Publish News Article/EditPublishNewsArticle";
import AllPublishedArticleByAPI from "../pages/Content Management/Publish Article By API/All Published Article By API/AllPublishedArticleByAPI";
import EditStaticContent from "../pages/Static Content/Edit Static Content/EditStaticContent";
import AllPendingArticles from "../pages/Content Management/Publish Article By Admin/All Pending Article/AllPendingArticle";
import AllPendingArticlesByAPI from "../pages/Content Management/Publish Article By API/All Pending Article/AllPendingArticleByAPI";
import PendingArticleKnowledgeInsigth from "../pages/Content Management/Knowlwdge insights by Admin/Pending Article by admin Knowledge insight/PendingArticleKnowledgeInsigth";
import ViewKnowledgeInsight from "../pages/Content Management/Knowlwdge insights by Admin/Knowledge Insight Edit/ViewKnowledgeInsight";
import AllHiddenArticlesByAPI from "../pages/Content Management/Publish Article By API/Hidden Article/HiddenArticleByAPI";
import QuizUploadByAdminOutlet from "../pages/Quiz Management/Quiz Upload by admin/QuizUploadByAdminOutlet";
import AllPublishedQuiz from "../pages/Quiz Management/Quiz Upload by admin/AllPublishedQuiz";
import HiddenQuiz from "../pages/Quiz Management/Quiz Upload by admin/HiddenQuiz";
import DisapprovedQuiz from "../pages/Quiz Management/Quiz Upload by admin/DisapprovedQuiz";
import DeletedQuiz from "../pages/Quiz Management/Quiz Upload by admin/DeletedQuiz";
import QuizManagement from "../pages/Quiz Management/QuizManagement";
import AddQUIZ from "../pages/Quiz Management/Quiz Upload by admin/AddQUIZ";
import SubCategoryList from "../pages/Content Management/Sub Category/SubCategoryList";

const RouterComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<UserDashboard />} />
          <Route path="UserDashboard" element={<UserDashboard />} />
          <Route path="QuizDashboard" element={<QuizDashboard />} />
        </Route>

        <Route path={"/login"} element={<LoginCommonOutlet />}>
          <Route index element={<Login />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="OTPVerify" element={<OTPVerify />} />
          <Route path="OTPVerify" element={<OTPVerify />} />
          <Route path="ResetPassword" element={<ResetPassword />} />
          <Route path="Congras" element={<Congras />} />
        </Route>

        <Route path="/UserManagement" element={<UserManagementOutlet />}>
          <Route index element={<UserManagement />} />
          <Route
            path="UserManagementDetails"
            element={<UserManagementDetailsOutlet />}
          >
            <Route index element={<PersonalDetails />} />
            <Route path="PersonalDetails/:id" element={<PersonalDetails />} />
            <Route path="NewsPreferences/:id" element={<NewsPreferences />} />
            <Route path="UserEnagement/:id" element={<UserEnagement />} />
            <Route path="Bookmarks/:id" element={<Bookmarks />} />
            <Route path="OfflineSave/:id" element={<OfflineSave />} />
            <Route path="UserQuizLeaderBoad/:id" element={<UserQuizLeaderBoad />} />
          </Route>
        </Route>

        <Route path="/ContentManagement" element={<ContentManagement />}>
          {/*Publish Article By Admin */}
          <Route
            path="PublishArticleByAdmin"
            element={<PublishArticleByAdminOutlet />}
          >
            <Route index element={<AllPublishedArticles />} />
            <Route
              path="AllPublishedArticles/:pageNo"
              element={<AllPublishedArticles />}
            />
            <Route
              path="AllPendingArticles/:pageNo"
              element={<AllPendingArticles />}
            />
            <Route path="HiddenArticles/:pageNo" element={<HiddenArticles />} />
            <Route
              path="DisapprovedArticles/:pageNo"
              element={<DisapprovedArticles />}
            />
            <Route path="DeleteArticles/:pageNo" element={<DeleteArticles />} />

            <Route
              path="AllArticleDetails/:id"
              element={<AllArticleDetails />}
            />

             {/* Publish Article By Admin */}
          <Route path={`CategoryList/:pageNo`} element={<CategoryList />} />
          <Route path="PublishNewsArticle" element={<PublishNewsArticle />} />
          <Route
            path="EditPublishNewsArticle"
            element={<EditPublishNewsArticle />}
          />
          {/* Sub Category Module */}
          <Route path="SubCategoryList" element={<SubCategoryList />} />
          </Route>

          {/*Publish Article by API */}
          <Route
            path="PublishArticleByAPI"
            element={<PublishArticleByAPIOutlet />}
          >
            <Route index element={<AllPublishedArticleByAPI />} />
            <Route
              path="AllPublishedArticleByAPI"
              element={<AllPublishedArticleByAPI />}
            />
            <Route
              path="AllPendingArticlesByAPI"
              element={<AllPendingArticlesByAPI />}
            />
            <Route
              path="AllHiddenArticle"
              element={<AllHiddenArticlesByAPI />}
            />
            <Route path="NewRequest" element={<NewRequest />} />
            <Route path="DisapprovedArticle" element={<DisapprovedArticle />} />
            <Route path="DeletedArticle" element={<DeletedArticle />} />

            <Route
              path="AllArticleDetails/:id"
              element={<AllArticleDetails />}
            />
          </Route>

          <Route
            path="KnowledgeInsightsByAdmin"
            element={<KnowledgeInsightsByAdminOutlet />}
          >
            <Route index element={<AllPublishedArticleKnowledgeInsigth />} />
            <Route
              path="PendingArticleKnowledgeInsigth"
              element={<PendingArticleKnowledgeInsigth />}
            />
            <Route
              path="AllPublishedArticleKnowledgeInsigth"
              element={<AllPublishedArticleKnowledgeInsigth />}
            />
            <Route
              path="HiddenArticleByAdminKnowledgeInsigth"
              element={<HiddenArticleByAdminKnowledgeInsigth />}
            />
            <Route
              path="DisapprovedArticleByAdminKnowledgeInsigth"
              element={<DisapprovedArticleByAdminKnowledgeInsigth />}
            />
            <Route
              path="DeletedArticleByAdminKnowledgeInsigth"
              element={<DeletedArticleByAdminKnowledgeInsigth />}
            />

            <Route
              path="ViewKnowledgeInsight"
              element={<ViewKnowledgeInsight />}
            />

                      {/*Knowledge insights by Admin  */}
          <Route path="PublishedNewInsigth" element={<PublishedNewInsigth />} />

          </Route>

          <Route
            path="KnowledgeInsightsByAPI"
            element={<KnowledgeInsightByAPIOutlet />}
          >
            <Route
              index
              element={<AllPublishedArticleKnowledgeInsigthByAPI />}
            />
            <Route
              path="AllPublishedArticleKnowledgeInsigthByApi"
              element={<AllPublishedArticleKnowledgeInsigthByAPI />}
            />
            <Route path="NewRequest" element={<NewRequest />} />
            <Route
              path="HiddenArticleByKnowledgeInsigthByAPI"
              element={<HiddenArticleByKnowledgeInsigthByAPI />}
            />
            <Route
              path="DisApprovedArticleByKnowledgeInsightByAPI"
              element={<DisApprovedArticleByKnowledgeInsightByAPI />}
            />
            <Route
              path="DeletedArticleKnowledgeInsigthByAPI"
              element={<DeletedArticleKnowledgeInsigthByAPI />}
            />
          </Route>

          {/* Knowledge insights of Admin
     <Route path='KnowledgeInsightsByAdmin' element={<KnowledgeInsightsByAdminOutlet/>}>
        <Route />
     </Route> */}
        </Route>

        <Route path="/QuizManagement" element={<QuizManagement />}>
          <Route path="QuizUploadByAdmin" element={<QuizUploadByAdminOutlet />}>
            <Route index element={<AllPublishedQuiz />} />
            <Route path="AllPublishedQuiz" element={<AllPublishedQuiz />} />
            <Route path="HiddenQuiz" element={<HiddenQuiz />} />
            <Route path="DisapprovedQuiz" element={<DisapprovedQuiz />} />
            <Route path="DeletedQuiz" element={<DeletedQuiz />} />
          </Route>

          <Route path="AddQUIZ" element={<AddQUIZ />} />
        </Route>

        <Route path="ContentAnalystics" element={<ContentAnalysticsOutlet />}>
          <Route index element={<QuizAnalystics />} />
          <Route path="QuizAnalystics" element={<QuizAnalystics />} />
          <Route path="ContactAnalystics" element={<ContactAnalystics />} />
        </Route>

        <Route path="BannerManagement" element={<BannerManagementOutlet />}>
          <Route index element={<BannerUpload />} />
          <Route path="BannerUpload" element={<BannerUpload />} />
          <Route path="GoogleAdsBanner" element={<GoogleAdsBanner />} />
        </Route>

        <Route
          path="NotificationManagementNode"
          element={<NotificationManagementOutlet />}
        >
          <Route
            path="NotificationManagement"
            element={<NotificationManagement />}
          />
        </Route>

        <Route path="StaticContent" element={<StaticContentOutlet />}>
          <Route path="AboutUS" element={<AboutUS />} />
          <Route path="TermAndCondition" element={<TermAndCondition />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="EditStaticContent" element={<EditStaticContent />} />
        </Route>

        <Route path="MyAccountNode" element={<MyAccountOutlet />}>
          <Route index element={<MyAccount />} />
          <Route path="MyAccount" element={<MyAccount />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
        </Route>
      </Routes>
    </>
  );
};

export default RouterComponent;
