import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const ArticleCreateSlice = createAsyncThunk("Article-Create-api",async (data, { rejectWithValue }) => {
    // try {
    //   const response = await axiosInstance.post('newArticle',data);
    //   // if (response.status === 200) {
    //     return response.data;
    //   // } else {
    //   //   return rejectWithValue(response.data);a
    //   // }
    // } catch (error) {
    //   console.error("API error:", error);
    //   return rejectWithValue(error.response?.data || { message: "An error occurred" });
    // }
  }
);

export const PendingArticleGetByAPISlice = createAsyncThunk("Article-listingapiarticles",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingapiarticles/pending/?${param}`);
        return response.data;

    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error?.response?.data || { message: "An error occurred" });
    }
  }
);

export const ArticleDeleteSlice = createAsyncThunk("Article-softdeleteArticle",async (param, { rejectWithValue }) => {
    // try {
    //   const response = await axiosInstance.put(`hidearticle?${param}`);
    //     return response.data;
    // } catch (error) {
    //   console.error("API error:", error); 
    //   return rejectWithValue(error.response?.data || { message: "An error occurred" });
    // }
  }
);

export const ArticleHideSlice = createAsyncThunk("Penidng Article-Hide-API",async (param, { rejectWithValue }) => {
    // try {
    //   const response = await axiosInstance.put(`hidearticle?${param}`);
    //     return response.data;
    // } catch (error) {
    //   console.error("API error:", error); 
    //   return rejectWithValue(error.response?.data || { message: "An error occurred" });
    // }
  }
);

export const ArticleDisapproveSlice = createAsyncThunk("Article-DisApproved",async (param, { rejectWithValue }) => {
    // try {
    //   const response = await axiosInstance.put(`disapprovearticle/${param}`);
    //     return response.data;
    // } catch (error) {
    //   console.error("API error:", error); 
    //   return rejectWithValue(error.response?.data || { message: "An error occurred" });
    // }
  }
);

export const StatusChangeApiSlice = createAsyncThunk("Article-StatusChange",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`updateapiarticle?${param}`);
        return response.data;
    } catch (error) {
      console.error("API error:", error); 
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);


const PendingArticleByAPISlice = createSlice({
  name: 'Pending-Article-Api',
  initialState: {
    ArticleCreated: [],
    AllPendingArticleByAPI: [],
    DeletedArticle:[],
    HideArticle:[],
    DisApprovedArticle:[],
    ArticleStatusChange:[],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      console.log(state.error);
      // toast.error(state.error);
    }

    builder
      .addCase(ArticleCreateSlice.pending,handlePendingState)
      .addCase(ArticleCreateSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.ArticleCreated = action.payload.data; 
        state.loading = false;
        toast.success("Article Created Successfully!");
      })
      .addCase(ArticleCreateSlice.rejected,handleRejectState)

      .addCase(PendingArticleGetByAPISlice.pending,handlePendingState)
      .addCase(PendingArticleGetByAPISlice.fulfilled, (state, action) => {
        state.AllPendingArticleByAPI = action.payload.data.articles; 
        state.loading = false;
        // toast.success("Article Created Successfully!");
      })
      .addCase(PendingArticleGetByAPISlice.rejected,handleRejectState)

      .addCase(ArticleDeleteSlice.pending,handlePendingState)
      .addCase(ArticleDeleteSlice.fulfilled, (state, action) => {
        state.DeletedArticle = action.payload.data; 
        state.loading = false;
        toast.success("Article Deleted Successfully!");
      })
      .addCase(ArticleDeleteSlice.rejected,handleRejectState)

      .addCase(ArticleHideSlice.pending,handlePendingState)
      .addCase(ArticleHideSlice.fulfilled, (state, action) => {
        state.HideArticle = action.payload.data; 
        state.loading = false;
        toast.success("Article Status change Successfully!");
      })
      .addCase(ArticleHideSlice.rejected,handleRejectState)

      .addCase(ArticleDisapproveSlice.pending,handlePendingState)
      .addCase(ArticleDisapproveSlice.fulfilled, (state, action) => {
        state.DisApprovedArticle = action.payload.data; 
        state.loading = false;
        toast.success("Article Disapproved Successfully!");
      })
      .addCase(ArticleDisapproveSlice.rejected,handleRejectState)

      .addCase(StatusChangeApiSlice.pending,handlePendingState)
      .addCase(StatusChangeApiSlice.fulfilled, (state, action) => {
        state.ArticleStatusChange = action.payload.data; 
        state.loading = false;
        toast.success("Article Published Successfully!");
      })
      .addCase(StatusChangeApiSlice.rejected,handleRejectState)
  },
});


export default PendingArticleByAPISlice.reducer;
