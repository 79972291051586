import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useAuthentication from '../../components/Authentication'
import { FaLock, FaLockOpen } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { AdminLoginSlice } from '../../redux/features/AuthSlice'
import { toast } from 'react-toastify'

const dataToLogin = {
  email:'',
  password:''
}

const Login = () => {

  const {isValid,handleChange} = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [iState,setUpdate] = useState(dataToLogin);
  const [error,setError] = useState({
    email:'',
    password:'',
    remeberMe:false
  });
  const [open,setOpen] = useState(true);

  useEffect(()=>{
    if(localStorage.getItem('QuixRemeberMe')){
      const remeber =  JSON.parse(localStorage.getItem('QuixRemeberMe'))
      setUpdate((prev)=>({...prev,email:remeber?.email,password:remeber?.password,remeberMe:remeber?.remeberMe}))
    }
  },[])



  
  const handleSubmit = () =>{
    if(isValid({iState,setError})){
      console.log("iState:",iState);
      if(iState?.remeberMe){
       localStorage.setItem('QuixRemeberMe',JSON.stringify(iState)) 
      }else{
        localStorage.removeItem('QuixRemeberMe') 
      }

      // if(Object.entries(iState)?.some(([key,value])=>{
      //   if(value?.trim()?.length === 0){
      //     toast.error(`Please fill ${key}`);
      //     return true;
      //   }
      // }) ){
      //   return;
      // }

      dispatch(AdminLoginSlice(iState)).then((res)=>{  
        if(res?.payload?.data?.token?.length){
          localStorage.setItem("Quixtoken",JSON.stringify(res.payload?.data?.token));
          navigate('/') 
        }
      })
    }
  }
  
  return (
    <>
     <div className="LoginArea">
  <div className="LoginBox">
    <div className="LoginLeft">
      <figure>
        <img src={require('../../assets/images/Logo.png')} />
      </figure>
      <h3>
        <span>Login your account</span>
      </h3>
      <h4>
        Enter your email address and password <br />
        to access admin panel.
      </h4>
      <form>
        <div className="form-group">
          <label>Enter your email ID </label>
          <input
            type="text"
            placeholder="Enter Email ID"
            className="form-control"
            name='email'
            value={iState?.email}
            onChange={(event)=>handleChange({event,setUpdate})}
          />
          <span className="Icon">
            <i className="fa fa-envelope" />
          </span>
          {error.email?.length > 0 && <span style={{color:'red'}}>
            {error.email}
          </span>
          }
        </div>
        <div className="form-group">
          <label>Enter your password </label>
          <input
            type={`${open ? 'password' : 'text'}`}
            placeholder="Enter Password"
            className="form-control"
            name='password'
            value={iState?.password}
            onChange={(event)=>handleChange({event,setUpdate})}
          />
          <span className="Icon">
           { open ?
            <FaLock onClick={()=>setOpen((prev)=>!prev)}/>
            :
            <FaLockOpen onClick={()=>setOpen((prev)=>!prev)}/>
           }
          </span>
          {error.password?.length > 0 && <span style={{color:'red'}}>
            {error.password}
          </span>
          }
        </div>
        <div className="Checkboxs">
          <label className="CheckBox">
            Remember Me
            <input type="checkbox" checked={iState?.remeberMe} onChange={(e)=>setUpdate((prev)=>({...prev,remeberMe:!prev['remeberMe']}))}/>
            <span className="checkmark" />
          </label>
          <Link  to={'forgotPassword'}>Forgot password ?</Link>
        </div>
        <Link className="Login" onClick={handleSubmit}>
          Log In <i className="fa fa-sign-in" />
        </Link>
        {/* <button>Log In <i class="fa fa-sign-in"></i></button> */}
      </form>
    </div>
  </div>
</div>
 
    </>
  )
}

export default Login
