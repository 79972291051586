import { Route, Routes } from 'react-router-dom';
import './App.css';

import WrapperComponent from './Wrapper Component/WrapperComponent';
import RouterComponent from './Router Component/RouterComponent';

function App() {
  return (
   <WrapperComponent>
    <RouterComponent/> 
   </WrapperComponent>
  );
}

export default App;
