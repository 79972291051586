import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const SubCategoryGetSlice = createAsyncThunk("listingsubcat-get",async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listingsubcat?${params}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const SubCategoryGetForDropDownSlice = createAsyncThunk("SubCategory-get-ForDropDown",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`SubCategoryListing`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const AddSubCategorySlice = createAsyncThunk("SubCategory-Add",async (Data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('createsubcat',Data);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const DeleteSubCategorySlice = createAsyncThunk("deletesubcat-delete",async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`deletesubcat/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const UpdateSubCategorySlice = createAsyncThunk("SubCategory-Update",async ({id,iState}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`updatesubcat/${id}`,iState);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const SubCategorySlice = createSlice({
  name: 'SubCategory',
  initialState: {
    AllSubCategory: [],
    SubCategoryForDropDown: [],
    NewSubCategoryAdded: [],
    deletedSubCategory: [],
    UpdatedSubCategory: [],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      toast.error(state.error);
    }

    builder
      .addCase(SubCategoryGetSlice.pending,handlePendingState)
      .addCase(SubCategoryGetSlice.fulfilled, (state, action) => { 
        state.AllSubCategory = action.payload.data; 
        state.loading = false;
        // toast.success("LoggedIn Successfully!");
      })
      .addCase(SubCategoryGetSlice.rejected,handleRejectState)

      .addCase(SubCategoryGetForDropDownSlice.pending,handlePendingState)
      .addCase(SubCategoryGetForDropDownSlice.fulfilled, (state, action) => { 
        state.SubCategoryForDropDown = action.payload.data; 
        state.loading = false;
        // toast.success("LoggedIn Successfully!");
      })
      .addCase(SubCategoryGetForDropDownSlice.rejected,handleRejectState)

      .addCase(AddSubCategorySlice.pending,handlePendingState)
      .addCase(AddSubCategorySlice.fulfilled, (state, action) => { 
        state.NewSubCategoryAdded = action.payload.data; 
        state.loading = false;
        toast.success("SubCategory Created Successfully!");
      })
      .addCase(AddSubCategorySlice.rejected,handleRejectState)

      .addCase(DeleteSubCategorySlice.pending,handlePendingState)
      .addCase(DeleteSubCategorySlice.fulfilled, (state, action) => { 
        // console.log(action.payload.data)
        state.deletedSubCategory = action.payload.data; 
        state.loading = false;
        toast.success("SubCategory deleted Successfully!");
      })
      .addCase(DeleteSubCategorySlice.rejected,handleRejectState)

      .addCase(UpdateSubCategorySlice.pending,handlePendingState)
      .addCase(UpdateSubCategorySlice.fulfilled, (state, action) => { 
        state.UpdatedSubCategory = action.payload.data; 
        state.loading = false;
        toast.success("SubCategory deleted Successfully!");
      })
      .addCase(UpdateSubCategorySlice.rejected,handleRejectState)
  },
});


export default SubCategorySlice.reducer;
