import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CategoryGetForDropDownSlice } from "../../../../../redux/features/CategorySlice";
import { StatusChangeApiSlice } from "../../../../../redux/features/Publish Article By API Slices/PendingByAPISlice";
import useParamsFormat from "../../../../../common/Params Values format/ParamsFormat";

const AllArticleDetails = () => {
  const [categoryList, setCategoryListca] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { createDataSetParams } = useParamsFormat();

  const { state: ArticleDetails } = useLocation();
  console.log("1:",ArticleDetails);
  const {
    articleId = null,
    headline = "",
    content = "",
    summary = "",
    category = "",
    state = "",
    district = "",
    images = [],
    oneSummary = "",
    subcategory="",
    isActive = false,
    isTrending = false,
    isKeyLearning = false,
    isDeleted = false,
  } = ArticleDetails || {}; // Ensure ArticleDetails is not undefined
  
  const [iState, setUpdate] = useState({ ...ArticleDetails });

  console.log(ArticleDetails);

  const hanldePendingToPublish = () => {
    const params = {
      id: ArticleDetails?._id,
      status: "published",  
      isActive: true,
      isTrending: iState?.isTrending,
      isKeyLearning: iState?.isKeyLearning,
    };
    console.log(params);
    dispatch(StatusChangeApiSlice(createDataSetParams(params))).then((res)=>{
      if(res?.payload?.data){
        navigate(-2)
      }
    })
  };

  const handleChange = (key) => {
    setUpdate((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  console.log(iState);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <button
          onClick={() => {
            navigate(-1);
          }}
          style={{
            backgroundColor: "#e3083f",
            color: "white",
            height: "40px",
            width: "90px",
            border: "none",
            borderRadius: "5px",
            float: "ri",
          }}
        >
          Back
        </button>
        <button
          onClick={() =>
            navigate("/ContentManagement/EditPublishNewsArticle", {
              state: ArticleDetails,
            })
          }
          style={{
            backgroundColor: "#e3083f",
            color: "white",
            height: "40px",
            width: "90px",
            border: "none",
            borderRadius: "5px",
            float: "ri",
          }}
        >
          Edit
        </button>
      </div>

      <div className="Small-Wrapper">
        <div className="CommonForm">
          <h4>Details News Article</h4>
          <div className="row">
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-6 mb-4">
                  <div>
                    <label className="Radioboxs ">
                      <input
                        type="radio"
                        name="radio"
                        checked={iState?.isTrending}
                        onChange={(e) =>
                          handleChange('isTrending')
                        }
                      />
                      <span
                        className="checkmark ml-2 mt-1"
                        style={{ height: "20px", width: "20px" }}
                      />
                      Trending Article
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 mb-4">
                  <div className="Checkboxs">
                    <label className="CheckBox">
                      Add in Key learning
                      <input type="checkbox" checked={iState?.isKeyLearning} onChange={(e) =>
                          handleChange('isKeyLearning')   
                        }/>
                      <span className="checkmark" />
                      
                    </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>News Headline</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={headline}
                      placeholder="Government Launches New Electric Vehicle (EV) Scheme to Accelerate Sustainable Mobility"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>News Category</label>
                    {/* <select className="form-control" value={category}>
                <option>International Affairs</option>
              </select> */}
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={category}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Sub Category</label>
                    {/* <select className="form-control" value={category}>
                <option>International Affairs</option>
              </select> */}
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={subcategory}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>News Content</label>
                    <div
                      className="form-control"
                      dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-12">
                  {" "}
                  <div className="form-group">
                    <label>Upload Images/Video </label>
                    <div className="UploadBox">
                      <div className="Upload" style={{padding:'0'}}>
                        <img
                          src={images}
                          style={{ height: "100%", width: "100%" }}
                          alt="Article image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={state}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={district}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="form-group">
                <label>News 60 words summary</label>
                <div
                  className="form-control"
                  dangerouslySetInnerHTML={{ __html: summary }}
                ></div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="form-group">
                <label>One line summary</label>
                <div
                  className="form-control"
                  dangerouslySetInnerHTML={{ __html: oneSummary }}
                ></div>
              </div>
            </div>
          </div>
          {/* <button class="Button">PUBLISH</button> */}
        </div>
      </div>
      <button
        style={{
          backgroundColor: "#e3083f",
          color: "white",
          height: "40px",
          width: "90px",
          border: "none",
          borderRadius: "5px",
          marginTop: "10px",
        }}
        onClick={hanldePendingToPublish}
      >
        Publish
      </button>
    </>
  );
};

export default AllArticleDetails;
