import React from 'react'

const QuizAnalystics = () => {
  return (
    <>
     <div className="Small-Wrapper">
  <div className="FilterArea">
    <div className="FilterLeft">
      <div className="form-group">
        <label>From Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>To Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>&nbsp;</label>
        <button className="Button">Apply</button>
        <button className="Button Cancel">
          <i className="fa fa-refresh" />
        </button>
      </div>
    </div>
    <div className="FilterRight">
      <div className="form-group">
        <label>Timeframe</label>
        <select className="form-control">
          <option>Select Timeframe</option>
          <option>Today</option>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select>
      </div>
    </div>
  </div>
  <div className="Small-Wrapper">
    <div className="row">
      <div className="col-6">
        <div className="TitleBox">
          <h4 className="Title">
            Trending Topics Based <br /> On User My Feed/Shares
          </h4>
        </div>
        <figure className="graph-1">
          <img src="images/graph-2.png" />
        </figure>
      </div>
      <div className="col-6">
        <div className="TitleBox">
          <h4 className="Title">
            News Popularity Based On User
            <br /> Daily Reading Habit (Category/Watching)
          </h4>
        </div>
        <figure className="graph-1">
          <img src="images/graph-2.png" />
        </figure>
      </div>
    </div>
  </div>
</div>
 
    </>
  )
}

export default QuizAnalystics
