import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useParamsFormat from '../../../../common/Params Values format/ParamsFormat';
import NotFoundData from '../../../../common/NotFoundData';
import TableSkeleton from '../../../../common/Skeleton';
import { DisapprovedKnowledgeInsightSlice, StatusUpdateKnowledgeInsightSlice } from '../../../../redux/features/Knowledge insight by Admin/KnowledgeInsightByAdminSlice';
import CustomPagination from '../../../../common/Pagination/Pagination';

const DisapprovedArticleByAdminKnowledgeInsigth = () => {

  const {pageNo} = useParams();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const [iState,setUpdate] = useState([]);
  const {createDataSetParams} = useParamsFormat(); 
  const [dataForHold,setDataForHold] = useState();
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: pageNo ?? 1,
  });
 
  const {loading,DisapproveKnowledgeInsight,StatusUpdateKnowledgeInsight} = useSelector((state)=>state?.KnowledgeInsigth)

  useEffect(()=>{
    dispatch(DisapprovedKnowledgeInsightSlice(createDataSetParams(getSearch))).then((res)=>{
      setPagination(res?.payload?.data?.pagination);
    })
  },[StatusUpdateKnowledgeInsight]); 

  useEffect(()=>{
    setUpdate(DisapproveKnowledgeInsight);
  },[DisapproveKnowledgeInsight])

  const hanldeFetchData = (PageNumber) =>{
       setGetSearch((prev)=>({...prev,page:PageNumber}))
       dispatch(DisapprovedKnowledgeInsightSlice(createDataSetParams({...getSearch,page:PageNumber}))).then((res)=>{
        setPagination(res?.payload?.data?.pagination);
      })
  }

  const handleDeleteArticle = (id) => {
    const params = { status: "deleted", isActive: false };
    dispatch(StatusUpdateKnowledgeInsightSlice({ id: id, data: params }));
  };

  const handleHideArticle = (id) => {
    const params = { status: "hidden", isActive: false };
    dispatch(StatusUpdateKnowledgeInsightSlice({ id: id, data: params }));
  };

  const handleDisapprove = (id) => {
    const params = { status: "pending", isActive: true };
    dispatch(StatusUpdateKnowledgeInsightSlice({ id: id, data: params }));
  };


  return (
    <>
     <div className="Small-Wrapper">
  <div className="FilterArea">
  <div className="FilterLeft">
                <div className="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    name='search'
                    onChange={(e)=>setGetSearch((prev)=>({...prev,[e.target.name]:e.target.value}))}
                    className="form-control"
                    placeholder="Search here"
                  />
                </div>
                <div className="form-group">
                  <label>From Date</label>
                  <input type="date"
                  name='fromDate'
                  onChange={(e)=>setGetSearch((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  className="form-control" />
                </div>
                <div className="form-group">
                  <label>To Date</label>
                  <input type="date"
                  name='toDate'
                  onChange={(e)=>setGetSearch((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  className="form-control" />
                </div>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <button className="Button">Apply</button>
                  <button className="Button Cancel">
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
    <div className="FilterRight">
      <div className="form-group">
        <label>Timeframe</label>
        <select className="form-control">
          <option>Select Timeframe</option>
          <option>Today</option>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select>
      </div>
    </div>
  </div>
  <div className="TableList">
    <table>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Article ID</th>
          <th>Article Headline</th>
          <th>Posted On</th>
          <th>Article Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
              {loading ? (
                <TableSkeleton />
              ) : Array.isArray(iState) && iState.length > 0 ? (
                iState?.map((item, index) => {
                  console.log(item?.items[0]);
                  return (
                    <tr>
                      <td>{(pagination?.currentPage - 1) * 10 + index + 1}</td>
                      <td>{item?.items[0]?.insightId}</td>
                      <td>
                        {item?.items[0]?.headline?.slice(0, 20) + "..."}
                        <br />
                        <Link to={'/ContentManagement/KnowledgeInsightsByAdmin/ViewKnowledgeInsight'} state={item} className="blue">view more</Link>
                      </td>
                      <td>{item?.createdAt?.slice(0, 10)}</td>
                      <td>
                        <span className="Green">Active</span>
                      </td>
                      <td>
                        <div className="Actions">
                        <label className="Switch">
                            <input type="checkbox" 
                            checked={true}
                            onClick={()=>handleDisapprove(item?._id)}
                            />
                            <span className="slider" />
                          </label>
                          <a
                            className="Red"
                            data-toggle="modal" 
                            data-target="#DeleteModal"
                            onClick={()=>setDataForHold(item?._id)}
                          >
                            <i className="fa fa-trash" aria-hidden="true" /> 
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NotFoundData />
              )}
            </tbody>
    </table>
  </div>
  {iState.length > 0 && (
          <CustomPagination
            activePage={pagination?.currentPage}
            itemsCountPerPage={pagination?.perPage}
            totalItemsCount={pagination?.totalPages}
            pageRangeDisplayed={5}
            hanldeFetchData={hanldeFetchData}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        )}
</div>
 
<div className="ModalBox">
        <div id="DeleteModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to Delete? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-dismiss="modal"
                      onClick={() => handleDeleteArticle(dataForHold)}
                    >
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DisapprovedArticleByAdminKnowledgeInsigth
