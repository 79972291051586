import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { TermAndConditionGetSlice } from "../../../redux/features/Static Content Management slice/StaticContentManagement";

const TermAndCondition = () => {

  const [iState,setUpdate] = useState({id:'',data:''});
  const dispatch = useDispatch();

  useEffect(()=>{
   dispatch(TermAndConditionGetSlice()).then((res)=>{
    setUpdate((prev)=>({...prev,id:res?.payload?.data?.[0]?._id,data:res?.payload?.data?.[0]?.details}));
   })
  },[]) 

  return (
    <>
      <div className="TitleBox">
        <h4 className="Title">Term and Condition</h4>
        <Link className="TitleLink" to={"/StaticContent/EditStaticContent"} state={{id:iState?.id,type:'staticContent',heading:'Term and Condition',content:iState?.data}}>

          Edit Term and Condition
        </Link>
      </div>

      <div className="StaticContentArea">
      <div dangerouslySetInnerHTML={{ __html: iState?.data }}/>
      </div>
    </>
  );
};

export default TermAndCondition;
