import React from "react";
import Skeleton from "react-loading-skeleton"; // You can install react-loading-skeleton package
import "react-loading-skeleton/dist/skeleton.css";
const TableSkeleton = ({column=6}) => {
  const skeletonRows = 3; // Define how many skeleton rows you want

  return (
    <>
      {[...Array(skeletonRows)].map((_, index) => (
        <tr key={index}>
          {[...Array(column)].map((_,index)=>{
           return( <td>
            <Skeleton width={20} /> 
          </td>)
          })}
          {/* <td>
            <Skeleton width={80} /> 
          </td>
          <td>
            <Skeleton width={120} /> 
          </td>
          <td>
            <Skeleton width={160} /> 
          </td>
          <td>
            <Skeleton width={120} /> 
          </td>
          <td>
            <Skeleton width={80} /> 
          </td>
          <td>
            <Skeleton width={80} /> 
          </td>
          <td>
            <Skeleton width={100} /> 
          </td>
          <td>
            <Skeleton width={60} /> 
          </td>
          <td>
            <Skeleton width={40} /> 
          </td> */}
        </tr>
      ))}
    </>
  );
};

export default TableSkeleton;
