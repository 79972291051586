import React, { useState } from "react";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ResetPasswordSlice } from "../../../redux/features/AuthSlice";

const ResetPassword = () => {
  const navigate = useNavigate();
  
  const [resetPassword, setResetPassword] = useState({
    newPasssword: "",
    confirmPassword: "",
  });
  const [open, setOpen] = useState({
    newPasssword: false,
    confirmPassword: false,
  });
  const {EmailHoldForOPT} = useSelector((state)=>state.Auth)
  const dispatch = useDispatch();


  const handleChange = (event) => {
    const { name, value } = event.target;
    setResetPassword((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!resetPassword.newPasssword?.length || !resetPassword.confirmPassword?.length) {
      toast.error(`Please fill all field`);
      return;
    }

    else if (resetPassword.newPasssword !== resetPassword.confirmPassword) {
      toast.error(`Password didn't match!`);
      return;
    }

    const temp = {
      "email":EmailHoldForOPT,
      "password":resetPassword?.newPasssword
  }
   

   dispatch(ResetPasswordSlice(temp)).then((res)=>{
    if(res?.payload?.data){
      navigate('/login/Congras');
    }
   })

  };

  return (
    <>
      <div className="LoginArea">
        <div className="LoginBox">
          <div className="LoginLeft">
            <figure>
              <img src={require('../../../assets/images/Logo.png')} />
            </figure>
            <h3>
              <span>Create New Password</span>
            </h3>
            <h4>No Problem! Enter your new password</h4>
            <form>
              <div className="form-group">
                <label>Enter New Password</label>
                <input
                  type={`${!open?.newPasssword ? 'password' : 'text'}`}
                  className="form-control"
                  placeholder="Password"
                  name="newPasssword"
                  onChange={handleChange}
                />
                <span className="Icon"  onClick={() => setOpen((prev) =>({...prev,['newPasssword']:!prev['newPasssword']}))} style={{cursor:'pointer'}}>
                {!open.newPasssword ? (
                    <FaLock />
                  ) : (
                    <FaLockOpen/>
                  )}
                </span>
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type={`${!open?.confirmPassword ? 'password' : 'text'}`}
                  className="form-control"
                  placeholder="Password"
                  name="confirmPassword"
                  onChange={handleChange}
                />
                <span className="Icon" onClick={() => setOpen((prev) =>({...prev,['confirmPassword']:!prev['confirmPassword']}))} style={{cursor:'pointer'}}>
                  {!open.confirmPassword ? (
                    <FaLock />
                  ) : (
                    <FaLockOpen/>
                  )}
                </span>
              </div>
              {/* <button class="Login">Submit</button>  */}
              <button className="Login" onClick={handleSubmit}>
                Create Password
              </button>
              <Link className="Login Back aa" onClick={() => navigate(-3)}> 
                Back to Login
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
