import React from 'react'

const AllPublishedArticleKnowledgeInsigthByAPI = () => {
  return (
    <>
      <div className="Small-Wrapper">
  <div className="FilterArea">
    <div className="FilterLeft">
      <div className="form-group">
        <label>Search</label>
        <input type="text" className="form-control" placeholder="Search here" />
      </div>
      <div className="form-group">
        <label>From Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>To Date</label>
        <input type="date" className="form-control" />
      </div>
      <div className="form-group">
        <label>&nbsp;</label>
        <button className="Button">Apply</button>
        <button className="Button Cancel">
          <i className="fa fa-refresh" />
        </button>
      </div>
    </div>
    <div className="FilterRight">
      <div className="form-group">
        <label>Timeframe</label>
        <select className="form-control">
          <option>Select Timeframe</option>
          <option>Today</option>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select>
      </div>
    </div>
  </div>
  <div className="TableList">
    <table>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Article ID</th>
          <th>Article Description</th>
          <th>Posted On</th>
          <th>Article Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>12345</td>
          <td>
            Lorem ipsum dolor sit amet
            <br />
            consectetur adipisicing elit.
            <br />
            Odio, voluptate?
            <br />
            <span className="blue">view more</span>
          </td>
          <td>04-Nov-2024</td>
          <td>
            <span className="Green">Active</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>12345</td>
          <td>
            Lorem ipsum dolor sit amet
            <br />
            consectetur adipisicing elit.
            <br />
            Odio, voluptate?
            <br />
            <span className="blue">view more</span>
          </td>
          <td>04-Nov-2024</td>
          <td>
            <span className="Red">Disapproved</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>12345</td>
          <td>
            Lorem ipsum dolor sit amet
            <br />
            consectetur adipisicing elit.
            <br />
            Odio, voluptate?
            <br />
            <span className="blue">view more</span>
          </td>
          <td>04-Nov-2024</td>
          <td>
            <span className="Orange">Hidden</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>12345</td>
          <td>
            Lorem ipsum dolor sit amet
            <br />
            consectetur adipisicing elit.
            <br />
            Odio, voluptate?
            <br />
            <span className="blue">view more</span>
          </td>
          <td>04-Nov-2024</td>
          <td>
            <span className="Red">Deleted</span>
          </td>
          <td>
            <div className="Actions">
              <label className="Switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <a className="Green" href="">
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <a className="Red" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash" aria-hidden="true" />
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

    </>
  )
}

export default AllPublishedArticleKnowledgeInsigthByAPI
