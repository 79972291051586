import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { onImageHandler } from "../../../common/Image upload on bucket/UploadImageOnBucket";
import { TbEditCircle } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { MyAccountSlice } from "../../../redux/features/My Account/MyAccount";


const MyAccount = () => {
  const [imageLoading, setImageLoading] = useState(false);

  const [iState, setUpdate] = useState({
    "name":'',
    "profileImage":'',
    "email":'',
    "contactNo":'',
  });

  const dispatch = useDispatch();

  const handleUpload = async ({ e, setImageLoading, setUpdate }) => {
    setImageLoading(true);
    const data = await onImageHandler(e);
    setUpdate((prev) => ({ ...prev, [e.target.name]: data[1].location }));
    setImageLoading(false);
  };

  const handleChange=(e)=>{
    const {name,value} = e.target;
    setUpdate((prev)=>({...prev,[name]:value}));
  }

  const handleSubmit=()=>{
   const decodedToken = jwtDecode(JSON.parse(localStorage.getItem('Quixtoken')));
   if(Object.entries(iState)?.some(([key,value],index)=>{
    if(value === ''){
      toast.error(`Please fill ${key} field`);
      return true;  
    }
   })){
    return;
   }
   dispatch(MyAccountSlice({id:decodedToken?.id,data:iState})).then(()=>{
    setUpdate({
      "name":'',
      "profileImage":'',
      "email":'',
      "contactNo":'',
    })
   })
  }

  return (
    <>
      <div className="tab-pane active" id="MyAccount">
        <div className="row">
          <div className="col-sm-5">
            <div className="Small-Wrapper">
              <div className="CommonForm">
                <div className="form-group">
                  <label>Upload Image</label>
                  <div className="UploadBox">
                    <div className="Upload" style={{ padding: "0" }}>
                      {imageLoading ? (
                        <Skeleton width={"100%"} height={"130px"} />
                      ) : iState?.profileImage?.length > 0 ? (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={iState?.profileImage}
                            style={{ height: "100%", width: "100%" }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="file"
                              name="profileImage"
                              onChange={(e) =>
                                handleUpload({ e, setImageLoading, setUpdate })
                              }
                              style={{
                                height: "100%",
                                width: "100%",
                                opacity: "0",
                              }}
                            />
                            <TbEditCircle
                              style={{
                                fontSize: "20px",
                                backgroundColor: "gray",
                                color: "wheat",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <i className="fa fa-upload" /> <span>Upload </span>
                          <input
                            type="file"
                            name="profileImage"
                            onChange={(e) =>
                              handleUpload({ e, setImageLoading, setUpdate })
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Full name</label>
                  <input
                    type="text"
                    name="name"
                    value={iState?.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Your Name"
                  />
                </div>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    name="email"
                    value={iState?.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Your Email"
                  />
                </div>
                <div className="form-group">
                  <label>Contact No</label>
                  <input
                    type="number"
                    name="contactNo"
                    value={iState?.contactNo}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Your Contact No"
                  />
                </div>
                <button className="Button" onClick={handleSubmit}>Save Details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
