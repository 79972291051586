import { jwtDecode } from 'jwt-decode';
import React, { useState } from 'react'
import { FaEyeSlash } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { ChangePasswordSlice } from '../../../redux/features/My Account/MyAccount';

const ChangePassword = () => {

  const dispatch = useDispatch();

  const [iState,setUpdate] = useState({
    oldPassword:'',
    newPassword:'',
    confirmPassword:'',
  })

  const [eyeState,setEyeUpdate] = useState({
    oldPassword:false,
    newPassword:false,
    confirmPassword:false,
  }) 

  const handleChange=(e)=>{
   const {name,value} = e.target;
   setUpdate((prev)=>({...prev,[name]:value}))
  }

  const handleSubmit=()=>{
       const decodedToken = jwtDecode(JSON.parse(localStorage.getItem('Quixtoken')));
   if(iState?.newPassword === iState?.confirmPassword){
      dispatch(ChangePasswordSlice({id:decodedToken?.id,oldPassword:iState.oldPassword,password:iState?.newPassword}))
      .then(()=>setUpdate({
        oldPassword:false,
        newPassword:false,
        confirmPassword:false,
      }));
   }
  }

  return (
    <>
     <div className="tab-pane fade active show" id="PasswordSetting">
  <div className="TitleBox">
    <h4 className="Title">Change Password</h4>
  </div>
  <div className="row">
    <div className="col-sm-5">
      <div className="Small-Wrapper">
        <div className="CommonForm">
          <div className="form-group">
            <label>Old Password</label>
            <input
              type={eyeState?.oldPassword?'text':'password'}
              name='oldPassword'
              value={iState?.oldPassword}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter Old Password"
            />
            <span className="Icon" onClick={()=>setEyeUpdate((prev)=>({...prev,oldPassword:!prev['oldPassword']}))}>
            {eyeState?.oldPassword ?
              <i className="fa fa-eye" />
            :
              <FaEyeSlash />
            }
            </span>
          </div>
          <div className="form-group">
            <label>New Password</label>
            <input
              type={eyeState?.newPassword?'text':'password'}
              name='newPassword'
              value={iState?.newPassword}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter New Password"
            />
            <span className="Icon" onClick={()=>setEyeUpdate((prev)=>({...prev,newPassword:!prev['newPassword']}))}>
            {eyeState?.newPassword ?
              <i className="fa fa-eye" />
            :
              <FaEyeSlash />
            }            </span>
          </div>
          <div className="form-group">
            <label>Re-enter Password</label>
            <input
              type={eyeState?.confirmPassword?'text':'password'}
              name='confirmPassword'
              value={iState?.confirmPassword}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter Re-enter Password"
            />
            <span className="Icon" onClick={()=>setEyeUpdate((prev)=>({...prev,confirmPassword:!prev['confirmPassword']}))}>
            {eyeState?.confirmPassword ?
              <i className="fa fa-eye" /> 
            :
              <FaEyeSlash />
            }            </span>
          </div>
          <button className="Button" onClick={handleSubmit}>Update Password</button>
        </div>
      </div>
    </div>
  </div>
</div>
 
    </>
  )
}

export default ChangePassword
