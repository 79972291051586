import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInstance.js';

export const AdminLoginSlice = createAsyncThunk("adminLogin",async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.post('adminLogin',data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ForgotPasswordSlice = createAsyncThunk("Forgot-Password",async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.post('adminforgot',data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const VerifyOTPSlice = createAsyncThunk("Verify-OTP",async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.post('verifyOtp',data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const ResetPasswordSlice = createAsyncThunk("updatePassword",async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.patch('updatePassword',data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);


export const GetMyProfileSlice = createAsyncThunk("viewadminprofile",async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`viewadminprofile/${id}`);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const GetProfileByIdSlice = createAsyncThunk("customerDetails",async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`customerDetails/${id}`);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);



const AuthSlice = createSlice({
  name: 'Admin Auth',
  initialState: {
    user: [],
    ForgottedPassword: [],
    loading: false,
    error: null,
    validationErrors: [],
    EmailHoldForOPT: '',
    PasswordReset: '',
    MyProfile:'',
    VerifyOTP: [],
  },
  reducers: {
    EmailForVerify:(state,action)=>{
      state.EmailHoldForOPT = action.payload; 
     }
  },
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      // toast.error(state.error);
    }

    builder
      .addCase(AdminLoginSlice.pending,handlePendingState)
      .addCase(AdminLoginSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.user = action.payload.data; 
        state.loading = false;
        toast.success("Logged Successfully!");
      })
      .addCase(AdminLoginSlice.rejected,handleRejectState)

      .addCase(ForgotPasswordSlice.pending,handlePendingState)
      .addCase(ForgotPasswordSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.ForgottedPassword = action.payload; 
        state.loading = false;
      })
      .addCase(ForgotPasswordSlice.rejected,handleRejectState)

      .addCase(VerifyOTPSlice.pending,handlePendingState)
      .addCase(VerifyOTPSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.VerifyOTP = action.payload; 
        state.loading = false;
        toast.success('OTP Verified Successfully')
      })
      .addCase(VerifyOTPSlice.rejected,handleRejectState)

      .addCase(ResetPasswordSlice.pending,handlePendingState)
      .addCase(ResetPasswordSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.PasswordReset = action.payload; 
        state.loading = false;
        toast.success('Password updated successfully')
      })
      .addCase(ResetPasswordSlice.rejected,handleRejectState)

      .addCase(GetMyProfileSlice.pending,handlePendingState)
      .addCase(GetMyProfileSlice.fulfilled, (state, action) => {
        state.MyProfile = action.payload?.data; 
        state.loading = false;
        // toast.success('Password updated successfully')
      })
      .addCase(GetMyProfileSlice.rejected,handleRejectState)
  },
});

export const {EmailForVerify} = AuthSlice?.actions;

export default AuthSlice.reducer;
