import React from 'react'

const UserEnagement = () => {
  return (
    <>
    <div className="row">
  <div className="col-sm-12">
    <div className="Small-Wrapper">
      <div className="News-References">
        <div className="row">
          <div className="col-sm-4">
            <h4>Make Pie Graph Based on User “My Feed”</h4>
            <img className="graph-1" src="images/graph-2.png" />
          </div>
          <div className="col-sm-4">
            <h4>Make Pie Graph Based on User “My Feed”</h4>
            <img className="graph-1" src="images/graph-2.png" />
          </div>
          <div className="col-sm-4">
            <h4>Make Pie Graph Based on User “My Feed”</h4>
            <img className="graph-1" src="images/graph-2.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default UserEnagement
