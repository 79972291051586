import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CategoryGetForDropDownSlice, CategoryGetSlice } from "../../../redux/features/CategorySlice";
import Skeleton from "react-loading-skeleton";
import { onImageHandler } from "../../../common/Image upload on bucket/UploadImageOnBucket";
import { TbEditCircle } from "react-icons/tb";
import { Country, State, City }  from 'country-state-city';
import Editor from "../../../common/Editor/Editor";
import { ArticleCreateSlice, ArticleUpdateSlice } from "../../../redux/features/Publish Article By Admin Slices/ArticleSlice";
  import usePublishArticle from "../../../components/PublishNewArticleCreate";
import { toast } from "react-toastify";
import { SubCategoryGetSlice } from "../../../redux/features/Sub Category Slice/SubCategorySlice";

const EditPublishNewsArticle = () => {
  const navigate = useNavigate();
  const {handleUpload,handleChangeRegion} = usePublishArticle()

  const {state} = useLocation();

  const [selectedRegion, setSelectedRegion] = useState({
    state:null,
    city:null,
    category:null
  }); 
  
  const [imageLoading, setImageLoading] = useState(false);
  const [iState, setUpdate] = useState({
     "headline":"",
     "content":"",
     "summary":"", 
     "oneSummary":"",
     "category":"", 
     "state":"", 
     "district":"", 
     "images":[],
     "isTrending":false,
     "isKeyLearning":false
});
  const [stateArr,setStateArr] = useState([]);
  const [cityArr,setCityArr] = useState([]);
  const dispatch = useDispatch();
  const { CategoryForDropDown } = useSelector((state) => state.Category);
  const { AllSubCategory } = useSelector((state) => state.SubCategory);

  const [option, setOption] = useState({ options: [] });
  const [errors, setErrors] = useState({});

  console.log(state);

  useEffect(()=>{
    setUpdate({
      "headline":state?.headline,
      "content":state?.content,
      "summary":state?.summary, 
      "oneSummary":state?.oneSummary,
      "category":state?.category, 
      "state":state?.state, 
      "district":state?.district, 
      "images":state?.images,
      "isTrending":state?.isTrending,
      "isKeyLearning":state?.isKeyLearning
 })

 setSelectedRegion({  
  state:{value:state?.state,label:state?.state},
  city:{value:state?.district,label:state?.district},
  category:{value:state?.category,label:state?.category},
  subcategory:{value:state?.subcategory,label:state?.subcategory}
})

  },[state])


  useEffect(()=>{
    const indiaStates = State.getStatesOfCountry('IN'); // 'IN' is the ISO code for India
    console.log(indiaStates)
    setStateArr(indiaStates.map((item)=>({value:item?.isoCode,label:item?.name})));
  },[])

    useEffect(() => {
      dispatch(CategoryGetForDropDownSlice());
      dispatch(SubCategoryGetSlice())
    }, []);

  useEffect(() => {
    const option = CategoryForDropDown?.map((item, index) => ({
      value: item?.catName,
      label: item?.catName,
    }));
    setOption((prev) => ({ ...prev, options: option }));
  }, [CategoryForDropDown]);

    useEffect(() => {
      const option = AllSubCategory?.map((item, index) => ({
        value: item?.subcatName,
        label: item?.subcatName,
      }));
      setOption((prev) => ({ ...prev, subcatoptions: option }));
    }, [AllSubCategory]);

  const handleChange = (e) =>{
    const {name,value} = e.target;
    setUpdate((prev)=>({...prev,[name]:value}));
  }
  useEffect(()=>{
   setUpdate((prev)=>({...prev,state:selectedRegion?.state?.label,district:selectedRegion?.city?.value,category:selectedRegion?.category?.value,subcategory:selectedRegion?.subcategory?.value}))
  },[selectedRegion])

  const validateForm = () => {
    let newErrors = {};
    Object.entries(iState).forEach(([key, value]) => {
      console.log(key,value )
      if ([undefined,null,''].includes(value) || (!(typeof value === 'boolean') && String(value)?.trim()?.length === 0)) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = ()=>{
    console.log('called',iState)
    
    if(Object.entries(iState).some(([key,value])=>{
      if([undefined,null,''].includes(value) || String(value)?.trim()?.length===0){
        toast.error(`Please fill this field ${key}`)
        return true;
      }
    })){
      // return;
    }
     console.log(validateForm())
    if (!validateForm()) return;

    dispatch(ArticleUpdateSlice({id:state?._id,data:iState})).then((res)=>{
      console.log("res",res?.payload);
      if(res?.payload?.customStatus === 200){
        navigate(-2);  
      }
    })
  }

  const handleChangeForUpdate = ({content,type})=>{
    setUpdate((prev) => ({ ...prev, [type]: content }));
  }

  return (
    <>
      <>
        <div className="TitleBox">
          <h4 className="Title">Content Management</h4>
          <Link className="TitleLink" to={-1}>
            <i className="fa fa-arrow-left" /> Back
          </Link>
        </div>
        <div className="Small-Wrapper">
          {/* <div class="TitleBox"> 
              <h4 class="Title">Add News Article</h4> 
          </div> */}
          <div className="CommonForm">
            <h4>Edit News Article</h4>
            <div className="row">
              <div className="col-sm-8">
                <div className="row">
                  
                    <div className="col-sm-6 mb-4">
                      <div>
                        <label className="Radioboxs ">
                          <input type="radio" checked={iState?.isTrending} name="radio" onChange={(e)=>setUpdate((prev)=>({...prev,isTrending:true}))} />
                          <span className="checkmark ml-2 mt-1" style={{height:'20px',width:'20px'}} />
                          Trending Article
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-4">
                      <div className="Checkboxs">
                        <label className="CheckBox">
                          Add in Key Learning
                          <input type="checkbox" checked={iState?.isKeyLearning} onChange={(e)=>setUpdate((prev)=>({...prev,isKeyLearning:!prev['isKeyLearning']}))}/>
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>News Headline</label>
                      <input
                        type="text"
                        className="form-control"
                        name="headline"
                        onChange={handleChange}
                        value={iState?.headline}
                        placeholder="Government Launches New Electric Vehicle (EV) Scheme to Accelerate Sustainable Mobility"
                      />
                      {errors.headline && <p style={{color:'red'}}>{errors.headline}</p>}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Select News Category</label>
                      <Select
                        options={option?.options}
                        value={selectedRegion?.category}
                        onChange={(e)=>setSelectedRegion((prev)=>({...prev,category:e}))}
                        isSearchable={true}
                        placeholder="Choose a category..."
                      />
                      {errors.category && <p style={{color:'red'}}>{errors.category}</p>}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Select Sub Category</label>
                      <Select
                        options={option?.subcatoptions}
                        value={selectedRegion?.subcategory} 
                        onChange={(e)=>setSelectedRegion((prev)=>({...prev,subcategory:e}))}
                        isSearchable={true}
                        placeholder="Quix Dynamic..."
                      />
                      {errors.category && <p style={{color:'red'}}>{errors.category}</p>}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>News Content</label> 
                  <Editor setUpdate={setUpdate} type="content" contentData={iState?.content}/>
                  {errors.content && <p style={{color:'red'}}>{errors.content}</p>}
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Upload Images/Video </label>
                  <div className="UploadBox">
                    <div className="Upload" style={{ padding: "0" }}>
                      {imageLoading ? (
                        <Skeleton width={"100%"} height={"130px"} />
                      ) : iState?.images?.length > 0 ? (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={iState?.images}
                            style={{ height: "100%", width: "100%" }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="file"
                              name="images"
                              onChange={(e)=>handleUpload({e,setImageLoading,setUpdate})}
                              style={{
                                height: "100%",
                                width: "100%",
                                opacity: "0",
                              }}
                            />
                            <TbEditCircle
                              style={{
                                fontSize: "20px",
                                backgroundColor: "gray",
                                color: "wheat",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <i className="fa fa-upload" /> <span>Upload </span>
                          <input type="file" name="images" onChange={(e)=>handleUpload({e,setImageLoading,setUpdate})}/>
                        </>
                      )} 
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Select State</label>
                    <Select
                        options={stateArr}
                        value={selectedRegion?.state}
                        onChange={(e)=>handleChangeRegion({e,setSelectedRegion,setCityArr})}
                        isSearchable={true}
                        placeholder="Choose a State..."
                      />
                      {errors.state && <p style={{color:'red'}}>{errors.state}</p>}
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Select District</label>
                    <Select
                        options={cityArr}
                        value={selectedRegion?.city}
                        onChange={(e)=>setSelectedRegion((prev)=>({...prev,city:e}))}
                        isSearchable={true}
                        placeholder="Choose a category..."
                      />
                   {errors.district && <p style={{color:'red'}}>{errors.district}</p>}
                  </div>
                </div>
              </div>

              <div className="col-sm-8">
                <div className="form-group">
                  <label>News 60 words summary</label>
                  <Editor setUpdate={setUpdate} type="summary" contentData={iState?.summary}/>
                  {errors.summary && <p style={{color:'red'}}>{errors.summary}</p>}
                </div>
              </div>

              <div className="col-sm-8">
                <div className="form-group">
                  <label>One line summary</label>
                  <Editor setUpdate={setUpdate} type="oneSummary" contentData={iState?.oneSummary}/>
                  {errors.oneSummary && <p style={{color:'red'}}>{errors.oneSummary}</p>} 
                </div>
              </div>
            </div>
            <button className="Button" onClick={handleSubmit}>PUBLISH</button>
          </div>
        </div>
      </>
    </>
  );
};

export default EditPublishNewsArticle;
