import React, { useEffect, useState } from "react";
import useParamsFormat from "../../../../common/Params Values format/ParamsFormat";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  ArticleDeleteSlice,
  ArticleHideSlice,
} from "../../../../redux/features/Publish Article By Admin Slices/ArticleSlice";
import { GetDisapproveArticleSlice } from "../../../../redux/features/Publish Article By Admin Slices/disapproveArticle";
import TableSkeleton from "../../../../common/Skeleton";
import NotFoundData from "../../../../common/NotFoundData";
import CustomPagination from "../../../../common/Pagination/Pagination";
import useRemoveTag from "../../../../common/Remove Tag of ReactQuil/RemoveTag";

const DisapprovedArticles = () => {
  const location = useLocation().pathname.split("/").splice(1);

  const { pageNo } = useParams();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const [iState, setUpdate] = useState([]);
  const { createDataSetParams } = useParamsFormat();
  const [dataForHold, setDataForHold] = useState();
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: pageNo ?? 1,
  });

  const {getPlainText} = useRemoveTag();

  const { loading, AlldisapproveArticle } = useSelector(
    (state) => state?.disapprove
  );
  const { DeletedArticle, DisApprovedArticle, HideArticle } = useSelector(
    (state) => state?.Article
  );

  console.log("calleddddda");

  useEffect(() => {
    console.log("called");
    dispatch(GetDisapproveArticleSlice(createDataSetParams(getSearch))).then(
      (res) => {
        setPagination(res?.payload?.data?.pagination);
      }
    );
  }, [DeletedArticle, DisApprovedArticle, HideArticle]);

  useEffect(() => {
    console.log(AlldisapproveArticle);
    setUpdate(AlldisapproveArticle);
  }, [AlldisapproveArticle]);

  const hanldeFetchData = (PageNumber) => {
    setGetSearch((prev) => ({ ...prev, page: PageNumber }));
    dispatch(
      GetDisapproveArticleSlice(
        createDataSetParams({ ...getSearch, page: PageNumber })
      )
    ).then((res) => {
      setPagination(res?.payload?.data?.pagination);
    });
  };

  const handleDeleteArticle = (id) => {
    dispatch(ArticleDeleteSlice(id));
  };

  const handleHideArticle = (id) => {
    const dataForHide = { id: id, status: "hidden", isActive: false };
    dispatch(ArticleHideSlice(createDataSetParams(dataForHide)));
  };

  const handleDisapprove = (id) => {
    const dataForDisapprove = { id: id, status: "pending", isActive: true }; 
    dispatch(ArticleHideSlice(createDataSetParams(dataForDisapprove)));
  };

  return (
    <>
      <div className="Small-Wrapper">
        <div className="FilterArea">
          <div className="FilterLeft">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                name="search"
                onChange={(e) =>
                  setGetSearch((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
                className="form-control"
                placeholder="Search here"
              />
            </div>
            <div className="form-group">
              <label>From Date</label>
              <input
                type="date"
                name="fromDate"
                onChange={(e) =>
                  setGetSearch((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>To Date</label>
              <input
                type="date"
                name="toDate"
                onChange={(e) =>
                  setGetSearch((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>&nbsp;</label>
              <button className="Button">Apply</button>
              <button className="Button Cancel">
                <i className="fa fa-refresh" />
              </button>
            </div>
          </div>
          <div className="FilterRight">
            <div className="form-group">
              <label>Timeframe</label>
              <select className="form-control">
                <option>Select Timeframe</option>
                <option>Today</option>
                <option>This Week</option>
                <option>This Month</option>
                <option>This Year</option>
              </select>
            </div>
          </div>
        </div>
        <div className="TableList">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Article ID</th>
                <th>Article Description</th>
                <th>Posted On</th>
                <th>Summary</th>
                <th>Article Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableSkeleton />
              ) : Array.isArray(iState) && iState.length > 0 ? (
                iState?.map((item, index) => {
                  return (
                    <tr>
                      <td>{(pagination.currentPage - 1) * 10 + index + 1}</td>
                      <td>{item?.articleId?.toUpperCase()}</td>
                      <td>
                      <div>
                       {getPlainText(item?.content)?.slice(0, 20)}...
                    </div>
                        <Link
                          to={`/ContentManagement/PublishArticleByAdmin/AllArticleDetails/${item?._id}`}
                          state={item}
                          className="blue"
                        >
                          view more
                        </Link>
                        <br />
                      </td>
                      <td>{item?.createdAt.slice(0, 10)}</td>
                      <td>
                      <div>
                       {getPlainText(item?.summary)?.slice(0, 20)}...
                    </div>
                        <Link
                          to={`/ContentManagement/PublishArticleByAdmin/AllArticleDetails/${item?._id}`}
                          state={item}
                          className="blue"
                        >
                          view more
                        </Link>
                        <br />
                      </td>
                      <td>
                        <span className={item?.isActive ? "Green" : "Red"}>
                          {item?.isActive ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <div className="Actions">
                          <label className="Switch">
                            <input
                              type="checkbox"
                              checked={!item?.isActive}
                              onChange={() => handleDisapprove(item?._id)}
                            />
                            <span className="slider" />
                          </label>
                          <a className="Green">
                            <i
                              className="fa fa-eye"
                              aria-hidden="true"
                              onClick={() => handleHideArticle(item?._id)}
                            />
                          </a>
                          <a
                            className="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                            onClick={() => setDataForHold(item?._id)}
                          >
                            <i className="fa fa-trash" aria-hidden="true" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NotFoundData />
              )}
            </tbody>
          </table>
        </div>
        {iState.length > 0 && (
          <CustomPagination
            activePage={pagination?.currentPage}
            itemsCountPerPage={pagination?.pageSize}
            totalItemsCount={pagination?.totalPages}
            pageRangeDisplayed={5}
            hanldeFetchData={hanldeFetchData}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        )}
      </div>
    </>
  );
};

export default DisapprovedArticles;
