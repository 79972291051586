import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"
import './style.css';
import { modules, formats } from "./Module";

const Editor = ({ handleChange,setUpdate,ObjectKey,type, contentData="" }) => {
  console.log("key:",type)

  const handleEditorChange = (content) => {
    switch (type) {
      case 'content':
        setUpdate((prev)=>({...prev,[type]:content}))
        break;
        case 'summary':
        setUpdate((prev)=>({...prev,[type]:content}))
        break;
        case 'oneSummary':
        setUpdate((prev)=>({...prev,[type]:content}))
        break;
        case 'staticContent':
        setUpdate(()=>(content))

    }
  };


  return (
    <>
      <ReactQuill 
        theme="snow"
        value={contentData}
        onChange={handleEditorChange}
        modules={modules}
        formats={formats}
        placeholder="Write something awesome..."
      />
    </>
  );
};

export default Editor;