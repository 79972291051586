import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const QuizUploadByAdminOutlet = () => {
  const location = useLocation().pathname.split("/").splice(1);

  return (
    <>
      <div className="TitleBox">
        <h4 className="Title">Quiz Management</h4>
      </div>

      <div className="CommonTabs">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location?.includes("AllPublishedQuiz") && "active"
              }`}
              data-toggle="tab"
              to={"AllPublishedQuiz"}
            >
              All Published Quiz
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              className={`nav-link ${location?.includes('HiddenQuiz') && 'active'}`}
              data-toggle="tab" to={"HiddenQuiz"}>
              Hidden Quiz
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              className={`nav-link ${location?.includes('DisapprovedQuiz') && 'active'}`}
              data-toggle="tab" to={"DisapprovedQuiz"}>
              Disapproved Quiz
            </Link>
          </li>
          <li className="nav-item">
            <Link 
              className={`nav-link ${location?.includes('DeletedQuiz') && 'active'}`}
              data-toggle="tab" to={"DeletedQuiz"}>
              Deleted Quiz
            </Link>
          </li>
        </ul>
      </div>

      <div className="TitleBox">
        <h4 className="Title" />
        <span>
          {" "}
          <a
            className="TitleLink"
            data-toggle="modal"
            data-target="#AddQuizCategoryModal"
          >
            Add Quiz Category
          </a>
          <Link className="TitleLink" to={"/QuizManagement/AddQUIZ"}>
            Add Quiz{" "}
          </Link>
        </span>
      </div>

      <div className="ModalBox">
        <div id="LogoutModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Logout</h3>
                  <p>Are you sure you want to log out? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="login.html">Yes</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="DeleteModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to Delete? </p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="ActiveModal"
          className="modal fade ExtraSmallModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Active</h3>
                  <p>Are you sure you want to active?</p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="InactiveModal"
          className="modal fade ExtraSmallModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Inactive</h3>
                  <p>Are you sure you want to inactive ?</p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="AddQuizCategoryModal"
          className="modal fade"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Add QUIZ Category</h3>
                  <div className="form-group">
                    <label>Category Title</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Auto fill "
                    />
                  </div>
                  <div className="form-group">
                    <label>Upload Image </label>
                    <div className="UploadBox">
                      <div className="Upload">
                        <i className="fa fa-upload" /> <span>Upload </span>
                        <input type="file" />
                      </div>
                    </div>
                  </div>
                  <button className="Button">Add Quiz Category</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
};

export default QuizUploadByAdminOutlet;
