import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const CreateBannerManagementSlice = createAsyncThunk("create-BannerManagement",async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('createbanner',data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const GetBannerManagementSlice = createAsyncThunk("bannerlisting",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('bannerlisting');
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const GoogleAdsBannerSlice = createAsyncThunk("Google-Ads-Banner",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('bannerlisting');
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const UpdateBannerManagementSlice = createAsyncThunk("updatebanner/:id",async ({id,data}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`updatebanner/${id}`,data);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

export const DeleteBannerManagementSlice = createAsyncThunk("delete-BannerManagement",async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`deletebanner/${id}`);
      console.log(response)
        return response.data;
     
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error.response?.data || { message: "An error occurred" });
    }
  }
);

const BannerSlice = createSlice({
  name: 'Banner Management',
  initialState: {
    addBanner: [],
    AllBanner:[],
    UpdatedBanner:[],
    DeletedBanner:[],
    GoogLeAdsBanner:[],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      toast.error(state.error);
    }

    builder
    
      .addCase(CreateBannerManagementSlice.pending,handlePendingState)
      .addCase(CreateBannerManagementSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.addBanner = action.payload.data; 
        state.loading = false;
        toast.success("Banner Created Successfully!");
      })
      .addCase(CreateBannerManagementSlice.rejected,handleRejectState)

      .addCase(GetBannerManagementSlice.pending,handlePendingState)
      .addCase(GetBannerManagementSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.Banner = action.payload.data; 
        state.loading = false;
      })
      .addCase(GetBannerManagementSlice.rejected,handleRejectState)

      .addCase(UpdateBannerManagementSlice.pending,handlePendingState)
      .addCase(UpdateBannerManagementSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.UpdatedBanner = action.payload.data; 
        state.loading = false;
        toast.success("Banner updated Successfully Successfully!");
      })
      .addCase(UpdateBannerManagementSlice.rejected,handleRejectState)

      .addCase(DeleteBannerManagementSlice.pending,handlePendingState)
      .addCase(DeleteBannerManagementSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.DeletedBanner = action.payload.data; 
        state.loading = false;
        toast.success("Banner deleted Successfully Successfully!");
      })
      .addCase(DeleteBannerManagementSlice.rejected,handleRejectState)

      .addCase(GoogleAdsBannerSlice.pending,handlePendingState)
      .addCase(GoogleAdsBannerSlice.fulfilled, (state, action) => {
        console.log("action",action)
        state.GoogLeAdsBanner = action.payload.data; 
        state.loading = false;
        toast.success("Banner deleted Successfully Successfully!");
      })
      .addCase(GoogleAdsBannerSlice.rejected,handleRejectState)
  },
});


export default BannerSlice.reducer;
