import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Editor from "../../../../common/Editor/Editor";
import { TbEditCircle } from "react-icons/tb";
import { onImageHandler } from "../../../../common/Image upload on bucket/UploadImageOnBucket";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { UpdateKnowledgeInsightSlice } from "../../../../redux/features/Knowledge insight by Admin/PendingKnowledgeInsigth";
import { useNavigate } from "react-router-dom";

function EditKnowledgeInsight({ show, setShow, StateForUpdate }) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageLoading, setImageLoading] = useState(false);

  const [iState, setUpdate] = useState({});

  useEffect(() => {
    setUpdate(StateForUpdate);
  }, [StateForUpdate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdate((prev) => ({ ...prev, [name]: value }));
  };

  const hanldeImage = async ({ e, setImageLoading, setUpdate }) => {
    const { name } = e.target;
    setImageLoading((prev) => ({ ...prev, [name]: true }));
    const data = await onImageHandler(e);
    setUpdate((prev) => ({
      ...prev,
      [name]: data[1]?.location,
    }));
    setImageLoading((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = () => {
    dispatch(UpdateKnowledgeInsightSlice({ id: iState?._id, data: iState })).then(()=>{
      handleClose()
      navigate(-1);
    })
    console.log(iState);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Edit Quix Sense Article</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>News Headline</label>
                    <input
                      type="text"
                      className="form-control"
                      name="headline"
                      value={iState?.headline}
                      onChange={handleChange}
                      placeholder="Government Launches New Electric Vehicle (EV) Scheme to Accelerate Sustainable Mobility"
                      defaultValue="Introduction to AI"
                    />
                  </div>
                </div>
                {/* <div className="col-sm-12">
                  <div className="form-group">
                    <label>Sub Headline</label>
                    <input
                      type="text"
                      value={iState?.subHeadline}
                      className="form-control"
                      name="subheadline"
                      onChange={handleChange}
                      placeholder="Government Launches New Electric Vehicle (EV) Scheme to Accelerate Sustainable Mobility"
                      defaultValue="Understanding the Basics"
                    />
                  </div>
                </div> */}
                <div>
                  {/* <div
                    className="form-group"
                    style={{ width: "calc(100vh - 35%)" }}
                  >
                    <label>Edit Content</label>
                    <Editor
                      setUpdate={setUpdate}
                      type="content"
                      contentData={iState?.content}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Upload Images </label>
                <div className="UploadBox">
                  <div className="Upload" style={{ padding: "0" }}>
                    {imageLoading["images"] ? (
                      <Skeleton width={"100%"} height={"130px"} />
                    ) : iState?.images?.length > 0 ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={iState?.images}
                          style={{ height: "100%", width: "100%" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            type="file"
                            name="images"
                            onChange={(e) =>
                              hanldeImage({
                                e,
                                setImageLoading,
                                setUpdate,
                              })
                            }
                            style={{
                              height: "100%",
                              width: "100%",
                              opacity: "0",
                            }}
                          />
                          <TbEditCircle
                            style={{
                              fontSize: "20px",
                              backgroundColor: "gray",
                              color: "wheat",
                              cursor: "pointer",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <i className="fa fa-upload" /> <span>Upload </span>
                        <input
                          type="file"
                          name="images"
                          onChange={(e) =>
                            hanldeImage({
                              e,
                              setImageLoading,
                              setUpdate,
                            })
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditKnowledgeInsight;
