import React, { useEffect, useiState, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom'
import { GetProfileByIdSlice } from '../../../../redux/features/AuthSlice';

const PersonalDetails = () => {

  const {id} = useParams();
  const dispatch = useDispatch();
  const [iState,setUpdate] = useState({});

  useEffect(()=>{
    dispatch(GetProfileByIdSlice(id)).then((res)=>{
      console.log(res.payload?.customStatuss)
      if(res.payload?.customStatus === 200){
        console.log(res.payload?.data);
        setUpdate(res.payload?.data);
      }
    })
  },[id])


  return (
    <>
     <div className="row">
  <div className="col-sm-5">
    <div className="Small-Wrapper">
      <div className="CommonForm">
        <div className="form-group">
          <label>Full Name</label>
          <input
            type="text"
            disabled={true}
            className="form-control"
            placeholder="Your Name"
            value={iState?.name}
          />
        </div>
        <div className="form-group">
          <label>Preferred Language</label>
          <input
            type="text"
            disabled={true}
            value={iState?.preferLanguage}
            className="form-control"
            placeholder="English"
          />
        </div>
        <div className="form-group">
          <label>Mobile Number</label>
          <input
            type="number"
            disabled={true}
            value={iState?.phoneNumber}
            className="form-control"
            placeholder="123456789"
          />
        </div>
        <div className="form-group">
          <label>iState</label>
          <input
            type="text"
            disabled={true}
            value={iState?.meraGaon}
            className="form-control"
            placeholder="Uttar Pradesh"
          />
        </div>
        <div className="form-group">
          <label>City/District</label>
          <input
            type="text"
            disabled={true}
            value={iState?.city}
            className="form-control"
            placeholder="Allahabad"
          />
        </div>
      </div>
    </div>
  </div>
</div>
 
    </>
  )
}

export default PersonalDetails
