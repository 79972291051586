import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInstance.js';

export const AllPendingArticleGetSlice = createAsyncThunk("Pending-Article-listofArticles",async (param, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`listofArticles/pending?${param}`);
        return response.data;

    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(error?.response?.data || { message: "An error occurred" });
    }
  }
);


const PendingArticleByAdminSlice = createSlice({
  name: 'Pending-Article-Admin',
  initialState: {
    ArticleCreated: [],
    AllPendingArticle: [],
    DeletedArticle:[],
    HideArticle:[],
    DisApprovedArticle:[],
    loading: false,
    error: null,
    validationErrors: [],
  },
  reducers: {},
  extraReducers: (builder) => {

    const handlePendingState = (state,action) => {
      state.loading = true;
      state.error = null; 
      state.validationErrors = []; 
    }
    const handleRejectState = (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "An error occurred";
      state.validationErrors = action.payload?.errors || []; 
      console.log(state.error);
      // toast.error(state.error);
    }

    builder
      .addCase(AllPendingArticleGetSlice.pending,handlePendingState)
      .addCase(AllPendingArticleGetSlice.fulfilled, (state, action) => {
        state.AllPendingArticle = action.payload.data.articles; 
        state.loading = false;
        // toast.success("Article Created Successfully!");
      })
      .addCase(AllPendingArticleGetSlice.rejected,handleRejectState)
  },
});


export default PendingArticleByAdminSlice.reducer;
