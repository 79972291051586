import React from 'react'

const QuizDashboard = () => {
  return (
    <>
      <>
        <div className="AccordionBox mt-4">
          <div id="accordion2">
            <div className="card">
              <div
                className="card-header"
                data-toggle="collapse"
                href="#collapse14"
                aria-expanded="false"
              >
                <h4>Turinq Quizes</h4>
              </div>
              <div
                id="collapse14"
                className="collapse"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-11 ml-auto">
                      <div className="CardBox">
                        <figure>
                          <img src="images/chart 2.png" />
                        </figure>
                        <div className="legend">
                          <div className="legend-item">
                            <div className="legend-dot chrome" />
                            Total Quizes
                          </div>
                          <div className="legend-item">
                            <div className="legend-dot internet-explorer" />
                            Total Categories | Collections
                          </div>
                          <div className="legend-item">
                            <div className="legend-dot opera" />
                            Total Users participated
                          </div>
                          <div className="legend-item">
                            <div className="legend-dot microsoft-edge" />
                            Total Quiz time
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="card-header"
                data-toggle="collapse"
                href="#collapse15"
                aria-expanded="false"
              >
                <h4>Local Quizes</h4>
              </div>
              <div
                id="collapse15"
                className="collapse"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-11 ml-auto">
                      <div className="CardBox">
                        <figure>
                          <img src="images/chart 2.png" />
                        </figure>
                        <div className="legend">
                          <div className="legend-item">
                            <div className="legend-dot chrome" />
                            Total Quizes
                          </div>
                          <div className="legend-item">
                            <div className="legend-dot internet-explorer" />
                            Total Categories | Collections
                          </div>
                          <div className="legend-item">
                            <div className="legend-dot opera" />
                            Total Users participated
                          </div>
                          <div className="legend-item">
                            <div className="legend-dot microsoft-edge" />
                            Total Quiz time
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="card-header"
                data-toggle="collapse"
                href="#collapse16"
                aria-expanded="false"
              >
                <h4>Turinq</h4>
              </div>
              <div
                id="collapse16"
                className="collapse"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="FilterArea">
                        <div className="FilterLeft">
                          <div className="form-group">
                            <label>Search</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                            />
                          </div>
                        </div>
                        <div className="FilterRight">
                          <div className="form-group">
                            <label>State</label>
                            <select className="form-control">
                              <option>Kerala</option>
                              <option>Maharashtra</option>
                              <option>Assam</option>
                              <option>Orissa</option>
                              <option>Bihar</option>
                              <option>Uttar Pradesh</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label>City</label>
                            <select className="form-control">
                              <option>Noida</option>
                              <option>Agra</option>
                              <option>Lucknow</option>
                              <option>Basti</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="TableList">
                        <table>
                          <thead>
                            <tr>
                              <th>Leadership board</th>
                              <th>Name</th>
                              <th>Score</th>
                              <th>Rank</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Archana</td>
                              <td>50</td>
                              <td>B+</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Archana</td>
                              <td>100</td>
                              <td>A+</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="card-header"
                data-toggle="collapse"
                href="#collapse17"
                aria-expanded="false"
              >
                <h4>Local</h4>
              </div>
              <div
                id="collapse17"
                className="collapse"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="FilterArea">
                        <div className="FilterLeft">
                          <div className="form-group">
                            <label>Search</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                            />
                          </div>
                        </div>
                        <div className="FilterRight">
                          <div className="form-group">
                            <label>State</label>
                            <select className="form-control">
                              <option>Kerala</option>
                              <option>Maharashtra</option>
                              <option>Assam</option>
                              <option>Orissa</option>
                              <option>Bihar</option>
                              <option>Uttar Pradesh</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label>City</label>
                            <select className="form-control">
                              <option>Noida</option>
                              <option>Agra</option>
                              <option>Lucknow</option>
                              <option>Basti</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="TableList">
                        <table>
                          <thead>
                            <tr>
                              <th>Leadership board</th>
                              <th>Name</th>
                              <th>Score</th>
                              <th>Rank</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Archana</td>
                              <td>50</td>
                              <td>B+</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Archana</td>
                              <td>100</td>
                              <td>A+</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="card-header"
                data-toggle="collapse"
                href="#collapse18"
                aria-expanded="false"
              >
                <h4>Trending Quiz Categories</h4>
              </div>
              <div
                id="collapse18"
                className="collapse"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="Dashboard Active">
                        {/* <figure><img src="images/Order.png"></figure> */}
                        <figcaption>
                          <h4>
                            What is the name of the spacecraft recently launched
                            by NASA to study a metal-rich asteroid?
                          </h4>
                        </figcaption>
                        <figure>
                          <img src="images/Order.png" />
                        </figure>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="Dashboard">
                        <figcaption>
                          <h4>
                            Which tech giant recently announced a major
                            investment in quantum computing?
                          </h4>
                        </figcaption>
                        <figure>
                          <img src="images/Order.png" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="card-header"
                data-toggle="collapse"
                href="#collapse18"
                aria-expanded="false"
              >
                <h4>Top 5 listing</h4>
              </div>
              <div
                id="collapse18"
                className="collapse"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="Dashboard Active">
                        {/* <figure><img src="images/Order.png"></figure> */}
                        <figcaption>
                          <h4>Polutics</h4>
                          {/* <h2>25</h2> */}
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="Dashboard">
                        {/* <figure><img src="images/Order.png"></figure> */}
                        <figcaption>
                          <h4>Bollywood</h4>
                          {/* <h2>25</h2> */}
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="Dashboard">
                        {/* <figure><img src="images/Order.png"></figure> */}
                        <figcaption>
                          <h4>Business</h4>
                          {/* <h2>25</h2> */}
                        </figcaption>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default QuizDashboard
