import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotFoundData from "../../../common/NotFoundData";
import TableSkeleton from "../../../common/Skeleton";
import { GoogleAdsBannerSlice } from "../../../redux/features/Banner Management/BannerManagement";
import useParamsFormat from "../../../common/Params Values format/ParamsFormat";

const GoogleAdsBanner = () => {
  const [modalState, setModalState] = useState({
    AddBanner: false,
    delete: false,
    EditBanner: false,
  });
  const [dataForHold, setDataForHold] = useState({
    deleteId: "",
    EditBanner: "",
  });
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.Banner);
  const { createDataSetParams } = useParamsFormat();
  const [iState, setUpdate] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [getSearch, setGetSearch] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: 1,
  });

  useEffect(() => {
    // dispatch(GoogleAdsBannerSlice())
  }, []);

  return (
    <>
      <div className="Small-Wrapper">
        <div className="TableList">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Banner ID</th>
                <th>Banner Image</th>
                <th>Uploaded On</th>
                <th>Last Updated On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableSkeleton />
              ) : Array.isArray(iState) && iState?.length > 0 ? (
                iState?.map((item, index) => {
                  return (
                    <tr>
                      <td>1</td>
                      <td>bnrid1</td>
                      <td>
                        <img src="images/car.png" />
                      </td>
                      <td>dd/mm/yyyy</td>
                      <td>dd/mm/yyyy</td>
                      <td>
                        <div className="Actions">
                          <a className="Blue" href="">
                            <i className="fa fa-download" aria-hidden="true" />
                          </a>
                          <a
                            className="Green"
                            data-toggle="modal"
                            data-target="#ViewBannerModal"
                          >
                            <i className="fa fa-eye" aria-hidden="true" />
                          </a>
                          <a
                            className="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                          >
                            <i className="fa fa-trash" aria-hidden="true" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NotFoundData />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GoogleAdsBanner;
