import React, { useEffect, useState } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'

const UserManagementDetailsOutlet = () => {

  const {id} = useParams();
  
  return (
    <>
    <div className="TitleBox">
  <h4 className="Title">User Management</h4>
</div>
    <div className="CommonTabs">
  <ul className="nav nav-tabs">
    <li className="nav-item">
      <Link className="nav-link active"  data-toggle="tab" to={`PersonalDetails/${id}`}>
        Personal Detail
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-toggle="tab" to={`NewsPreferences/${id}`}>
        News Preferences
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-toggle="tab" to={`UserEnagement/${id}`}>
        User Engagement
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-toggle="tab" to={`Bookmarks/${id}`}>
        Bookmarks
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-toggle="tab" to={`OfflineSave/${id}`}>
        Offline Save
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-toggle="tab" to={`UserQuizLeaderBoad/${id}`}>
        User Quiz LeaderBoard
      </Link>
    </li>
  </ul>
</div>

     <Outlet/> 
    </>
  )
}

export default UserManagementDetailsOutlet
