import React, { useEffect, useState } from 'react'
import Editor from '../../../common/Editor/Editor'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { AboutusUpdateSlice, ContactUsUpdateSlice, PrivacyAndPolicyUpdateSlice, TermAndConditionUpdateSlice } from '../../../redux/features/Static Content Management slice/StaticContentManagement';

const EditStaticContent = () => {
  const {state} = useLocation();
  const [update,setUpdate] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(()=>{
    setUpdate(state?.content);
  },[]);

  const handleSubmit=()=>{
  
   switch (state?.heading) {
    case 'Privacy and Policy':
      dispatch(PrivacyAndPolicyUpdateSlice({
        id:state?.id,update
    })).then((res)=>navigate(-1));
      break;
   
    case 'About Us':
      dispatch(AboutusUpdateSlice({
        id:state?.id,update
    })).then((res)=>navigate(-1));
      break;

    case 'Term and Condition':
      dispatch(TermAndConditionUpdateSlice({
        id:state?.id,update
    })).then((res)=>navigate(-1));
      break;
    
      case 'Contact Us':
      dispatch(ContactUsUpdateSlice({ 
        id:state?.id,update
    })).then((res)=>navigate(-1));
      break;
   
    default:
      break;
   }
  }

  return (
    <>
     <div className='WrapperBox'>
  <div className="TitleBox">
    <h4 className="Title">Content Management</h4>
  </div>
  <div className="CommonForm">
    <h4>Update</h4>
    <div className="form-group">
      <div className="row">
        <div className="col-sm-2">
          <label>Title</label>
        </div>
        <div className="col-sm-10">
          <input
            type="text"
            disabled={true}
            className="form-control"
            defaultValue={state?.heading}
          />
        </div>
      </div>
    </div>
    <div className="form-group">
      <div className="row">
        <div className="col-sm-2">
          <label>Description</label>
        </div>
        <div style={{paddingLeft:'15px'}}> 
        <Editor setUpdate={setUpdate} contentData={update} type={'staticContent'}/>
        <button className="Button" onClick={handleSubmit} style={{marginTop:'15px'}}>Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
 
    </>
  )
}

export default EditStaticContent
